import { createGlobalStyle } from 'styled-components';

import Roboto from '../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoThin from '../assets/fonts/Roboto-Thin.ttf';


export default createGlobalStyle`
    /**************************************************************
    * Fonts
    **************************************************************/
    @font-face {
        font-family: 'Roboto';
        src: url(${ Roboto });
    }

    @font-face {
        font-family: 'RobotoBold';
        src: url(${RobotoBold});
    }

    @font-face {
        font-family: 'RobotoMedium';
        src: url(${ RobotoMedium});
    }

    @font-face {
        font-family: 'RobotoThin';
        src: url(${ RobotoThin});
    }

    /**************************************************************
    * Global CSS
    **************************************************************/
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    body {
        background-color: ${props => props.theme.colors.background};
        font-size: 16px;
        color: ${props => props.theme.colors.text};
        font-family: Roboto;
    }

    strong {
        font-family: RobotoBold;
        font-weight: normal;
    }

    .react-modal-overlay{
        background: rgba(0,0,0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        z-index:0;
        height: 100%;
}

.react-modal-content{
    max-height: 800px;
    outline: none;
    width: 100%;
    max-width: 800px;
    background: #FFFFFF;
    position: relative;
    border-radius: 0.25rem;
    overflow-y: auto;
    margin-top: 40px;
   
    
}


.react-modal-content-city, .react-modal-content-delete{
    max-height: 800px;
    outline: none;
    width: 100%;
    max-width: 500px;
    background: #FFFFFF;
    position: relative;
    border-radius: 0.25rem;
    overflow-y: auto;
    margin-top: 40px;
    padding-bottom: 20px;

}

.react-modal-content-delete{
    max-height: 600px;
    max-width: 400px;
}




`;
