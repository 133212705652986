import React, { useCallback, useState } from 'react';
import { FaPaste } from "react-icons/fa";

import HeaderSupport from '../../shared/HeaderSupport';
import Breadcrumb from '~/shared/components/Breadcrumb';
import {
    Container,
    Cards,
    Card,
    CardTitle,
    CardContent,
    Table,
  
} from './styles';

export default function Dashboard() {
  
    return (

        <>
          <HeaderSupport />
          <Breadcrumb title="Dashboard" paths={[
            { pageName: "Dashboard", link: "/support/dasboard" },
            { pageName: "Suporte" }
          ]} />

            <Container>
              <Cards> 
                <Card> 
                  <CardTitle> 
                    <span> <FaPaste/> </span>
                    <h2> Últimos Atendimentos </h2>
                  </CardTitle>
                  <CardContent> 

                    <Table> 
                      <thead>
                        <tr>
                          <th>Cliente </th>
                          <th> Data </th>
                          <th> Hora </th>
                          <th> TA</th>
                          <th> Protocolo </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20/02 </td>
                          <td> 08:45 </td>
                          <td> 8:45 </td>
                          <td> 254875</td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20/02 </td>
                          <td> 08:45 </td>
                          <td> 8:45 </td>
                          <td> 254875</td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20/02 </td>
                          <td> 08:45 </td>
                          <td> 8:45 </td>
                          <td> 254875</td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20/02 </td>
                          <td> 08:45 </td>
                          <td> 8:45 </td>
                          <td> 254875</td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20/02 </td>
                          <td> 08:45 </td>
                          <td> 8:45 </td>
                          <td> 254875</td>
                        </tr>

                      </tbody>
                    
                    </Table>

                  </CardContent>
                </Card>

                <Card> 
                  <CardTitle> 
                    <span> <FaPaste/> </span>
                    <h2> Média de Atendimento Diario </h2>
                  </CardTitle>
                  <CardContent> 

                    <Table> 
                      <thead>
                        <tr>
                          <th>Atendente </th>
                          <th> QA </th>
                          <th> TMA</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 10 </td>
                          <td> 8:45 </td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 20 </td>
                          <td> 8:45 </td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 30 </td>
                          <td> 8:45 </td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 40 </td>
                          <td> 08:45 </td>
                        </tr>

                        <tr> 
                          <td> ANA PATRICIA DE LIRA SILVA DUARTE </td>
                          <td> 50 </td>
                          <td> 8:45 </td>
                        </tr>
                      </tbody>

                    </Table>

                  </CardContent>
                </Card>
              
              </Cards>
            </Container>
        </>
    )
}


