import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import Leaflet from "leaflet";

import api from '~/shared/services/api';

import {getHumanFormated} from '~/shared/utils/FormatDataUtils';

import { Container, LegendMap, Ul, Li } from './style';

import mapIconCtoGreen from '~/shared/assets/ctoVerde.svg';
import mapIconCtoGray from '~/shared/assets/ctoCinza.svg';
import mapIconCtoYellow from '~/shared/assets/ctoAmarelo.svg';
import mapIconCtoRed from '~/shared/assets/ctoVermelho.svg';
import mapIconCtoOrange from '~/shared/assets/ctoLaranja.svg';

const mapCtoGreenIcon = Leaflet.icon({
  iconUrl: mapIconCtoGreen,
  iconSize: [14.4, 18],
  iconAnchor: [7.2, 9],
  popupAnchor: [0, -20],
});

const mapCtoYellowIcon = Leaflet.icon({
 iconUrl: mapIconCtoYellow,
 iconSize: [14.4, 18],
 iconAnchor: [7.2, 9],
 popupAnchor: [0, -20],
});

const mapCtoOrangeIcon = Leaflet.icon({
  iconUrl: mapIconCtoOrange,
  iconSize: [14.4, 18],
  iconAnchor: [7.2, 9],
  popupAnchor: [0, -20],
});

const mapCtoRedIcon = Leaflet.icon({
 iconUrl: mapIconCtoRed,
 iconSize: [14.4, 18],
 iconAnchor: [7.2, 9],
 popupAnchor: [0, -20],
});

const mapCtoGrayIcon = Leaflet.icon({
  iconUrl: mapIconCtoGray,
  iconSize: [14.4, 18],
  iconAnchor: [7.2, 9],
  popupAnchor: [0, -20],
});

export default function Maps({ handleOpenModal }) {
  const [ctos, setCtos] = useState([]);

  const location = useLocation();
  const history = useHistory();

  /**
   * Retorna o icone com a crominância baseada na ocupação da CTO informada.
   * 
   * @param availablePorts Quantida de portas vagas
   * @param totalPorts Quantidade de portas do split
   */
  const getCorrectCtoIcon = useCallback((availablePorts, totalPorts) => {
    if (availablePorts === -1) {
      return mapCtoGrayIcon;
    }

    const occupation = (availablePorts / totalPorts) * 100;

    if (occupation <= 30) {
      return mapCtoGreenIcon;
    } else if (occupation <= 60) {
      return mapCtoYellowIcon;
    } else if (occupation <= 85) {
      return mapCtoOrangeIcon;
    } else {
      return mapCtoRedIcon;
    }
  }, []);

  useEffect(() => {
    const districtIds = location.state?.districtIds || null;

    if (districtIds) {
      if(!districtIds.length) {
        setCtos([]);
        return;
      }
      api
        .get('infra/ctos/district', {
          params: {
            district_id: districtIds
          }
        })
        .then(response => {
          setCtos(response.data);
        })
        .catch(error => alert("Não foi possível recuperar CTOs. Por favor, tente novamente"))
    } else {
      // history.push('/mgmaps/');
    }
  }, [location.state?.districtIds, history]);
    
  return (
     <Container>
      <MapContainer 
        center={!!ctos.length ? ctos[0].location.coordinates : [-5.5406035, -35.8322093]}
        zoom={!!ctos.length ? 13 : 9} 
        scrollWheelZoom={true}>
          
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />

        {ctos.map(cto => 
          <Marker
            key={cto._id}
            position={cto.location.coordinates}
            icon={getCorrectCtoIcon(cto.availablePorts, cto.totalPorts)}
            // eventHandlers={{
            //   click: () => {handleOpenModal();},
            // }}
          >
            <Popup
               closeButton={true}
               minWidth={240}
               maxWidth={240}
               className="map-popup"
             >
               <div>
                 <h3>Ocupação</h3>
                 <p>
                   <span>Portas:</span> {cto.availablePorts >= 0 ? `${cto.availablePorts}/${cto.totalPorts}`: "Não Mapeadas"}
                 </p>
                 <p>
                   <span>Localização:</span> {cto.location.coordinates[0] + " " + cto.location.coordinates[1]}
                 </p>
                 <p>
                   <span>Atualizado em:</span> {cto.availablePorts >= 0 ? getHumanFormated(cto.updatedAt) : ""}
                 </p>
               </div>
            </Popup>
          </Marker>
        )}

        <LegendMap>
          <Ul>
          <Li>
              <div className={'gray'}>&nbsp;</div>
              <span>Não Mapeada</span>
            </Li>
            <Li>
              <div className={'green'}>&nbsp;</div> 
              <span>Até 30% Ocupado</span>
            </Li>
            
            <Li>
              <div className={'yellow'}>&nbsp;</div> 
              <span>Até 60% Ocupado</span>
            </Li>
            
            <Li>
              <div className={'orange'}>&nbsp;</div> 
              <span>Até 85% Ocupado</span>
            </Li>
            
            <Li>
              <div className={'red'}>&nbsp;</div> 
              <span>Acima de  85% Ocupado</span>
            </Li>
            
          </Ul>
          
        </LegendMap>

      </MapContainer>
 
     </Container>
   )
};