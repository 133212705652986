import React, {useRef,useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-modal';
import { MdClose} from "react-icons/md";

import Input from '~/shared/components/FormValidation/input';
import {useToast} from '~/shared/hooks/toast';
import {Container, FormRegistration, ModalHeader} from './style';
import { Form } from './style';
Modal.setAppElement('#root')

export default function ModalRegistrationCity({isOpen, onRequestClose, typeAction}){
 const {addToast} = useToast();
  const formRef = useRef(null);

  const handleSubmit = useCallback(async (data, {reset}) => {

    try{
      
      const schema = Yup.object().shape({
        city: Yup.string().min(3, 'No mínino 3 caracteres')
          .required('A Cidade é obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      })

      formRef.current.setErrors({})
      console.log(data.city);
      reset();
      addToast(
        {
          type: 'sucess',
          title: 'Cadastro realizado',
          description:'Cidade cadastrada com sucesso',
          
        });

      
    }catch(err){
      if(err instanceof Yup.ValidationError ){
        const errorMessages = {

        };
        //dispara um toast caso o form entre no catch
        addToast(
          {
            type: 'error',
            title: 'Houve um erro',
            description:'Cidade já cadastrada',
            
          });

        err.inner.forEach(error =>{
          errorMessages[error.path] = error.message;
        })

        formRef.current.setErrors(errorMessages);

      }
      //disparar um toast
     
    }
   

  },[addToast])

 
  return(

  

        <Container>
          <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-city"

          >
          
             {typeAction ==='register' &&
             <>
            


            <ModalHeader>
           
            <div>
              <button onClick={onRequestClose}> <MdClose /> </button>

            </div>
             <p> Cadastrar Cidade</p>

             </ModalHeader>
 
           <Form ref={formRef} onSubmit={handleSubmit}> 
           <Input type="text" name="city" placeholder="Nome da Cidade" />
           
           
           <button type="submit">Cadastrar</button>
        
           </Form>
           </>
            
            }

          {typeAction ==='update' &&
             <>
            


            <ModalHeader>
            <div>
              <button onClick={onRequestClose}> <MdClose /> </button>

            </div>
          
             <p> Editar Cidade</p>

             </ModalHeader>
 
            
            
           <Form ref={formRef} onSubmit={handleSubmit}> 
           <Input type="text" name="city" placeholder="Nome da Cidade" />
           
           
           <button>Atualizar</button>
        
           </Form>
           </>
            
            }
         
             
           
            

          
       

         
          

   

        </Modal>

         </Container>


  );
}