// export class Node {
//   /*
//     Classe responsável por representar um Nó na árvore de decisão do script de atendimento

//     @param callback: function que será executada assim que o no for escolhido
//     @param feedBack: Mensagem informando o que a função callback está fazendo e que será sendo mostrada durante a execução do callback
//     @param responseOptions: Opções de respostas
//     @param getNextNode: Ira receber a resposta do suporte a pergunta feita, e ira dizer qual o proximo nó no que sera executado
//     @param responseSelected: Armazenado a resposta do suporte a pergunta feita
//     @param children: Lista de Nós que podem ser executados dependendo da resposta do suporte
//     @param msgForSaySupport: Mensagem que será informada ao atendente
//     @param msg: Um vetor de objetos contendo o texto da mensagem e para quem será direcionada, cliente ou atendente.
//   */
//   constructor(props) {
//     Object.assign(this, props);
//   }

//   setResponseSelected(msg) {
//     this.responseSelected = msg;
//   }
// }

export class Node {
  /*
    Classe responsável por representar um Nó na árvore de decisão do script de atendimento
    
    @param callback: function que será executada assim que o no for escolhido
    @param feedBack: Mensagem informando o que a função callback está fazendo e que será sendo mostrada durante a execução do callback
    @param responseOptions: Opções de respostas
    @param getNextNode: Ira receber a resposta do suporte a pergunta feita, e ira dizer qual o proximo nó no que sera executado
    @param responseSelected: Armazenado a resposta do suporte a pergunta feita
    @param children: Lista de Nós que podem ser executados dependendo da resposta do suporte
    @param msgForSaySupport: Mensagem que será informada ao atendente
    @param msg: Um vetor de objetos contendo o texto da mensagem e para quem será direcionada, cliente ou atendente.
  */
  constructor(props) {
    Object.assign(this, props);
  }

  setResponseSelected(msg) {
    this.responseSelected = msg;
  }

  setCallback(callback) {
    this.callback = callback;
  }

  async getCallback() {
    if (!this.callback) {
      return async () => { };
    }

    return this.callback(this);
  }

  setCallbackResult(result) {
    this.callbackResult = result;
  }

  getCallbackResult() {
    return this.callbackResult;
  }

  setCallbackResultError(isError) {
    this.callbackResultError = isError;
  }

  setMsg(msg) {
    this.msg = msg;
  }

  getMsg() {
    if (typeof this.msg == "function") {
      return this.msg(this);
    }

    return this.msg;
  }

  setResponseOptions(options) {
    this.responseOptions = options;
  }
}



export class Tree {
  constructor() {
    this.root = null;
    this.historyNodes = [];
  }

  getRootNode() {
    return this.root();
  }

  setRootNode(rootNode) {
    this.root = rootNode;
  }

  getNodeHistory() {
    return this.historyNodes;
  }

  addNodeHistory(node) {
    this.historyNodes.push(node);
  }

  rollBackNodeHistory() {
    return this.historyNodes.pop();
  }

  setAttendanceConnectionId(connectionId) {
    this.connectionId = connectionId;
  }

  getAttendanceConnectionId() {
    return this.connectionId;
  }

  setAttendanceConnectionData(connectionData) {
    this.connectionData = connectionData;
  }

  setAttendanceEquipment(equipment) {
    this.equipment = equipment;
  }

  getAttendanceConnectionData() {
    return this.connectionData;
  }

  getAttendanceEquipment() {
    return this.equipment;
  }

  setFinishAttendanceCallback(callback) {
    this.finishAttendanceCallback = callback;
  }
}


export class AttendanceError {
  constructor(message) {
    this.message = message;
  }
}