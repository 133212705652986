import React, {useCallback, useRef, useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { FaSearch, FaUserPlus} from 'react-icons/fa';
import { FiEdit } from "react-icons/fi";

import Breadcrumb from '~/shared/components/Breadcrumb';
import Input from '~/shared/components/FormValidation/input';
import Select from '~/shared/components/FormValidation/select';
import AlertLoading from '~/shared/components/AlertLoading';
import HeaderApi from '../../shared/components/HeaderAPI';

import api from '~/shared/services/api';

import { SectionTwo, Container, Content, SectionOne, FormTop, Form, Pagination, PaginationLink } from './styles';

export default function Users() {
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    
    const [isLoading, setIsLoading] = useState(false);
    const [amountPages, setAmountPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastSearchUsersParams, setLastSearchUsersParams] = useState({});
    const limitByPage = 15;

    const formRef = useRef(null);

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            api.get(`users?limit=${limitByPage}`),
            api.get('groups')
        ])
        .then(response => {
            const totalAmountUsers = Number(response[0].headers['x-total-count']);
            const amountPages = Math.ceil(totalAmountUsers / limitByPage);

            setAmountPages(amountPages > 0 ? amountPages : 1);
            setUsers(response[0].data);
            setGroups(response[1].data);
        })
        .catch(error => {
            alert("Não foi possível recuperar usuários. Por favor, atualize a página e tente novamente");
        })
        .finally(() => setIsLoading(false));

    },[]);

    const handleSubmit = useCallback(async (data, {reset}) => {
        setIsLoading(true);

        try {
            let params = {
                page: 1,
                limit: limitByPage
            };

            if (data.nome) {
                params['name'] = data.nome;
            }

            if (data.setor) {
                params['group_id'] = data.setor;
            }

            const response = await api.get('users', {
                params: params,
                timeout: 5000
            });

            const totalAmountUsers = Number(response.headers['x-total-count']);
            const amountPages = Math.ceil(totalAmountUsers / limitByPage);
            
            setAmountPages(amountPages > 0 ? amountPages : 1);
            setUsers(response.data);
            setLastSearchUsersParams(params);
            setCurrentPage(1);
        } catch (err) {
            alert("Não foi possível recuperar usuários. Por favor, atualize a página e tente novamente");
        } finally {
            setIsLoading(false);
        }
      }, [])

    
    const handleNavigateToPage = useCallback(async page => {
        setIsLoading(true);
        
        const newSearchParams = { 
            ...lastSearchUsersParams, 
            page: page,
            limit: limitByPage,
        };
        
        try {
            const response = await api.get('users', {
                params: newSearchParams,
                timeout: 5000
            });

            const totalAmountUsers = Number(response.headers['x-total-count']);
            const amountPages = Math.ceil(totalAmountUsers / limitByPage);

            setAmountPages(amountPages > 0 ? amountPages : 1);
            setUsers(response.data);
            setLastSearchUsersParams(newSearchParams);
            setCurrentPage(page);
        } catch (error) {
            alert("Não foi possível recuperar usuários. Por favor, atualize a página e tente novamente");
        } finally {
            setIsLoading(false);
        }
    }, [lastSearchUsersParams]);

    return (
        
        <>
            <HeaderApi/>
            <SectionOne>
                <Breadcrumb 
                    title="Usuários"
                    paths={[
                        { pageName: "Painel", link: "/admin" },
                        { pageName: "Usuários" }
                ]} />
                <FormTop>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <label>Nome:</label>
                        <Input 
                            type="text"
                            name="nome"
                            placeholder="nome"
                        />
                        <label>Setor:</label>

                        <Select 
                            name="setor"
                            options={groups}> 
                            <option value="">Selecione o setor</option>
                            {groups && groups.map((group)=>(
                                <option value={group.id} key={group.id}>{group.name}</option>

                            ))}
                        </Select>
                  
                  <button type="submit"><FaSearch /></button>
                  <button className="add">
                      <Link to="/admin/users/add"><FaUserPlus /></Link>
                      
                    </button>
                  
                    </Form>
                    
                </FormTop>
            </SectionOne>
            <SectionTwo>
                <Container>                    
                    <Content>
                        <section>
                            <div className="container">
                                <table>
                                <thead>
                                    <tr>
                                        <th>Status
                                            <div>Status</div>
                                        </th>
                                        <th>Nome
                                            <div>Nome</div>
                                        </th>
                                        <th>Login
                                            <div>Usuário</div>
                                        </th>
                                        <th>E-mail
                                            <div>E-mail</div>
                                        </th>
                                        <th>Ações
                                            <div>Ações</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    { users.map(user => (
                                        <tr key={user.id}>
                                            <td>
                                                {user.is_active ? 'Ativo' : 'Inativo'}
                                                
                                                </td>
                                            <td>{user.full_name}</td>
                                            <td>{user.username}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                <button>
                                                    <Link to={`/admin/users/${user.id}/edit`}> <FiEdit /></Link>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            <Pagination>
                                {
                                    <>
                                        <PaginationLink 
                                            onClick={() => handleNavigateToPage(1)} 
                                            disabled={currentPage <= 1}>
                                            Primeira
                                        </PaginationLink>
            
                                        <PaginationLink 
                                            onClick={() => handleNavigateToPage(currentPage-1)} 
                                            disabled={currentPage <= 1}>
                                            &laquo;
                                        </PaginationLink>
            
                                        {Array(currentPage > 2 ? 2 : currentPage - 1)
                                            .fill('')
                                            .map((_, index) => (
                                                <PaginationLink onClick={() => handleNavigateToPage(index + 1)}>
                                                    {index + (currentPage > 2 ? currentPage - 2 : 1)}
                                                </PaginationLink>
                                            ))}
                                            
                                        <PaginationLink 
                                            onClick={() => handleNavigateToPage(currentPage)} 
                                            selected>
                                            {currentPage}
                                        </PaginationLink>

                                        {
                                            Array((currentPage + 3) > amountPages ? amountPages - currentPage : 2)
                                                .fill('')
                                                .map(
                                                    (_, index) => (
                                                    <PaginationLink 
                                                        onClick={() => handleNavigateToPage(index + 1 + currentPage)}>
                                                        {index + 1 + currentPage}
                                                    </PaginationLink>
                                                ))
                                        }
            
                                        <PaginationLink 
                                            onClick={() => handleNavigateToPage(currentPage+1)} 
                                            disabled={currentPage >= amountPages}>
                                            &raquo;
                                        </PaginationLink>
            
                                        <PaginationLink 
                                            onClick={() => handleNavigateToPage(amountPages)} 
                                            disabled={currentPage >= amountPages}>
                                            Última
                                        </PaginationLink>
                                    </>
                                }
                            </Pagination>
                        </section>
                    </Content>
                </Container>
            </SectionTwo> 

            <AlertLoading msg={"Carregando usuários"} isVisible={isLoading} />
        </>
    )
}