import styled,{css} from 'styled-components';

export const Container = styled.div`

  color: #495057;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  margin-top: 15px;
  width: 95%;
  max-width: 1151px;
  min-width: 800px;
`;

export const Card = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;

  background: #FFFFFF;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* width: 100%; */
  min-width: 300px;
  text-align: center;
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
`;

export const CardTitle = styled.div`
  text-align: left;
  padding: 0.5rem;
  font-size: 1.2rem;
`;

export const CardInformation = styled.div`
  .card-blue {
    width: 20px;
    height: 14.4px;
    background-color: #1d7ade;
    border-radius: 5px;
    margin-right: 5px;
  }
  .card-green {
    width: 20px;
    height: 14.4px;
    background-color: #50bcba;
    border-radius: 5px;
    margin-right: 5px;
  }
  .card-red {
    width: 20px;
    height: 14.4px;
    background-color: #f66363;
    border-radius: 5px;
    margin-right: 5px;
  }
  ul {
    display: flex;
    justify-content: space-around;
  }
  li {
    font-weight: 400;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    margin: .5rem;
    font-size: .9rem;
  }
  li div {
    display: flex;
  }
  p{
    font-weight:600;
  }
`;

export const ProgressBar = styled.div`
  align-items: center;
  text-align: center;
  padding: 20px;

  .progressMax{
    width: 100%;
    max-width: 400px;
    border-radius: 6px;
    height: 5px;
    padding: 4px;
    background: #FF6363;
    text-align: center;
    align-items: center;
  }

  .progressCurrent::before{
    content: "";
    width: 100%;
    max-width: 100px;
    border-radius: 6px;
    height: 7px;
    display: flex;
    margin-top: -4px;
    margin-left: 0px;
    background: #117ADE;
  }
`;

export const ProgressGoal = styled.div`

    margin-top:10px;
    width: 100%;
    max-width: 400px;
    border-radius: 6px;
    height: 7px;
    background: #C4C4C4;

    &::before {
      content: "";
      width: 100%;
      border-radius: 6px;
      height: 7px;
      display: flex;
      margin-top: -4px;
      margin-left: 0px;
      background: #50BCBA;

      ${props => props.progress && css `
          max-width: ${props.widthBarCurrent};
      `}
    }
`;

export const ProgressCurrent = styled.div`
    margin-top:10px;
    width: 100%;
    max-width: 400px;
    border-radius: 6px;
    height: 7px;
    background: #C4C4C4;

    &::before{
      content: "";
      width: 100%;
      border-radius: 6px;
      height: 7px;
      display: flex;
      margin-top: -4px;
      margin-left: 0px;
      background: #117ADE;
      
      ${props => props.progress && css `
          max-width: ${props.widthBarUsed};
      `}
    }
`;