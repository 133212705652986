import React from 'react';
import { Line } from 'react-chartjs-2';

import { Container } from './styles';

function TrafficChart({ traffic }) {
    return (
        <Container>
            <Line 
                data={{
                    options: {
                        maintainAspectRatio: false,
                    },
                    labels: traffic.map((t) => {
                        let date = new Date(t.timestamp * 1000);
                        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                    }),
                    datasets: [{
                        datasetKeyProvider: 'downloadDataSet',
                        label: 'Download',
                        data: traffic.map((t) => {
                            return Number(t.download) / 1024;
                        }),
                        fill: false,
                        borderColor: "rgb(255, 99, 132)",
                    }, {
                        label: 'Upload',
                        datasetKeyProvider: 'uploadDataSet',
                        data: traffic.map((t) => {
                            return Number(t.upload) / 1024;
                        }),
                        fill: false,
                        borderColor: "rgb(54, 162, 235)"
                    }]
                }}
            />
        </Container>
    );
}

export default TrafficChart;