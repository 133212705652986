import React, {useRef, useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-modal';
import { MdClose} from "react-icons/md";

import {useToast} from '~/shared/hooks/toast';
import Input from '~/shared/components/FormValidation/input';
import Select from '~/shared/components/FormValidation/select';
import { Form } from './style';
import {Container, FormRegistration, ModalHeader} from './style';
Modal.setAppElement('#root')

export default function ModalRegistrationLocality({isOpen, onRequestClose, typeAction}){
  const {addToast} = useToast();
  const formRef = useRef(null);

  const handleSubmit = useCallback (async (data, {reset}) =>{

    try{
      const schema = Yup.object().shape({
        city: Yup.string().min(3, 'No mínino 3 caracteres')
          .required('Nome da localidade é obrigatória'),
         
      });
      
      await schema.validate(data, {
        abortEarly: false,
      })

      formRef.current.setErrors({})
        reset();
        addToast(
          {
            type: 'sucess',
            title: 'Cadastro realizado',
            description:'Cidade cadastrada com sucesso',
            
          });
       
    }catch(err){
      if(err instanceof Yup.ValidationError ){
        const errorMessages = {

        };
        addToast(
          {
            type: 'error',
            title: 'Houve um erro',
            description:'Localidade já está cadastrada',
            
          });

        err.inner.forEach(error =>{
          errorMessages[error.path] = error.message;
        })

        formRef.current.setErrors(errorMessages);

      }
    }

  },[addToast])

  //array de select ele vai ser substituido pela API
  
  const selectOptions = [
    {value: 'Ceará-Mirim'},
    {value: 'Touros'},
    {value: 'Macau'}
  ];
  return(

  

        <Container>
          <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-city"

          >
            {typeAction==='register' &&
              <>
              
            
                <ModalHeader>
                    <div>
                      <button onClick={onRequestClose}> <MdClose /> </button>
                    </div>
                
                    <p>Cadastrar Localidade</p>

                </ModalHeader>

                <Form ref={formRef} onSubmit={handleSubmit}> 
                  <Input type="text" name="city" placeholder="Nome" />
                 <Select 
                  name="cities"
                  options={selectOptions}
                  > 
                  <option selected> Selecione uma Cidade</option>
                    {selectOptions.map(option =>(
                      <option key={option.value} value={option.value} >

                        {option.value}
                      </option>
                  
                    ))}
                  </Select>
              
              
              
                <button type="submit">Cadastrar</button>

              
              </Form>
            </>
         }
         {typeAction==='update' &&

          <>
              
            
            <ModalHeader>
                <div>
                  <button onClick={onRequestClose}> <MdClose /> </button>
                </div>

                <p>Editar Localidade</p>

             </ModalHeader>

            <Form ref={formRef} onSubmit={handleSubmit}> 
                <Input type="text" name="city" placeholder="Nome" />
                <Select 
                  name="cities"
                  options={selectOptions}
                  > 
                  <option selected> Selecione uma Cidade</option>
                    {selectOptions.map(option =>(
                      <option key={option.value} value={option.value} >

                        {option.value}
                      </option>
                  
                    ))}
                </Select>



                <button>Altualizar</button>


                </Form>
          </>
                
         
         
        }
          

   

      </Modal>

      </Container>


  );
}