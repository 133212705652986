import React, { useCallback, useState } from 'react';

import { Link } from "react-router-dom";
import { BiRightArrowCircle } from "react-icons/bi";


import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderMgcliente from '../../components/HeaderMgcliente'

import {
    Filters,
    FilterInputs,
    Search,
    ButtonSearch,
    Content,
    ListThe,
    Table,
    Pagination,
   
} from './styles';


export default function ServiceOrders() {

 

    return (
        <>
          <HeaderMgcliente />
            
            <Breadcrumb title="Solicitações" paths={[
        { pageName: "MG CLIENTE", link: "/mgcliente" },
        { pageName: "Solicitações" }
      ]} />
        <Content> 
          


            <ListThe> 
            <Filters> 
           
           <FilterInputs> 

             <form action="">

             <Search>
               <label htmlFor="" > Nome   </label>
              
                <input type="text"  placeholder="Nome do cliente"/>

             </Search>

             <Search>
               <label htmlFor="" > CPF/CNPJ   </label>
              
                <input type="text"  placeholder="CPF do cliente"/>

             </Search>

             <Search>
               <label htmlFor=""> Cidade </label>
               <select className="">
                 <option defaultValue="" >Selecione uma Cidade</option>
                 <option > Ceara Mirim </option>
                 <option > Touros </option>
                 <option > João Camara  </option>
               </select>
             </Search>

             <Search>
               <label htmlFor=""> Status </label>
               <select className="">
                 <option   defaultValue="" >Selecione Status</option>
                 <option > Concluida </option>
                 <option > Pendente </option>
                 
               </select>
             </Search>


             <Search>
              <label htmlFor=""> Periodo </label>
                <div className="all"> 

                    <div>
                      <input type="date"/>
                    </div>

                    <div>
                  
                    <input type="date" className="final"/>
                    </div>


               </div>

             </Search>
             </form>
            
            
           </FilterInputs>

           <ButtonSearch>

           <button>
              <span>Buscar</span>
         </button>

         <button>
              <span>Limpar</span>
         </button>
       </ButtonSearch>

       
          
         </Filters>
            <Table> 
              <thead>
               <tr> 
                 <th> Nome </th>
                 <th> CPF </th>
                 <th> Cidade </th>
                 <th> Data</th>
                 <th colSpan="2"> Status </th>
                 
               </tr>
              </thead>

              <tbody>
                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 1234567891 </td>
                  <td> São Miguel do Gostoso </td>
                  <td> 2 de Setembro de 2020 ás 08:19</td>
                  <td> <span>Concluida </span></td>
                  <td> <Link to='/mgcliente/details/requests' ><BiRightArrowCircle /> </Link></td>
                  

                </tr>

                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 1234567891 </td>
                  <td> São Miguel do Gostoso </td>
                  <td> 2 de Setembro de 2020 ás 08:19</td>
                  <td> <span>Concluida </span></td>
                  <td> <Link to='/mgcliente/details/requests' ><BiRightArrowCircle /> </Link></td>

                </tr>

                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 1234567891 </td>
                  <td> São Miguel do Gostoso </td>
                  <td> 2 de Setembro de 2020 ás 08:19</td>
                  <td> <span className="pendente">Pendente </span></td>
                  <td> <Link to='/mgcliente/details/requests' ><BiRightArrowCircle /> </Link></td>

                </tr>

                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 1234567891 </td>
                  <td> São Miguel do Gostoso </td>
                  <td> 2 de Setembro de 2020 ás 08:19</td>
                  <td> <span className="pendente">Pendente </span></td>
                  <td> <Link to='/mgcliente/details/requests' ><BiRightArrowCircle /> </Link></td>
                </tr>

                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 1234567891 </td>
                  <td> São Miguel do Gostoso </td>
                  <td> 2 de Setembro de 2020 ás 08:19</td>
                  <td> <span>Concluida </span></td>
                  <td> <Link to='/mgcliente/details/requests' ><BiRightArrowCircle /> </Link></td>

                </tr>
              </tbody>
              
              
            </Table>
           
            <Pagination>

            <button>
                <span>Primeira</span>
              </button>

            <button className="page"> 
              <span>1</span>
            </button>

            
            <button className="nextPage"> 
              <span>&raquo;</span>
            </button>
                

            
            <button className="next"> 
              <span>Última</span>
            </button>
                
                
                
            </Pagination>
            
            
           
           
            </ListThe>
           
          </Content>

          
        </>
    )
}

