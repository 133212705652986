import React,{useEffect, useRef} from 'react';
import { useField}  from '@unform/core';


export default function Input({name, ...rest}){
  const inputRef = useRef(null);
  const {fieldName,registerField, defaultValue, error } = useField(name);

  useEffect(()=>{
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })

  }, [fieldName, registerField]);
  
  return(
    <>

      <input ref={inputRef} {...rest} defaultValue={defaultValue} />
      {error && <span style={{color: 'red', fontSize: '14px', marginTop: '5px', marginLeft: '2px'}}> {error}</span>}
    </>
  )

}