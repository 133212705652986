import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FaUserAlt } from 'react-icons/fa';
import { BsToggleOn } from "react-icons/bs";

import { useAuth } from '~/shared/hooks/auth';

import { NavDark, Logo, IconDiv, IconTheme, DivIcons } from './styles';

import LogoBranco from '../../../../assets/logo-mgnet-branco.png';

export default function HeaderDark() {
  const [menuVisible, setMenuVisible] = useState(false);

  const { signOut, user } = useAuth();

  const history = useHistory();

  const handleShowMenu = useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [menuVisible])

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleNavigateToAdminPage = useCallback(() => {
    history.push("/admin");
  }, [history]);

  const handleNavigateToAlterPasswordPage = useCallback(() => {
    history.push("/alter-password");
  }, [history]);

  return (
    <NavDark>

      <Logo>
        <img src={LogoBranco} alt="Logo branca mgnet" />
      </Logo>

      <DivIcons>
        {/* <IconTheme>
          <BsToggleOn />
        </IconTheme> */}
        
        <IconDiv visible={Number(menuVisible)}>
          <button onClick={handleShowMenu}><FaUserAlt /></button>
          <div>
            { user.is_admin && (
              <button onClick={handleNavigateToAdminPage}> 
                <p>Entrar como admin</p>
              </button>
            )}
            <button onClick={handleNavigateToAlterPasswordPage}>
              <p>Alterar senha</p>
            </button>
            <button onClick={handleSignOut}> <p>Sair</p> </button>
          </div>
        </IconDiv>
      </DivIcons>
    </NavDark>
  )
}