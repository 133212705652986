import React from 'react';

import { Container, Loader } from './styles';

function Spinner({ size = "2rem", borderWidth = "0.4rem", color = "#3498db", backgroundColor = "#e9e9e9" }) {
  const spinnerTransition = {
    repeat: Infinity,
    repeatType: "loop",
    duration: 1,
    ease: "easeOut"
  };

  return (
    <Container size={size}>
      <Loader
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        borderWidth={borderWidth}
        animate={{ rotate: 360 }}
        transition={spinnerTransition} />
    </Container>
  );
}

export default Spinner;