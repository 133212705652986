import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1300px;
  min-width: 550px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 80vh;

  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #33CC95;
    }
  }
`;

export const CardTable = styled.div`
  background-color: #f6f6f6;
  width: 600px;

  margin: 30px;
  padding: 20px;
  position: relative;

  box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  
  p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;

    &:hover {
      color: burlywood;
    }
  }
`;

export const HeaderTable = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: left;

  background-color: #A6DDDC;
  width: 100%;
  height: 39px;
  padding: 10px;

  i {
    font-size: 1.4rem;
    margin-right: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  

  margin-top: 22px;
  div {
    font-size: .8rem;
    display: flex;
    flex-direction: column;
    width: 80%;

    margin: 20px 20px;
  }
  label {
    font-size: .8rem;
  }
  input {
    height: 40px;
    background: #F8F9FA;
    box-shadow: inset 0px 0px 3px rgba(8, 8, 0, 0.25);
    border-radius: 1px;
    padding: 10px;
  }
  button {
    width: 128px;
    height: 35.64px;
    background: #33CC95;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
  button:hover {
    background: #35A47C;
  }
  textarea {
    padding: 8px;
  }
  ul {
    background: #fff;
    border: 1px solid #BFBFBF;
    width: 450px;
    height: 150px;
    overflow-y: auto;
    
    margin: 20px 0;
    padding: 8px;
    
    list-style: none;
    font-size: 12px;
    color: red;
  }
`;