import React from 'react';

import Spinner from '../Spinner';

import {
  Container
} from './styles';

function AlertLoading({ msg, isVisible }) {
  return (
    <Container visible={isVisible}>
        <div className="content">
            <Spinner />
            <label>{msg}</label>
        </div>
    </Container>
  )
}
export default AlertLoading;