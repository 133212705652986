import React, { useCallback, useRef, useEffect } from "react";
import { useField } from "@unform/core";


export default function Checkbox({name, value, label, ...rest}) {
  const inputRef = useRef(null);
  const {fieldName,registerField, defaultValue, error } = useField(name);

  const defaultChecked = defaultValue === value;

  useEffect(()=>{
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked
      },
      clearValue: ref =>{
        //ao redefinir o formulário ele volta para o seu padrão inicial
        ref.current.checked = defaultChecked;

      },
      setValue: (ref, value) =>{
        ref.current.checked = value
      },

    })

  }, [defaultValue, fieldName, registerField, defaultChecked])

  
return(
  <div>
    <input 
      
      ref={inputRef}
      value={value}
      type="checkbox"
      id={fieldName}
      {...rest}
    />
     <label htmlFor={fieldName} key={fieldName}>
        {label}
      </label>

{error && <span style={{color: 'red', fontSize: '14px', marginTop: '5px', }}> {error}</span>}
  </div>
  
);


}