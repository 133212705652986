import styled, {css} from 'styled-components';

export const Container = styled.div`
  ${props => props.visible 
    ? css`display: flex;`
    : css`display: none;`
  }
    
   

    background-color: rgba(0, 0, 0, .33);
    flex: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    height: 100%;
   

  div.content {
      margin: 20px 30px;
      border-radius: 4px;
      background-color: #fff;
      display: flex;
      padding: 10px;
      align-items: center;
  }

  div.content label {
      color: #727272;
      font-family: Roboto;
      font-size: 0.875rem;
      margin-left: 10px;
  }
`;