import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa";

import api from "~/shared/services/api";

import { formatCpfCnpj } from "~/shared/utils/StringUtils";

import Breadcrumb from "~/shared/components/Breadcrumb";

import {
  Section,
  Container,
  Time,
  Content,
  TableHeader,
  Title,
  Inputsearch,
  Campsearch,
  ClientItem,
  DataItem,
  DataRow,
  ClientName,
} from "./styles";

export default function Connections() {
  const [currentTime, setCurrentTime] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [user, setUser] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [connectionsSynsuite, setConnectionsSynsuite] = useState([]);
  const [recoveringConnections, setRecoveringConnections] = useState(false);

  const history = useHistory();

  /**
   * Atualiza a cada minuto hora atual que aparece na UI
   */
  const updateTime = useCallback(() => {
    let now = new Date();
    let hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
    let min = now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();

    setCurrentTime(`${hours}:${min}`);
  }, []);

  /**
   * Recupera da API do synsuite as conexões que passaram no filtro dos
   * dados informados pelo usuário.
   */

  /**
   * Leva o usuário do suporte para tela de atendimento.
   */
  const initAtendance = useCallback(
    (connectionId) => {
      sessionStorage.clear();

      history.push({
        pathname: "connections/details",
        state: { connectionId },
      });
    },
    [history]
  );

  const handleSetCpf = (value) => {
    setCpf(() => {
      value = value.replace(/\D/g, "");

      if (value.length <= 11) {
        if (value.length <= 6) {
          return value.replace(/(\d{3})(\d)/, "$1.$2");
        } else if (value.length <= 9) {
          return value.replace(/(\d{3})(\d{3})(\d)/, "$1.$2.$3");
        } else {
          return value.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
        }
      } else {
        if (value.length <= 12) {
          return value.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "$1.$2.$3/$4");
        }
        return value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d)/,
          "$1.$2.$3/$4-$5"
        );
      }
    });
  };

  const handleGetConnections = useCallback(() => {
    if (recoveringConnections) {
      return;
    }

    setRecoveringConnections(true);

    let paramsSearch = {
      page: 1,
      limit: 25,
    };

    if (user.length > 2) {
      paramsSearch["user"] = user;
    }
    if (name.length > 2) {
      paramsSearch["client_name"] = name;
    }
    if (cpf.length > 2) {
      paramsSearch["cpf"] = cpf.replace(/\D/g, "");
    }
    if (contractNumber.length > 2) {
      paramsSearch["contract_number"] = contractNumber;
    }

    api
      .get("/my_synsuite/api/v2/connections/", {
        params: paramsSearch,
        timeout: 4000,
      })
      .then(function (response) {
        setConnectionsSynsuite(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert("Não foi possível recuperar as conexões");
      })
      .finally(function () {
        setRecoveringConnections(false);
      });
  }, [contractNumber, cpf, name, user, recoveringConnections]);

  useEffect(() => {
    const intervalUpdateTime = setInterval(updateTime, 1000);

    return () => clearInterval(intervalUpdateTime);
  }, [updateTime]);

  useEffect(() => {
    setRecoveringConnections(true);

    api
      .get("/my_synsuite/api/v2/connections/", { timeout: 6000 })
      .then(function (response) {
        setConnectionsSynsuite(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert("Não foi possível recuperar as conexões");
      })
      .finally(function () {
        setRecoveringConnections(false);
      });
  }, []);

  return (
    <div>
      <Helmet title="Seedot - Suporte" />

      <Breadcrumb
        title={"Conexões"}
        paths={[
          { pageName: "Suporte", link: "/support/connections" },
          { pageName: "Conexões" },
        ]}
      />
      <Container>
        <Section>
          <Title>Pesquisar Cliente</Title>

          <form>
            <Campsearch>
              <label htmlFor="">Nome</label>
              <Inputsearch
                type="text"
                placeholder="Nome Completo"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  handleGetConnections();
                }}
              />
            </Campsearch>
            <Campsearch>
              <label htmlFor="">CPF/CNPJ</label>
              <Inputsearch
                type="text"
                placeholder="CPF/CNPJ"
                maxLength="18"
                value={cpf}
                onChange={(event) => {
                  handleSetCpf(event.target.value);
                  handleGetConnections();
                }}
              />
            </Campsearch>
            <Campsearch>
              <label htmlFor="">Conexão</label>
              <Inputsearch
                type="text"
                placeholder="Usuário pppoe"
                value={user}
                onChange={(event) => {
                  setUser(event.target.value);
                  handleGetConnections();
                }}
              />
            </Campsearch>
            <Campsearch>
              <label htmlFor="">Nº Contrato</label>
              <Inputsearch
                type="number"
                placeholder="Nº do Contrato"
                value={contractNumber}
                onChange={(event) => {
                  setContractNumber(event.target.value);
                  handleGetConnections();
                }}
              />
            </Campsearch>
            {/* <SearchButton type="button" value="Pesquisar" onClick={searchConnections}/> */}
          </form>
        </Section>

        <Time>
          <p>Hora Atual</p>
          <p>{currentTime} hs</p>
        </Time>

        <Content>
          <ul>
            <TableHeader>
              <DataItem>
                <h4>Nome</h4>
              </DataItem>
              <DataItem>
                <h4>Dados</h4>
              </DataItem>
              <DataItem>
                <h4>Endereço</h4>
              </DataItem>
              <DataItem></DataItem>
            </TableHeader>
            {connectionsSynsuite.map((connection) => {
              return (
                <ClientItem key={connection.id}>
                  <DataItem>
                    <ClientName>
                      <h2>{connection.client_name}</h2>
                    </ClientName>
                  </DataItem>
                  <DataItem>
                    <DataRow>
                      <strong>CPF/CNPJ:</strong>
                      <span> {formatCpfCnpj(connection.client_cpf)}</span>
                    </DataRow>
                    <DataRow>
                      <strong>E-mail:</strong>
                      <span> {connection.client_email}</span>
                    </DataRow>
                    <DataRow>
                      <strong>Usuário:</strong>
                      <span> {connection.user}</span>
                    </DataRow>
                  </DataItem>
                  <DataItem>
                    <DataRow>
                      <strong>Rua:</strong>
                      <span>
                        {" "}
                        {connection.street}, {connection.number}
                      </span>
                    </DataRow>
                    <DataRow>
                      <strong>Bairro:</strong>
                      <span> {connection.neighborhood}</span>
                    </DataRow>
                    <DataRow>
                      <strong>Cidade:</strong>
                      <span> {connection.city}</span>
                    </DataRow>
                  </DataItem>
                  <DataItem>
                    <div className="init-attendance-button-container">
                      <button onClick={() => initAtendance(connection.id)}>
                        <FaLocationArrow />
                        Iniciar Atendimento
                      </button>
                    </div>
                  </DataItem>
                </ClientItem>
              );
            })}
          </ul>
        </Content>
      </Container>
    </div>
  );
}
