import styled,{css} from 'styled-components';





export const Content = styled.div`
margin: 20px auto;

 
`;


export const Filters = styled.div`
  margin: 0 auto;
  width: 90%;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;


  form{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); 
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    
  }

`;

export const FilterInputs = styled.div`
  padding: 5px;
 

`;

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px;
  select, input{
    height: 30px;
    width: 80%;
    margin-top: 1%;
    border: 1px solid #BBBBBB;
    background-color: #FFFFFF;
    padding-left: 4px;
    border-radius: 4px;
    
  }

  label{
    color: #495057;
    font-size: 0.7rem;
    font-weight: 100;
  }
  input[type="date"]{
   width: 217px;

 }

 input[class="final"], label[class="final"]{
    margin-left: 8px;
    
 }


 div{
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   
 }
  
 .all{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
 }
`;

export const ButtonSearch = styled.div`

  display: flex;
  justify-content: flex-end;
  margin-right: 12%;
  button {
    position: relative;
    border: none;
    width: 140px;
    height: 30px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 1px;
    cursor: pointer;
    outline: none;
  
  &:hover{
    background-color: rgba(80, 188, 186, 0.8);
  }

    span{  
      font-size: 0.875rem;
      color: #FFFFFF;
      font-weight: 500;
    }

 }

 
`;

export const ListThe = styled.div`
  margin: 0 auto;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
 


`;
export const Table = styled.table`
  flex: 1;
  margin: 10px auto;
  border-collapse: collapse;
  background-color: #F8F9FA;
  width: 100%;
  background-color: #FFFFFF;
  overflow:hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  svg{
    color: #495057;
    font-size: 1.3rem;
    cursor: pointer;
  }
 

thead th{

  background-color: #50BCBA;
}
thead th{

  color:#FFFFFF;
}


th, td{
  text-align: left;
  padding-left: 3%;
  padding: 10px;
  font-size: 1rem;
  color: #495057;
  font-weight: 400;
}

  tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  tr:last-child {
      border-bottom: none;
  }

  tr:nth-child(odd) {
    background-color: #F8F9FA;
  }

 

   tr td:nth-child(1){
    text-transform: uppercase;
  }

`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px; 


  button {
    border: none;
    width: 70px;
    /*width: 5%; */
    height: 30px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin-left: 10px;
     
  &:hover{
    background-color: rgba(80, 188, 186, 0.8);
  }

  span{  
    font-size: 0.875rem;
    color: #FFFFFF;
    font-weight: 500;
  }

}

 .next{
  width: 70px;
  /* width: 5%; */

 }

 .page, .nextPage{
   width: 40px;

   /* width: 3%; */

 }

  
`;


export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  ${props => !props.visible && css `
  display: none;



`}


`;

export const Modal = styled.div`
  margin: 200px auto;
  border-radius: 4px;
  width: 40%;
  max-height: 400px;
  background-color: white;
  box-sizing: border-box;
  overflow-y: auto;


  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #50BCBA;

  }
  

  ::-webkit-scrollbar-thumb {
    background: #f1f1f1; 

  }

  ::-webkit-scrollbar-thumb:hover {
    background: #D0CECE;
  }

  ${props => !props.visible && css `
  display: none;



`}

`;

export const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  button{
    border: 0;
    background: inherit;
    cursor: pointer;
  }
  svg{
      font-size: 1.2rem;
      cursor: pointer;
      margin-left: 20%;
      color: #495057;
    }

    ${props => !props.visible && css `
  display: none;



`}

`;

export const ModalIdentificacionR = styled.div`
  display: flex;
  width: 80%;
  margin-left: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 0.8rem;
  color: #495057;
  font-weight: 400;

  svg{
    color: #495057;
    font-size: 2.5rem;
    margin-right: 1%;
  }

  div{
    display: flex;
    flex-direction: column;

  }
  div span{
    line-height: 30px;
  }

`;

export const ModalContent = styled.div`
  color: #495057;
  h2{
    font-weight: 700;
    font-size: 1.3rem;
  }
  width: 80%;
  padding: 20px;
  line-height: 30px;

`;


      
          

        