import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;

    button { 
        display: block;
        margin: 20px auto;
        border: initial;
        cursor: pointer;
        background: #63cbff;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 1.16667px;
        width: 183px;
        height: 35px;
        font-weight: bold;
    }
`

export const Title = styled.h3`
    color: #495057;
    font-size: 1.3rem;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
`

export const DataItem = styled.ul`
    display: flex;
    flex-direction: column;

    li {
        margin: 10px 0;
        list-style-type: none;
        font-size: 1rem;
        font-weight: 400;
    }
    strong {
        font-family: robotobold;
    }
`