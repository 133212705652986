import React from 'react';
import { Link } from "react-router-dom";
import { FiFileText } from "react-icons/fi";


import {  LogoMgnet, Header, Menu } from './styles';

import LogoColorful from '~/shared/assets/logo-mgnet.png';




export default function HeaderSupport() {
    
        return (
            <>
              <Header>
                <LogoMgnet>
                  <Link to='/smo' >
                    <img src={LogoColorful} alt="Logo branca MGNET"/>
                  </Link>
                </LogoMgnet>
            
                <Menu>
                  
                  <li><Link to='/support/reports' > <FiFileText/> <span> Relatórios </span> </Link></li>
                  
                </Menu>
              </Header>
            </>
                
              );
            
    
}
