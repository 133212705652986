import React, { useCallback, useEffect, useState } from 'react';
import { FiFileText } from "react-icons/fi";


import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderSupport from '../../shared/HeaderSupport'



import {
    Container,
    Identification,
    Details,
    SectionOne,
    TitleContent,
    SectionContent,

} from './styles';



export default function ServiceOrders() {


    return (
      <> 
        <HeaderSupport />

            <Breadcrumb title="Relato" paths={[
        { pageName: "suporte", link: "/support/dashboard" },
        { pageName: "Detalhes do Relato" }
      ]} />
      
     
      
        <Container>
            
      
        <Identification> 
          <FiFileText />


       
          <Details> 
            <span> Protocolo do Relato: 253040</span>
            <span> Atendente: Josefa Dalania da Silva </span>
            
          </Details>

      </Identification>

    
     

      <SectionOne> 
      <TitleContent> 
          <span> <img src="" alt=""/> </span>
          <h3> Detalhes do Atendimento </h3>

        </TitleContent>

        
        <SectionContent>

        <table>
    
          <thead>
            <tr>
                <th colSpan="3"></th>
        
        
            </tr>
         </thead>
           <tbody> 
              <tr>
                <td> <strong>  Atendente: </strong> ANA PATRICIA DE LIRA SILVA DUARTE	</td>
                <td> <strong>  Cliente: </strong>  ANA PATRICIA DE LIRA SILVA DUARTE	 </td>
                <td>  <strong>  CPF: </strong>  12345678911  </td>
              
              </tr>
              <tr>
                <td> <strong>Data: </strong> 20/02/2021 </td>
                <td> <strong> Protocolo: </strong> 10203040</td>
                <td>  <strong> Area: </strong> Suporte Técnico</td>

                
              </tr>
              <tr>
                <td><strong>  Inicio do Atendimento: </strong> 16:55</td>
                <td>  <strong> Fim do Atendimento: </strong> 17:25  </td>
                <td>  <strong> Duração do Atendimento: </strong> 30:00 min  </td>


              </tr>
              
            </tbody>
          </table>



        </SectionContent>

      </SectionOne>

      <SectionOne> 

        <TitleContent> 
          <span> <img src="" alt=""/> </span>
          <h3> Descrição </h3>

        </TitleContent>
          <SectionContent>
            <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book
              . It has survived not only five centuries, but also the leap into electronic typesetting, 
              remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
              sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
              like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </SectionContent>

    
      </SectionOne>

      <SectionOne> 

        <TitleContent> 
          <span> <img src="" alt=""/> </span>
          <h3> Relato do Atendimento </h3>

        </TitleContent>
        <SectionContent>
          <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
            when an unknown printer took a galley of type and scrambled it to make a type specimen book
            . It has survived not only five centuries, but also the leap into electronic typesetting, 
            remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
            sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
            like Aldus PageMaker including versions of Lorem Ipsum.</p>


        </SectionContent>


       </SectionOne>

      

        </Container>
        </>

    )
}

