import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  max-width: 1300px;
  min-width: 550px;
`

export const Content = styled.div`
  min-height: 109px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 20px;
  background-color: #F8F9FA;
  min-width: 550px;
`
export const SearchCustomer = styled.div`
  padding: 10px 5px;
  display:flex;
  flex-direction: column;
  justify-content: top;
  

  h2{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 21px;
    color: #1C1F73;
    margin-left: 1px;
  }

  form{
    display:flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  label{
    font-size: 0.75rem;
    line-break: 22px;
    font-weight: 600;
    margin: 3px 0;
    font-style: normal;
  }

  input{
    border: none;
    background: #F8F9FA;
    width: 181px;
    height: 30px;
    border-radius: 4px;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    padding-left: 5px;

    &:hover{
      background: #fff;
    }
 }

 input[class="name"]{
    width: 400px;
 }
 
 input::placeholder{
    color: #ACACAC;
    font-size: 0.85rem;
    font-weight: 300;
    /* padding-left: 1px; */
 }
`

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px;
`

export const Button = styled.div`
  padding-top: 16px;
  margin-left: 16px;
`

export const SecondContent = styled.div`
  display: flex;
  min-width: 590px;
`

export const Table = styled.table`
  flex: 1;
  border-collapse: collapse;
  margin: 10px 0px;
  background-color: #F8F9FA;
  width: 100%;
  overflow:hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  thead {
    background-color: #50BCBA;

  }

  th, td {
   text-align: left;
   padding-left: 10px;
  }

  td {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  tbody tr {
    border-bottom:  1px solid rgba(0, 0, 0, 0.3);
  }

  tbody tr:hover{
    background: #fff;
  }

  th {
    color: rgba(248, 249, 250, 1);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 2.5rem;
  }

  tr:last-child {
    border-bottom: none;
  }

  .nameClient{
    font-size: 1.125rem;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1C1F73;
  }

  .dateClient li{

    font-size: 0.9rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    list-style-type: none;
    padding-top: 1px;

  }
  li span{
    font-size: 0.85rem;
    font-family: Roboto;
    color: #495057;
  }

  li span strong {
    font-weight: 100;
    font-family: RobotoMedium;
  }

  .liIcon svg {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #495057;
    cursor: pointer;
    
    &:hover {
      color: blue;
    }
  }

  .liIcon button {
    border: 0;
    background: none;
  }


`




