import styled from 'styled-components'

export const ContainerGrapichs = styled.main`
width: 100%;
max-width: 1110px;


`;
export const Container = styled.div`


    margin: auto;
    width: 100vw; 
    height: 100%;
    min-width: 350px;
    max-width: 1115px;
    display: flex;
    flex: 1;
    
    canvas { 
        width: 100% !important;
    }

   
    
   

`;