import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  ${(props) => css`
    height: ${props.size};
    min-height: ${props.size};
    width: ${props.size};
    min-width: ${props.size};
  `}
`;

export const Loader = styled(motion.span)`
  display: block;
  ${(props) => css`
    height: ${props.size};
    width: ${props.size};
  `}
  border: ${(props) => props.borderWidth} solid ${(props) => props.backgroundColor};
  border-top: ${(props) => props.borderWidth} solid ${(props) => props.color};
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0
`;