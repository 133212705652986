import React, { useCallback, useState } from 'react';
import Collapsible from 'react-collapsible';

import HeaderSMO from '../../components/HeaderSMO';
import SearchButton from '../../components/SearchButton'

import { Link } from "react-router-dom";

import { FaUserAlt, FaServer, FaHome } from "react-icons/fa";
import { VscHome } from "react-icons/vsc";
import { BiSearchAlt } from 'react-icons/bi';
import Breadcrumb from '~/shared/components/Breadcrumb';

import {

  Content,
  SearchCustomer,
  Search,
  Button,
  SecondContent,
  Table,
  Container
} from './styles';




export default function SearchOthers() {


  return (
    <>
      < HeaderSMO />

      <Breadcrumb title="Cabo" paths={[
        { pageName: "SMO", link: "/smo" },
        { pageName: "Cabo" }
      ]} />
      <Container>
        <Content>
          <SearchCustomer>
            <h2> PESQUISAR EQUIPAMENTO </h2>
            <form action="" method="">
              <Search>
                <label for=""> NAS </label>
                <select className="selectNas">
                  <option value="" className="selectDisable" disabled selected>Selecione um Ponto de Acesso</option>
                  <option > CE-MGNET-PUR-CABO-CANABRAVA </option>
                  <option > CE-MGNET-PUR-CABO-CANABRAVA </option>
                  <option > CE-MGNET-PUR-CABO-CANABRAVA </option>
                </select>
              </Search>

              <Search>
                <label for=""> Mac do Equipamento </label>
                <input type="text" placeholder="e0:19:54:e0:c7:e6" />
              </Search>

              <Search>
                <label for=""> Conexão</label>
                <input type="text" placeholder="usuário pppoe" />
              </Search>

              <Search>
                <label>&nbsp;</label>
                <SearchButton />
              </Search>

            </form>

          </SearchCustomer>
        </Content>
        <SecondContent>

          <Table>
            <thead>
              <th> NAS </th>
              <th> POP </th>
              <th colSpan="2"> CONEXÃO </th>
            </thead>


            <tr>
              <td>
                <ul className="dateEquipament">
                  <li> CE-MGNET-PUR-CABO-CANABRAVA</li>
                </ul>
              </td>


              <td>
                <ul className="dateEquipament">
                  <li>  CEARA-MIRIM</li>
                </ul>
              </td>


              <td>
                <ul className="dateEquipament">
                  <li> jefferson.monteiro </li>
                </ul>
              </td>

              <td>
                <ul className="dateEquipament">
                  <li className="liIcon"> <Link to='/' >  <BiSearchAlt /> </Link></li>



                </ul>
              </td>


            </tr>

            <tr>
              <td>
                <ul className="dateEquipament">
                  <li>   CE-MGNET-PUR-CABO-CANABRAVA</li>
                </ul>
              </td>


              <td>
                <ul className="dateEquipament">
                  <li>  CEARA-MIRIM</li>
                </ul>
              </td>


              <td>
                <ul className="dateEquipament">
                  <li> jefferson.monteiro </li>
                </ul>
              </td>

              <td>
                <ul className="dateEquipament">
                  <li className="liIcon"> <Link to='/' >  <BiSearchAlt /> </Link></li>



                </ul>
              </td>


            </tr>





          </Table>

        </SecondContent>
      </Container>




    </>

  )
}