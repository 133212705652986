import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";

import { Container } from './styles';

function Breadcrumb({ title, paths }) {
  return (
    <Container>
      <p>{title}</p>
      <ul>
        <li>
          <Link to="/home"><FaHome /></Link>
        </li>
        {paths.map((path, index) => (

          <li key={index}>&nbsp;/&nbsp;
            {index === paths.length - 1 || !path.link
              ? path.pageName
              : <Link to={path.link}>{path.pageName}</Link>
            }
          </li>
        ))}
      </ul>
    </Container >
  );
}

// Nome , link

export default Breadcrumb;