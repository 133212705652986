import React from 'react';

import { Link } from "react-router-dom";

import {FaUserAlt, FaServer, FaHubspot } from "react-icons/fa";
// import { VscHome } from "react-icons/vsc";

import LogoColorful from '~/shared/assets/logo-mgnet.png';
// import IconWifi from '~/shared/assets/wifi.svg';
// import IconCable from '~/shared/assets/cabo.png';

import { Header,LogoMgnet,Menu} from './styles';


export default function HeaderSMO() {
  return (
<>
  <Header>
    <LogoMgnet>
      <Link to='/smo' >
        <img src={LogoColorful} alt="Logo branca MGNET"/>
      </Link>
    </LogoMgnet>

    <Menu>
      <li>
        <Link to='/smo'> 
          <FaUserAlt />
          <span> Cliente </span>
        </Link>
      </li>
      <li>
        <Link to='/smo/olt' >
          <FaServer />
          <span> ONU </span>
        </Link>
      </li>
      <li>
        <Link to='/smo/onuallocated'>
          <FaHubspot />
          <span> Alocar ONU </span>
        </Link>
      </li>
      {/* <li><Link to='/smo/others'  className="radio"> <img src={IconWifi} alt="Imagem de wifi"/> <span> Radio </span></Link></li> */}
      {/* <li><Link to='/smo/others'> <img src={IconCable} alt="Imagem de cabo"/> <span> Cabo </span></Link></li> */}
    </Menu>
  </Header>
</>
    
  )
  }