import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #495057;
  min-height: 700px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0px 20px;
  background-color: #FFFFFF;
 

`;


export const Identification = styled.div`
  display: flex;
  padding: 20px;

svg{
  color: #495057;
    font-size: 2.5rem;
}
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);


`;

export const Details = styled.div`
  margin-left: 2%;
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #495057;


`;

export const SectionOne = styled.div`
  padding-top: 20px;


`;

export const TitleContent = styled.div`
  display: flex;
  margin-left: 1%;
  padding-top: 10px;
  img{
  width: 16px;
}
  h3{
    font-size: 1rem;
    font-weight: 700;

}

  svg{
    color: #495057;
    font-size: 1.2rem;
}


`;

export const SectionContent = styled.div`

  margin-left: 1%;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 90%;
  padding-top: 10px;


  table{

    flex: 1;
    border-collapse: collapse;
    margin: 20px auto;
    width: 100%;
    overflow:hidden;
    

    }

  tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  }
  tr:last-child{
    border-bottom: none;
  }

  tr:nth-child(odd){
    background: #f8f9fa;
  }

  td{
    padding: 20px;
  }
  thead tr{
    border-bottom: none;

  }

 

`;









