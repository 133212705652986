import styled from 'styled-components';
import Background from '~/shared/assets/check-solid.svg';

export const Primary = styled.div`
  padding: 20px 10px;
  background-color: #ECEDEF;

  p{
  color: #495057;
  font-weight: 400;
  font-size: 1.4375rem;
  }

  ul{
    display: flex;
  }
  li{
    list-style-type: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: #48494E;
    padding-left: 4px;
  }

`
export const Container = styled.div`
 display: flex;
  flex-direction: column;
  margin: 0px auto;

`
export const Content = styled.div`
  min-height: 109px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0px 20px;
  background-color: #F8F9FA;

`
export const SearchCustomer = styled.div`
  padding: 10px 5px;
  display:flex;
  flex-direction: column;
  justify-content: top;    

  h2{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 21px;
    color: #1C1F73;
    margin-left: 1px;
  }

  form{
    display:flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;

  }

  label{
    font-size: 0.75rem;
    line-break: 22px;
    font-weight: 600;
    margin: 3px 0;
    font-style: normal;

  }

  input, select{
   border: none;
   background: #F8F9FA;
   width: 181px;
   height: 30px;
   border-radius: 1px;
   box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);

 }
 option[value=""][disabled] {
  display: none;
  color: pink;

}

.selectNas{
  width: 208px;

 }

 

 input::placeholder{
  color: #ACACAC;
  font-size: 0.875rem;
  font-weight: 300;
  padding-left: 8px;
 }


`

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px;

`

export const Button = styled.div`
  padding-top: 16px;
  margin-left: 16px;

`

export const SecondContent = styled.div`
   display: flex;

 
 

`

export const Table = styled.table`
 flex: 1;
  border-collapse: collapse;
  margin: 10px 20px;
  background-color: #F8F9FA;
  width: 100%;
  overflow:hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  thead{
    background-color: rgba(28, 31, 115, 0.9);


  }
  th, td {
   text-align: left;
   padding-left: 10px;
  }

  td {
    padding-bottom: 10px;
  }

  tr {
    border-bottom:  1px solid rgba(0, 0, 0, 0.3);
  }

  th {
    color: rgba(248, 249, 250, 1);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 2.5rem;
  }

  tr:last-child {
    border-bottom: none;
  }


  li span{
    font-weight: 700;
  }
  li a{
    color: #1F2397;
    font-weight: rgba(31, 35, 151, 1);
    font-weight: 700;
  }


  .liIcon svg {
    font-size: 24px;
    color: #495057;
    cursor: pointer;
  }
.dateEquipament li{
    list-style-type: none;
    padding-top: 10px;
    font-weight: 100;
    margin-right: 3px;
    font-size: 0.85rem;
    font-family: RobotoMedium;
    color: #495057;

}


`

