import React, { useState, useEffect, useCallback } from 'react';
import { FaHubspot } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { RiDeleteBin5Line } from "react-icons/ri";

import api from '~/shared/services/api';

import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderSMO from '../../components/HeaderSMO';
import { getHumanFormatedDate } from '~/shared/utils/FormatDataUtils';

import { 
  Container,
  Content,
  CardTable,
  HeaderTable,
  AllocList,
  AddOnu,
  Top,
} from './styles';

export default function SearchAlloc() {

  const [onus, setOnus] = useState([])

  const getOnus = useCallback(
    () => {
      api
      .get('smo/api/onus/alloc/')
      .then(response => setOnus(response.data))
      .catch ((error) => {
        alert("Não foi possível recuperar os dados da API")
      })
    },[]) 

  useEffect(() => {
    
    getOnus()      
    },[getOnus])

  const handleDeleteOnu = useCallback( async (id) => {
    
    try{
      const confirm = window.confirm("Deseja mesmo excluir?")

      if (confirm) {
        await api
          .delete(`smo/api/onus/alloc/${id}/`)
          getOnus()
      }
    } catch (error) {
      alert("Não foi possível excluir o registro")
    }
  },[])

  return (
    <>
      < HeaderSMO />
      
      <Container>

        <Top>
          <Breadcrumb title="Adcionar ONU" paths={[
            { pageName: "SMO", link: "/smo/olt" },
            { pageName: "Pré-provisionadas" }
          ]} />
          
          <AddOnu>
            <FaHubspot />
            <Link to="/smo/onuallocated/add">Adicionar</Link>
          </AddOnu>
        </Top>

        <Content>
          <CardTable>

            <HeaderTable>
              <i><FaHubspot /></i>
              <span>Pré-provisionadas</span>
            </HeaderTable>
            
            <AllocList>
              <table>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Serial</th>
                    <th>PPPOE</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {onus.map((onu) => (
                    <tr>
                      <td>{onu.created_at === null ? "" : getHumanFormatedDate(onu.created_at)}</td>
                      <td>{onu.serial}</td>
                      <td>{onu.pppoe_user}</td>
                      <td>
                        <button onClick={() => handleDeleteOnu(onu.id)}><RiDeleteBin5Line /></button>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </AllocList>

          </CardTable>
        </Content>

      </Container>
    </>

  )
}