import React from 'react'

import {Container} from './style';
import Toast from './Toast'

export default function ToastContainer({ message:messages }){


  return(
    <Container>
      {messages&& (messages.map((message)=>(
         <Toast key={message.id} message={message}/>
         
      )))}

    </Container>
  );
}