import styled, {css} from 'styled-components';
import {Form as Unform } from '@unform/web'

export const SectionOne = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;

  margin: 0 auto;
  
 
`
export const Form =  styled(Unform)`

    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
   

   
    label{
      margin-top: 10px;
      color: #495057;
    }

    input, select, button{

      width:250px;
      height: 25px;
      border: none;
      margin:8px;
      background: #F8F9FA;
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
      border-radius: 1px;
      padding-left: 4px;

    }
    /* option:nth-child(1){
      display: none;
    } */
    select{
      width: 150px;

    }
    >button{
      cursor: pointer;
      background: transparent;
      
      background: #2F3A60;
      border-radius: 1px;
      border: none;
      
      max-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        color: #FFFFFF;
        font-size: 1rem;
      }
   
   }

   .add{
     border: none;
     background: none;
     box-shadow: none;
     
     
     
     svg{
       font-size: 2rem;
       color: #2F3A60;
     }
   }      

`

export const FormTop = styled.div`
display: flex;
`

export const SectionTwo = styled.section`
    display:flex;
    justify-content: center;
`

export const Container = styled.div`
    margin: 20px auto;
    width: 95%;
    max-width: 1250px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
`

export const Content = styled.div`
    section {
      border-radius: 10px;
      padding-top: 37px;
      background: #2F3A60;
      margin: 0 auto;

      position: absolute;
      top:0px;
      left:0px;
      width:100%;
    }
    .container {
        overflow-y: auto;
        height: 65vh;

      ::-webkit-scrollbar {
        width: 6px;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-track {
        background: #ccc;
      }
      ::-webkit-scrollbar-thumb {
        background: #949494;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #495057;
      }
    }

    button:hover {
      cursor: pointer;
      opacity: .6;
    }

    tr {
        background-color: #fff;
    }
    table {
      border-collapse: collapse;
      width:100%;
    }

    td, th {
      color: #495057;
      padding: 10px 25px;
    }

    tbody tr:nth-child(odd){
        background-color: #F1F3FA;
        }
    th {
      height: 0;
      line-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      color: transparent;
      border: none;
      white-space: nowrap;
    }
    th div{
      position: absolute;
      background: transparent;
      color: #fff;
      padding: 9px 25px;
      top: 0;
      margin-left: -25px;
      line-height: normal;
    }

    td button{
      background: transparent;
      border: none;
      color: #2F3A60;
      svg{
        font-size: 1.1rem;
        color: #2F3A60;
      }
    }
`
export const Pagination = styled.div`
  position: absolute;
  bottom: -65px;
  right: 0;
`;

export const PaginationLink = styled.button`
    border: none;
    font-size: 0.875rem;
    padding: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin: 7px;
    padding: 15px 15px;
    color: #fff;

    ${props => props.selected 
      ? css`background-color: #219653;` 
      : css`background: rgba(28, 31, 115, 1);`
    }

    ${props => props.disabled 
      ? css`background-color: #acacac;`
      : css`
        &:hover {
          background-color: rgba(28, 31, 115, 0.8);
        }`
    }
`;