import {createContext, useContext, useCallback, useState} from 'react';
import { v4 as uuid } from 'uuid';
import ToastContainer from '../../modules/mgmaps/components/ToastContainer';

const ToastContext = createContext();

function ToastProvider({ children }){
  const [messages, setmessages] = useState([]);

  const addToast = useCallback(({type, title, description}) => {
    const id = uuid();
    const toast = {
      id,
      type,
      title,
      description
    }

    setmessages((oldMessages) => [...oldMessages, toast]);
  }, []);

  const removeToast = useCallback((id) => {
    setmessages((oldMessages) => oldMessages.filter(message => message.id !== id))
  }, [])

  
  return(
    <ToastContext.Provider value={{addToast, removeToast}} >
      {children}
      <ToastContainer message={messages}/>
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = useContext(ToastContext);

  if(!context) {
    throw new Error('useToast must be used within a ToastProvider ')
  }
  
  return context;
}

export {ToastProvider, useToast}