import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line, RiAddFill } from "react-icons/ri";
import Modal from 'react-modal';
import { MdClose} from "react-icons/md";

import NavBar from '../../components/NavBar/sideBar'
import Breadcrumb from '~/shared/components/Breadcrumb';

import ModalCity from '../../components/ModalRegistrationCity';
import ModalSplitter from '../../components/ModalRegistrationSplitter';
import ModalLocality from '../../components/ModalRegistrationLocality';
import { useToast } from '~/shared/hooks/toast';


import {Container,
        BreadrumDashboard,
        CardLocality,
        CardTitle,
        SmallCards,
        ModalContent,
      }
  
  from './style';



  export default  function Settings(){
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpenSplitter, setModalIsOpenSplitter] = useState(false);
    const [modalIsOpenLocality, setModalIsOpenLocality] = useState(false);
    const [typeActionCity, setTypeActionCity] = useState("");
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false);
    const [typeDelete, setTypeDelete] = useState("");
    const{addToast} = useToast()
    function handleCallToast(type,title,description,){
      addToast({type,title,description})
    }

    function handleOpenModalCity(type){
      setModalIsOpen(true);
      setTypeActionCity(type);
    }

    function handleCloseModalCity(){
      setModalIsOpen(false);
      setTypeActionCity("");
    }

    function handleOpenModalSplitter(type){
      setModalIsOpenSplitter(true);
      setTypeActionCity(type);
    }

    function handleCloseModalSplitter(){
      setModalIsOpenSplitter(false);
    }
    function handleOpenModalLocality(type){
      setModalIsOpenLocality(true); 
      setTypeActionCity(type);
    }
    function handleCloseModalLocality(){
      setModalIsOpenLocality(false);
      setTypeActionCity("")
    }

    function handleOpenModalDelete(action, id){
      setModalIsOpenDelete(!modalIsOpenDelete);
      setTypeDelete(action);
      {/* Esse id que vai receber é para fazer a consulta na ip e fazer o api.delete*/}

    }
  
  return(

    <>
    
    <NavBar />
      <BreadrumDashboard>
        <Breadcrumb
          paths={[
            { pageName: "MG Maps", link: "/mgmaps/district" },
            { pageName: "Configurações" }, 
          ]}
          style={{
            padding: 0
          }}  
        />
        
      </BreadrumDashboard>

    <Container>

     
    <ModalCity isOpen={modalIsOpen} onRequestClose={handleCloseModalCity} typeAction={typeActionCity} />
    <ModalSplitter isOpen={modalIsOpenSplitter} onRequestClose={handleCloseModalSplitter} typeAction={typeActionCity}/>
    <ModalLocality isOpen={modalIsOpenLocality} onRequestClose={handleCloseModalLocality} typeAction={typeActionCity} />
    <CardLocality>
      <Modal
        onRequestClose={handleOpenModalDelete}
        isOpen={modalIsOpenDelete}
        overlayClassName="react-modal-overlay"
        className="react-modal-content-delete"

      > 
        <ModalContent>
            <div className="buttonClose">
              <button onClick={handleOpenModalDelete}> <MdClose /> </button>
            </div>

            <div className="informationDelete">
              {typeDelete==='localidade'&&
               <p> Deseja Realmente excluir a localidade Planalto?</p>
              }
              {typeDelete==='splitter'&&
                <p>Deseja Realmente excluir o Splliter 1x8?</p>
              
              }
              {typeDelete==='city'&&
                 <p>Deseja Realmente excluir a cidade Caiçara do Norte?</p>

              }
              
            </div>

            <div className="buttons">
              <button>Cancelar</button>
              <button onClick={()=> handleCallToast('error','Houve um erro','Cidade com localidade cadastrada')}>Excluir</button>

            </div>
            
          </ModalContent>
            
      </Modal>
      <CardTitle>
          <h3>Localidades</h3>
          <button onClick={()=>handleOpenModalLocality('register')}><RiAddFill /></button>

        </CardTitle>
        <div className="tbl-header">
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Cidade</th>
                  <th>Ações</th>
                </tr>
              </thead>
            </table>
        </div>
      <div className="tbl-content">
      <table>
        
          <tbody>
            <tr>
              <td>  Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button onClick={()=>handleOpenModalLocality('update')}>   <FiEdit /> </button>
                <button onClick={()=>handleOpenModalDelete('localidade')}>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>

            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
           
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>

            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>

            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>

            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
            
            <tr>
              <td> Muriú</td>
              <td> Ceará-Mirim</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
                </td>
            </tr>
          </tbody>
          </table>
          </div>
        

      </CardLocality>
      <SmallCards>

      <CardLocality widthCard>

      <CardTitle>
          <h3> Splitters</h3>
          <button onClick={()=>handleOpenModalSplitter('register')}><RiAddFill /></button>

        </CardTitle>
        <div className="tbl-header">
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                </tr>
              </thead>
            </table>
        </div>
      <div className="tbl-content">
      <table>
        
          <tbody>

          <tr>
              <td>1x8</td>
              <td>
                <button onClick={()=>handleOpenModalSplitter('update')}><FiEdit /></button>
              </td>
                <td>
                  <button className="buttondelete"
                   onClick={()=>handleOpenModalDelete('splitter')}
                   > <RiDeleteBin5Line />
                   </button>
               </td>
            </tr>
            <tr>
              <td>1x16</td>
              <td>
                <button ><FiEdit /></button>
              </td>
                <td>
                  <button className="buttondelete">  <RiDeleteBin5Line /> </button>
               </td>
            </tr>
            
           
          </tbody>
          </table>
          </div>
        

      </CardLocality>

      <CardLocality widthCard>

      <CardTitle>
          <h3> Cidades</h3>
          <button  onClick={()=>handleOpenModalCity('register')}><RiAddFill /></button>

        </CardTitle>
        <div className="tbl-header">
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Localidade</th>
                  <th>Ações</th>
                </tr>
              </thead>
            </table>
        </div>
      <div className="tbl-content">
      <table>
        
          <tbody>

          <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button onClick={()=>handleOpenModalCity('update')}>   <FiEdit /> </button>
                <button onClick={()=>handleOpenModalDelete('city')}>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
            <tr>
              <td>Caiçara do Norte</td>
              <td> 1</td>
              <td>
                <button>   <FiEdit /> </button>
                <button>  <RiDeleteBin5Line /> </button>
              </td>
            </tr>
          </tbody>
          </table>
          </div>
        

      </CardLocality>
      </SmallCards>
     
    </Container>

    </>
  );

  

}