import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    margin: auto;
    width: 35vw; 
    height: 100%;
    min-width: 350px;
    max-width: 457px;
    display: flex;
    flex: 1;
    
    canvas { 
        width: 100% !important;
    }
`;