import React, { useEffect, useState, useCallback, useRef, } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import {
    FaUserAlt,
    FaPhoneAlt,
    FaClock,
    FaArrowLeft,
    FaWifi,
    FaSyncAlt,
    FaNetworkWired,
    FaServer,
    FaChartBar,
    FaRedoAlt,
    FaCopy
} from 'react-icons/fa';
import { FcAssistant } from "react-icons/fc";

import { useAuth } from '~/shared/hooks/auth';

import Skeleton from 'react-loading-skeleton';

import api from '~/shared/services/api';

import {
    getIntervalUpdateTime,
    getCurrentFormatedTime,
    getMillisecondsBetweenDates,
    formatMillisenconds,
    getMinutes
} from '~/shared/utils/FormatDataUtils';
import { formatCpfCnpj, capitalize } from '~/shared/utils/StringUtils';
import { getClientType, getTecnology } from '../../shared/utils/ConnectionUtils';

import Breadcrumb from '~/shared/components/Breadcrumb';
import Spinner from '~/shared/components/Spinner';
import DataClient from './components/DataClient';
import TrafficChart from '~/shared/components/TrafficChart';
import EquipmentImg from './components/EquipmentImg';

import { Node, AttendanceError } from './attendance/decisionTreeAttendance';
import decisionTreeAttendance from './attendance/attendanceScript';

import {
    Section,
    Container,
    Time,
    TimeInside,
    Name,
    Data,
    IconG,
    DataName,
    Content,
    ClientTop,
    Protocol,
    Chronometer,
    ChatMensegeSys,
    ChatMensegeClient,
    ChatMensegevc,
    ChatMessegeAtd,
    ChatMessegeGrup,
    ChatTop,
    ChatHeaderUser,
    ChatLoadingContainer,
    ChatMessageContainer,
    Body,
    ModalReport,
    ModalOverlay,
    ModalProtocolOverlay,
    ModalProtocol,
    ModalObs,
    ModalObsOverlay
} from './styles';

import {
    EquipmentContainer,
    DataItem,
    IP,
    CardSection,
    CardBody,
    StripeUp,
    Card,
    SectionOnu,
    EquipmentFiber,
    Grafic,
    DetailsTrafic,
    TitleDetailsTrafic
} from './equipmentStyles';
import smo from '~/shared/services/smo';


function ConfirmData(connectionId) {
    const sessionStoragePrefixKey = `seedot-support@${connectionId}/`;

    const [connectionData, setConnectionData] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}connection`) || '{}')
    );
    const [equipment, setEquipment] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}equipment`) || '{}')

    );
    const [equipmentOpticalInfo, setEquipmentOpticalInfo] = useState({});
    const [pppoe, setPPPoE] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}pppoeConnection`) || '{}')
    );
    const [recoveringPPPoEData, setRecoveringPPPoEData] = useState(false);
    const [recoveringEquipment, setRecoveringEquipment] = useState(false);
    const [recoveringConnectionData, setRecoveringConnectionData] = useState(false);
    const [disconnectingPPPoE, setDisconnectingPPPoE] = useState(false);

    const [pppoeTrafficHistory, setPPPoETrafficHistory] = useState([]);

    const [currentTime, setCurrentTime] = useState(getCurrentFormatedTime());
    const [startedAttendanceAt] = useState(new Date());
    const [attendanceDuration, setAttendanceDurantion] = useState('00:00:00');
    const [attendDurantionClass, setAttendDurantionClass] = useState("");

    // Attendance Script variables
    const [historyNodes, setHistoryNodes] = useState([]);
    const [currentNodeAttendance, setCurrentNodeAttendance] = useState({});
    const [canReplayNode, setCanReplayNode] = useState(false);
    const [isLoadingNode, setIsLoadingNode] = useState(false);
    const [loadingNodeMsg, setLoadingNodeMsg] = useState("");
    const [isFinishedAttendance, setIsFinishedAttendance] = useState(false);

    const [technicalDataVisible, setTechnicalDataVisible] = useState(false);

    const [modalVisible, setModalVisible] = useState(0);
    const [protocol, setProtocol] = useState("");
    const [report, setReport] = useState("");
    const [attendanceDescription, setAttendanceDescription] = useState("");
    const [isGetProtocolModalVisible, setIsGetProtocolModalVisible] = useState(false);
    const [isGetReportModalVisible, setIsGetReportModalVisible] = useState(false);
    const [isShowAttendanceDescriptionModalVisible, setIsShowAttendanceDescriptionModalVisible] = useState(false);

    const [isClientFinanceBlocked, setIsClientFinanceBlocked] = useState(false);

    const attendanceDescriptionRef = useRef(null);
    const chatRef = useRef(null);

    const history = useHistory();

    const { user } = useAuth();

    const handleNavigateToNextNodeAttendance = (responseSelected, nextNodeKey) => {
        currentNodeAttendance.setResponseSelected(responseSelected);

        if (typeof currentNodeAttendance.children[nextNodeKey] == "function") {
            nextToAttendanceNode(currentNodeAttendance.children[nextNodeKey]());
        } else {
            nextToAttendanceNode(currentNodeAttendance.children[nextNodeKey]);
        }
    }

    const handleReplayNodeAttendance = () => {
        setCanReplayNode(false);
        setHistoryNodes((historyNodes) => historyNodes.slice(0, -1));
        nextToAttendanceNode(currentNodeAttendance);
    }

    const handleBackNodeAttendance = () => {
        if (historyNodes.length === 0) {
            return;
        }

        if (historyNodes.length === 1) {
            nextToAttendanceNode(historyNodes[0]);
            return;
        }

        const nodeHistoryReversed = historyNodes.slice(0, -1).reverse();

        for (let i in nodeHistoryReversed) {
            if (nodeHistoryReversed[i].responseOptions) {
                setHistoryNodes((historyNodes) => historyNodes.slice(0, (-i - 2)))
                nodeHistoryReversed[i].setResponseSelected(null);
                nextToAttendanceNode(nodeHistoryReversed[i])
                break;
            }
        }
    }

    const handleDisconnectPPPoE = async () => {
        setDisconnectingPPPoE(true);

        try {
            await smo.post(
                'pppoe/disconnect/',
                { pppoe_user: connectionData.user },
                { timeout: 5000 }
            );
        } catch (error) {
            console.log(error);
            alert("Ops, não foi possível disconectar conexão PPPoE");
        }

        getPPPoEData(connectionData.user);

        setDisconnectingPPPoE(false);
    }

    const handleGetEquipment = () => {
        getEquipment(connectionData.tecnology)
    }

    const nextToAttendanceNode = useCallback(async (nextNode) => {
        await new Promise(resolve => setTimeout(resolve, 500));

        const node = nextNode;

        let nextNodeKey;

        setCurrentNodeAttendance(node);
        setIsLoadingNode(true);

        if (node.callback) {
            setLoadingNodeMsg(node.feedBack);

            try {
                nextNodeKey = await node.getCallback();
                node.setCallbackResultError(null);
            } catch (error) {
                console.log(error);
                if (error instanceof AttendanceError) {
                    node.setCallbackResultError(error.message);
                    alert(error.message);
                } else {
                    const commonMsgError = "Ops, algo não saiu como esperado. Por favor, tente novamente!";
                    // alert("Ops, algo não saiu como esperado. Por favor, tente novamente!");
                    node.setCallbackResultError(commonMsgError);
                }
                setCanReplayNode(true);
            } finally {
                setLoadingNodeMsg("");
            }
        }

        setIsLoadingNode(false);
        setHistoryNodes((historyNodes) => [...historyNodes, node]);

        if (node.responseOptions) {
            return;
        }

        if (nextNodeKey) {
            if (typeof node.children[nextNodeKey] == "function") {
                nextToAttendanceNode(node.children[nextNodeKey]());
            } else {
                nextToAttendanceNode(node.children[nextNodeKey]);
            }
        } else if (typeof node.children == "function") {
            nextToAttendanceNode(node.children());
            // TODO: Mostrar que o atendimento foi finalizado
        }
    }, []);

    const finishAttendance = useCallback(() => {
        setIsFinishedAttendance(true);
        setIsGetReportModalVisible(true);

        // history.push('/support/connections');
    }, []);

    const handleGenerateAttendanceDescription = useCallback(() => {
        if (!report.trim().length) {
            alert('Por favor informe um relato pessoal sobre o atendimento');
            return;
        }

        let description = `Protocolo: ${protocol}\nAtendente: ${capitalize(user.first_name || '')} ${capitalize(user.last_name || '')}\n\n\tDescrição do atendimento\n\n`;

        description += historyNodes.map(node => {
            if (node.callbackResultError) {
                return `Sistema: ${node.callbackResultError}`;
            } else if (node.getMsg()) {
                let msgs = "";

                node.getMsg().forEach(msg => {
                    const msgText = typeof msg.text == 'object' ? msg.toString() : msg.text;

                    if (msg.to === 'client') {
                        msgs += `Informado ao cliente: ${msgText}\n`;
                    } else if (msg.to === 'attendant') {
                        msgs += `Sistema: ${msgText}\n`
                    } else {
                        msgs += '';
                    }
                });

                if (node.responseSelected) {
                    msgs += `Atendente: ${node.responseSelected}\n`;
                }

                return msgs;
            } else {
                return '';
            }
        }).join('');

        description += `\n\n\nRelato pessoal do atendente:\n${report}`;

        setAttendanceDescription(description);

        setIsGetReportModalVisible(false);
        setIsShowAttendanceDescriptionModalVisible(true);
    }, [report, historyNodes, protocol, user]);


    const handleSaveAttendance = useCallback(async () => {
        try {
            await api.post('support/attendances', {
                started_at: startedAttendanceAt,
                finished_at: new Date(),
                connection_id: connectionId,
                protocol,
                report,
                description: attendanceDescription
            });

            history.push('/support/connections');
        } catch (error) {
            if (error.response && error.response.data?.message) {
                alert(error.response.data.message);
            } else {
                alert("Ops, não foi possível salvar atendimento. Por favor tente novamente!");
            }
        }
    }, [connectionId, protocol, report, attendanceDescription, startedAttendanceAt, history]);

    const handleCopyAttendanceDescription = useCallback((e) => {
        attendanceDescriptionRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }, []);

    const getPPPoEData = useCallback(async () => {
        if (recoveringPPPoEData) {
            return;
        }

        setRecoveringPPPoEData(true);

        try {
            const { data } = await api.get(`smo/api/pppoe/status/`, {
                params: { pppoe_user: connectionData.user },
                timeout: 10000
            });

            setPPPoE(data);
            setRecoveringPPPoEData(false);

            return data;
        } catch (error) {
            alert("Ops, Não foi possível recuperar dados da conexão PPPoE");
            setRecoveringPPPoEData(false);
        }
    }, [connectionData, recoveringPPPoEData]);


    const getPPPoETraffic = useCallback(async () => {
        if (!connectionData.user) {
            console.log(connectionData)
            console.log('without connection user');
            return;
        }

        try {
            const { data } = await api.get('smo/api/pppoe/traffic/', {
                params: { pppoe_user: connectionData.user },
                timeout: 10000
            });

            setPPPoETrafficHistory(pppoeTrafficHistory => [...pppoeTrafficHistory, data]);
            console.log(data);
            return data;
        } catch (error) {
            console.log('error')
            return null;
        }
    }, [connectionData]);


    const getEquipment = useCallback(async () => {
        // Recuperando dados do equipamento utilizado na conexão
        if (recoveringEquipment) {
            return;
        }

        setRecoveringEquipment(true);

        if (connectionData.tecnology === 'FIBRA') {
            try {
                var equipmentResponse = await api.get('smo/api/onus/', { params: { connection_id: connectionId } });
            } catch (error) {
                if (error.response) {
                    alert(error.response.msg);
                } else {
                    alert("Não foi possível recuperar equipamento da conexão");
                }
                setRecoveringEquipment(false);
                return;
            }

            if (!equipmentResponse.data.length) {
                alert("Não foi possível recuperar equipamento da conexão");
                setRecoveringEquipment(false);
                return;
            }

            console.log(equipmentResponse.data[0]);

            let equipmentData = {
                tecnology: connectionData.tecnology,
                ...equipmentResponse.data[0]
            };

            setEquipment(equipmentData);

            // Recuperando dados ópticos da ONU
            const equipmentId = equipmentResponse.data[0].id;

            try {
                var equipOpticInfoResp = await api.get(`smo/api/onus/${equipmentId}/optical_info/`, { timeout: 10000 });
            } catch (error) {
                if (error.response) {
                    alert(error.response.msg);
                } else {
                    alert("Não foi possível recuperar dados opticos do equipamento da conexão");
                }
                setRecoveringEquipment(false);
                return;
            }

            console.log(equipOpticInfoResp.data);

            equipmentData = {
                ...equipmentData,
                ...equipOpticInfoResp.data
            };

            setEquipment(equipmentData);
            setEquipmentOpticalInfo(equipOpticInfoResp.data);
        }

        setRecoveringEquipment(false);
    }, [connectionData, connectionId, recoveringEquipment]);


    const getConnectionData = useCallback(async () => {
        // Recuperando dados da conexão
        setRecoveringConnectionData(true);

        try {
            var connectionResponse = await api.get(`my_synsuite/api/v2/connections/${connectionId}`)
        } catch (error) {
            if (error.response) {
                alert(error.response.msg);
            } else {
                alert("Não foi possível recuperar dados da conexão");
            }

            setRecoveringConnectionData(false);
            return;
        }

        const tecnology = getTecnology(connectionResponse.data.pe);
        const clientType = getClientType(connectionResponse.data.plan_description);

        setConnectionData({
            tecnology,
            clientType,
            ...connectionResponse.data,
        });

        setRecoveringConnectionData(false);

        if (connectionResponse.data.contract_status.toLowerCase() === 'bloqueio financeiro') {
            setIsClientFinanceBlocked(true);
            alert("Cliente está com bloqueio financeiro");
        } else {
            setIsGetProtocolModalVisible(true);
        }
    }, [connectionId]);


    const getAllConecInfo = useCallback(async () => {
        await Promise.all([getEquipment(), getPPPoEData()]);

        decisionTreeAttendance.setAttendanceConnectionId(connectionId);
        decisionTreeAttendance.setFinishAttendanceCallback(finishAttendance);

        nextToAttendanceNode(decisionTreeAttendance.getRootNode());
    }, [getPPPoEData, connectionId, getEquipment, nextToAttendanceNode, finishAttendance]);


    const handleInitAttendance = useCallback(() => {
        if (isClientFinanceBlocked) {
            history.push('/support/connections');
        } else {
            getAllConecInfo();
        }
    }, [history, getAllConecInfo, isClientFinanceBlocked]);


    const handleInitConfirmClientData = useCallback(() => {
        if (!protocol.trim().length) {
            alert("Por favor informe o protocolo do atendimento");
            return;
        }

        setIsGetProtocolModalVisible(false);
    }, [protocol])


    useEffect(() => {
        getConnectionData();

        // Criando intervalos para manter a hora e o tempo de atendimento atualizados
        const intervalUpdateTime = getIntervalUpdateTime(setCurrentTime);

        const intervalUpdateAttendanceDuration = setInterval(() => {
            let currentAttendDuration = getMillisecondsBetweenDates(startedAttendanceAt);
            let currentAttendMinutes = getMinutes(currentAttendDuration);

            if (currentAttendMinutes >= 5) {
                setAttendDurantionClass("danger");
            }
            else if (currentAttendMinutes >= 3) {
                setAttendDurantionClass("warning");
            }

            setAttendanceDurantion(formatMillisenconds(currentAttendDuration));
        }, 1000);


        return () => {
            clearInterval(intervalUpdateTime);
            clearInterval(intervalUpdateAttendanceDuration);
        }

    }, [startedAttendanceAt, getConnectionData]);


    useEffect(() => {
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }
    }, []);

    // Criando intervalo para monitor tráfego do cliente
    useEffect(() => {
        const getPPPoETrafficInterval = setInterval(getPPPoETraffic, 20000);

        return () => {
            clearInterval(getPPPoETrafficInterval);
        }
    }, [getPPPoETraffic])


    // Iniciando script atendimento
    // useEffect(() => {
    //     decisionTreeAttendance.setAttendanceConnectionId(connectionId);
    //     nextToAttendanceNode(decisionTreeAttendance.getRootNode());
    // }, [nextToAttendanceNode, connectionId]);


    // Evento para manter a div do chat sempre rolada até o final
    useEffect(() => {
        if (chatRef) {
            chatRef.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem(`${sessionStoragePrefixKey}pppoeConnection`, JSON.stringify(pppoe));
    }, [pppoe, sessionStoragePrefixKey]);

    useEffect(() => {
        sessionStorage.setItem(`${sessionStoragePrefixKey}connection`, JSON.stringify(connectionData));
        decisionTreeAttendance.setAttendanceConnectionData(connectionData);
    }, [connectionData, sessionStoragePrefixKey]);

    useEffect(() => {
        sessionStorage.setItem(`${sessionStoragePrefixKey}equipment`, JSON.stringify(equipment));
        decisionTreeAttendance.setAttendanceEquipment(equipment);
    }, [equipment, sessionStoragePrefixKey])


    return (
        <>
            <Body>
                <Breadcrumb title={"Conexões"} paths={[
                    { pageName: "Suporte" },
                    { pageName: "Conexões" },
                    { pageName: `${connectionData.client_name || ""}` }
                ]} />

                <Container>

                    <Section>

                        <DataName>
                            <IconG>
                                <FaUserAlt />
                            </IconG>

                            <ClientTop>
                                {recoveringConnectionData ? (
                                    <>
                                        <Name><Skeleton height={28} width={400} /></Name>
                                        <Data>
                                            <Skeleton width={150} height={16} />
                                            <Skeleton width={150} height={16} />
                                        </Data>
                                    </>
                                ) : (
                                        <>
                                            <Name>{(connectionData.client_name || "").toUpperCase()}</Name>
                                            <Data>
                                                <span><strong>CPF/CNPJ:</strong> {formatCpfCnpj(connectionData.client_cpf || "")}</span>
                                                <span><strong>E-mail:</strong> {connectionData.client_email || ""}</span>
                                            </Data>
                                        </>
                                    )}
                            </ClientTop>
                        </DataName>

                        {!technicalDataVisible ? (
                            <DataClient
                                connectionData={connectionData}
                                recoveringConnectionData={recoveringConnectionData}
                                isClientFinanceBlocked={isClientFinanceBlocked}
                                setConfirmedClient={() => { setTechnicalDataVisible(true); handleInitAttendance() }}
                            />
                        ) : (
                                <EquipmentContainer>
                                    <Card>
                                        <CardSection>

                                            <StripeUp>
                                                <FaWifi />
                                                <span>Sessão</span>
                                                <i onClick={() => getPPPoEData()}><FaSyncAlt /></i>
                                            </StripeUp>
                                            <CardBody>

                                                {recoveringPPPoEData ? (
                                                    <>
                                                        <DataItem>
                                                            <li><Skeleton height={17} /></li>
                                                            <li><Skeleton height={17} /></li>
                                                            <li><Skeleton height={17} /></li>
                                                            <li><Skeleton height={17} /></li>
                                                            <li><Skeleton height={17} /></li>
                                                        </DataItem>

                                                        <Skeleton style={{ margin: "20px auto", display: "block" }} width={183} height={35} />
                                                    </>
                                                ) : (
                                                        <>
                                                            <DataItem>
                                                                <li><strong>Status:</strong> <span className={`pppoe-status ${pppoe.status || ''}`}>{(pppoe.status || '').toUpperCase() || <Skeleton />}</span></li>
                                                                <li><strong>IP:</strong> <IP>{pppoe.ip || "-"}</IP></li>
                                                                {pppoe.status === 'online' ? (
                                                                    <>
                                                                        <li><strong>Conectado à:</strong> {pppoe.online_time || ""}</li>
                                                                        <li><strong>Conectou em:</strong> {pppoe.start_time || ""}</li>
                                                                    </>
                                                                ) : (
                                                                        <>
                                                                            <li><strong>Desconectado à:</strong> {pppoe.offline_time || ""}</li>
                                                                            <li><strong>Desconectou em:</strong> {pppoe.start_offline_time || ""}</li>
                                                                        </>
                                                                    )}
                                                                <li><strong>MAC Address:</strong> {pppoe.mac || ""}  </li>
                                                            </DataItem>
                                                            <button
                                                                disabled={disconnectingPPPoE || recoveringPPPoEData}
                                                                onClick={handleDisconnectPPPoE}>
                                                                Desconectar
                                                            </button>
                                                        </>
                                                    )}

                                            </CardBody>
                                        </CardSection>

                                        <CardSection>
                                            <StripeUp>
                                                <FaNetworkWired />
                                                <span>Conexão</span>
                                                <i><FaSyncAlt /></i>
                                            </StripeUp>

                                            <CardBody>
                                                <DataItem>
                                                    <li><strong>Usuário:</strong> {connectionData.user || ""}</li>
                                                    <li><strong>Senha:</strong> {connectionData.password || ""}</li>
                                                    <li><strong>Plano:</strong> {connectionData.plan_description}  </li>
                                                    <li><strong>Ponto de Acesso:</strong> {connectionData.pe}</li>
                                                    <li><strong>Status Ponto de Acesso:</strong> OK</li><strong></strong>
                                                </DataItem>
                                            </CardBody>
                                        </CardSection>
                                    </Card>

                                    <CardSection>
                                        <StripeUp>
                                            <FaServer />
                                            <span>Equipamentos</span>
                                            <i onClick={handleGetEquipment}><FaSyncAlt /></i>
                                        </StripeUp>

                                        <CardBody>
                                            <SectionOnu>

                                                <EquipmentFiber>
                                                    <h3>ONU</h3>

                                                    {recoveringEquipment ?
                                                        (
                                                            <Skeleton height={80} />
                                                        ) : (
                                                            <div>
                                                                <EquipmentImg
                                                                    connected={(equipmentOpticalInfo.status || "") === "online"}
                                                                    signal={equipmentOpticalInfo.rx_power || ""}
                                                                />
                                                            </div>
                                                        )}

                                                    {false && (
                                                        <div>
                                                            <button>Reiniciar</button>
                                                        </div>
                                                    )}
                                                </EquipmentFiber>

                                                <div className="w-100">
                                                    <DataItem>
                                                        {recoveringEquipment ? (
                                                            <>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                                <li><Skeleton height={12} /></li>
                                                            </>
                                                        ) : (
                                                                <>
                                                                    <li><strong>Slot:</strong>  {equipment.card ? equipment.card.slot : ""}</li>
                                                                    <li><strong>Pon:</strong> {equipment.pon ? equipment.pon.number : ""}</li>
                                                                    <li><strong>Index:</strong> {equipment.index || ""}</li>
                                                                    <li><strong>Modelo:</strong> {equipment.model || ""}</li>
                                                                    <li><strong>Tempo Ligado:</strong> </li>
                                                                    <li><strong>Modo:</strong> {
                                                                        (equipment.connection_type || "") === "2" ? "Router" :
                                                                            (equipment.connection_type || "") === "1" ? "Bridge" : ""
                                                                    }
                                                                    </li>
                                                                    <li><strong>Serial:</strong> {equipment.serial || ""}</li>
                                                                </>
                                                            )}
                                                    </DataItem>
                                                </div>

                                            </SectionOnu>
                                        </CardBody>
                                    </CardSection>

                                    <CardSection>
                                        <StripeUp>
                                            <FaChartBar />
                                            <span>Tráfego da conexão</span>
                                        </StripeUp>

                                        <CardBody>
                                            <Grafic>
                                                <div className="w-100">
                                                    <TrafficChart traffic={pppoeTrafficHistory} />
                                                </div>

                                                <div className="w-100">
                                                    <DetailsTrafic>
                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÍNIMO</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {
                                                                    (Math
                                                                        .min(
                                                                            ...(pppoeTrafficHistory.length ?
                                                                                pppoeTrafficHistory.map(traffic => Number(traffic.download))
                                                                                : [0])
                                                                        ) / 1024
                                                                    )
                                                                        .toFixed(2)
                                                                } Mb/min
                                                </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {
                                                                    (Math
                                                                        .min(
                                                                            ...(pppoeTrafficHistory.length ?
                                                                                pppoeTrafficHistory.map(traffic => Number(traffic.upload))
                                                                                : [0])
                                                                        ) / 1024
                                                                    )
                                                                        .toFixed(2)
                                                                } Mb/min
                                                </span>
                                                        </div>
                                                    </DetailsTrafic>

                                                    <DetailsTrafic>
                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÉDIA</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.download;
                                                                    }

                                                                    return Number(a) + Number(b.download);
                                                                }, 0) / ((pppoeTrafficHistory.length || 1) * 1024)).toFixed(2)} Mb/min
                                                </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.upload;
                                                                    }

                                                                    return Number(a) + Number(b.upload);
                                                                }, 0) / ((pppoeTrafficHistory.length || 1) * 1024)).toFixed(2)} Mb/min
                                                </span>
                                                        </div>
                                                    </DetailsTrafic>

                                                    <DetailsTrafic>

                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÁXIMO</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.download;
                                                                    }

                                                                    return Math.max(Number(a), Number(b.download));
                                                                }, 0) / 1024).toFixed(2)} Mb/min
                                                    </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.upload;
                                                                    }

                                                                    return Math.max(Number(a), Number(b.upload));
                                                                }, 0) / 1024).toFixed(2)} Mb/min
                                                </span>
                                                        </div>

                                                    </DetailsTrafic>
                                                </div>
                                            </Grafic>
                                        </CardBody>

                                    </CardSection>
                                </EquipmentContainer>
                            )}

                    </Section>

                    <Time state={attendDurantionClass}>
                        <Protocol>
                            <div><FaPhoneAlt /></div>
                            <span>Protocolo <strong>{protocol}</strong></span>
                        </Protocol>
                        <TimeInside>Hora Atual
                        <p>{currentTime}</p>
                        </TimeInside>
                        <Chronometer>
                            <div>
                                <p>Em atendimento</p>
                                <span>{attendanceDuration}</span>
                            </div>
                            <i><FaClock /></i>
                        </Chronometer>
                    </Time>

                    <Content>
                        <ChatTop>
                            <div className="avatar">
                                <FcAssistant />
                            </div>
                            <ChatHeaderUser>
                                <span>Suporte</span>
                                <p>{isLoadingNode ? 'digitando...' : 'online'}</p>
                            </ChatHeaderUser>
                        </ChatTop>

                        <ChatMessageContainer>

                            <ChatMessegeGrup ref={chatRef}>
                                {historyNodes.map((node, indexNode) => {
                                    let msgs = [];

                                    if (node.callbackResultError) {
                                        return (
                                            <ChatMensegeSys key={`${indexNode}`}>
                                                <div className="message">
                                                    <span>Sistema</span>
                                                    <div>{node.callbackResultError}</div>
                                                </div>
                                            </ChatMensegeSys>
                                        )
                                    } else if (node.getMsg()) {
                                        msgs = node.getMsg().map((msg, indexMsg) => {
                                            if (msg.to === 'client') {
                                                return (
                                                    <ChatMensegeClient key={`${indexNode}.${indexMsg}`}>
                                                        <div className="messageclient">
                                                            <span>Informe ao Cliente</span>
                                                            <div>{msg.text}</div>
                                                        </div>
                                                    </ChatMensegeClient>
                                                )
                                            } else if (msg.to === 'attendant') {
                                                return (
                                                    <ChatMensegeSys key={`${indexNode}.${indexMsg}`}>
                                                        <div className="message">
                                                            <span>Sistema</span>
                                                            <div>{msg.text}</div>
                                                        </div>
                                                    </ChatMensegeSys>
                                                )
                                            } else {
                                                return <></>;
                                            }
                                        })

                                        if (node.responseSelected) {
                                            msgs.push(
                                                <ChatMensegevc key={indexNode}>
                                                    <div className="messagevc-yes">
                                                        <span>Você</span>
                                                        <div>{node.responseSelected}</div>
                                                    </div>
                                                </ChatMensegevc>
                                            )
                                        }
                                    }

                                    return msgs;
                                })}

                                {isLoadingNode && (
                                    <ChatMensegeSys>
                                        <div className="message">
                                            <span>Sistema</span>
                                            <div>digitando...</div>
                                        </div>
                                    </ChatMensegeSys>
                                )}

                            </ChatMessegeGrup>
                            <ChatMessegeAtd>
                                {canReplayNode && (
                                    <button
                                        className="iconback"
                                        disabled={isLoadingNode}
                                        onClick={handleReplayNodeAttendance}>
                                        <FaRedoAlt />
                                    </button>
                                )}

                                {!canReplayNode && (
                                    <button
                                        className="iconback"
                                        disabled={isLoadingNode}
                                        onClick={handleBackNodeAttendance}>
                                        <FaArrowLeft />
                                    </button>
                                )}

                                <div className="yes-no">
                                    {
                                        !currentNodeAttendance.callbackResultError &&
                                        currentNodeAttendance.responseOptions?.map((response, index) => (
                                            <button
                                                disabled={isLoadingNode}
                                                key={index}
                                                className="btn-yes"
                                                onClick={() => handleNavigateToNextNodeAttendance(response.text, response.nextNodeKey)}>
                                                {response.text}
                                            </button>
                                        ))
                                    }
                                </div>
                            </ChatMessegeAtd>

                            {isLoadingNode && (
                                <ChatLoadingContainer>
                                    <div className="content">
                                        <Spinner />
                                        <label>{loadingNodeMsg || "Verificando acesso remoto do roteador..."}</label>
                                    </div>
                                </ChatLoadingContainer>
                            )}

                        </ChatMessageContainer>
                    </Content>
                </Container>

            </Body>

            {/* Modal */}

            <ModalProtocolOverlay visible={isGetProtocolModalVisible}>
                <ModalProtocol visible={isGetProtocolModalVisible}>
                    <h4>Inicie o atendimento no Synsuite e Digite o protocolo o atendimento aqui.</h4>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <label htmlFor="">Protocolo: </label>
                        <input type="number" value={protocol} onChange={event => setProtocol(event.target.value)} />
                    </form>
                    <button onClick={handleInitConfirmClientData}>Iniciar Atendimento</button>
                </ModalProtocol>
            </ModalProtocolOverlay>

            <ModalObsOverlay visible={isGetReportModalVisible}>
                <ModalObs>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <h4>Você tem alguma observação pra fazer?</h4>
                        <label htmlFor="">Observação do Atendente: </label>
                        <textarea value={report} onChange={event => setReport(event.target.value)}>
                        </textarea>
                    </form>
                    <button onClick={handleGenerateAttendanceDescription}>Gerar Relato Final</button>
                </ModalObs>
            </ModalObsOverlay>

            <ModalOverlay visible={isShowAttendanceDescriptionModalVisible}>
                <ModalReport>
                    <span>Por favor, vá ao SYNSUITE e abra a ordem de serviço com o seguinte Relato.</span>
                    <div>
                        <textarea
                            readOnly={true}
                            ref={attendanceDescriptionRef}
                            value={attendanceDescription}>
                        </textarea>
                        <span onClick={handleCopyAttendanceDescription}><FaCopy title="Copiar texto" /></span>
                    </div>
                    <button onClick={handleSaveAttendance}>Finalizar Atendimento</button>
                </ModalReport>
            </ModalOverlay>
        </>
    );
}

function ErrorPage() {
    const history = useHistory();

    useEffect(() => {
        alert("Nenhuma conexão foi selecionada");
        history.push('/support/connections');
    }, [history])

    return (<></>);
}

function Validator() {
    const location = useLocation();

    if (!(location.state && location.state.connectionId)) {
        return ErrorPage();
    }

    return ConfirmData(location.state.connectionId);
}

export default Validator;