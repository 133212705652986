import styled,{css} from 'styled-components';
import {Form as Unform } from '@unform/web'

export const SectionOne = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    max-width: 1250px;
    width: 95%;
  }
`;

export const SectionUser = styled.section`
    padding: 20px;
    width: 100%;
    .contentSection {
      display: flex;
    }
`;

export const Form =  styled(Unform)`

    display: flex;
    flex-direction: column;
    font-size: .875rem;

    label{
      display: flex;
      align-items: center;
      color: #495057;
      margin:30px 10px 0 10px;
    }

    input, select{
      width: 100%;
      min-width: 100px;
      height: 25px;
      border: none;
      margin-top: 30px;
      background: #F8F9FA;
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding-left: 6px;
      span{
        color: pink;
      }
    }

    select{
      margin-left: -20px;
    }

    option:nth-child(1){
      display: none;
    }

    input[type="checkbox"]{
      padding: 20px;
    }

   .dataUserTwo{
     display: flex;
   }

   .button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      button {
        width: 128px;
        height: 35.64px;
        background: #33CC95;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
      }
      button:hover {
        background: #35A47C;
      }
   }
   a{
     text-decoration: none;
     color: #FFFFFF;
   }

`;

export const TitleSection = styled.div`
    border-radius: 4px;
    padding: 4px;
    color: #495057;
    margin-top: 20px;
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 1rem;
`;

export const ContentSection = styled.div`
  display: flex;
  align-self: center;
  width: 80%;
  .todos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .dataUser{
    flex: 1;
    display: flex;
  }
`;

export const ContentSectionTwo = styled(ContentSection)`
  flex-direction: column;
  input {
    border: 5px;
  }
`;

export const ContentSectionThree = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  width: 80%;
  margin-top: 30px;
  label {
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
  input {
    display: flex;
    margin: 0 20px 0 0;
    justify-content: flex-end;
    width: 15px;
    height: 20px;
    box-shadow: none;
    min-width: 0;
  }
  p {
    margin-bottom: 20px;
  }
  .cards {
    margin-top: -40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .card {
    width: 100%;
    margin: 0 0 10px 0;
    max-width: 500px;
    min-width: 200px;
    box-sizing: border-box;
    border-radius: 11.3243px;
  }
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .user{
    margin-top: 32px;
  }
  .email{
    margin-bottom: 5px;
  }
`;

export const DivDataUser = styled.div`
  flex: 1;
`;

export const Inputs = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

export const InputLegend = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 8px;
  span{
    color: #ACACAC;
    font-size: .7rem;
    margin-left: 2px;
  }
`;

export const SectionTwo = styled.section`
  display:flex;
  justify-content: center;
`;

export const Container = styled.div`
  margin: 20px;
  width: 95%;
  height: 60vh%;
  background-color: #FFFFFF;
  max-width: 1250px;

  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1250px;
`;

export const ContCard = styled.div`
  margin: 20px 10px;
    h3 {
      margin: 5px 10px;
      font-size: 20px;
    }
`;

export const Card = styled.div`
  flex: 1;
  background: #F8F9FA;
  border: 2px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 10px;
  height: 450px;
  position: relative; 
`;

export const CardBody = styled.div`
  height: 400px;
  margin: 5px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
    border-radius: 30px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 30px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 30px;
}
`;

export const CardTop = styled.div`
  background: #EFF0F1;
  height: 40px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4B4B4B;
  font-weight: bold;
`;

export const CollapsibleDiv = styled.div`
  .Collapsible__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #EFF0F1;
    color: #717579;
    border-radius: 8px;
    margin: 8px 2px 5px 2px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .Collapsible__trigger::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #717579;
    transition: .5s;
  }

  .Collapsible__trigger.is-closed::after {
    content: '';
    transform: rotate(0deg)
  }

  .Collapsible__trigger.is-open::after {
    content: '';
    transform: rotate(180deg)
  }
`;

export const ContColapsible = styled.div`
  li {
    display: flex;
    margin: 10px;
  }
`;

export const CardContent = styled.div`
  div{
    margin: 8px 0 0 8px;
    display: flex;
  }
  ul {
    list-style: none;
    margin: 10px;
  }
  li {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    button{
      border: none;
      background: transparent;
      color: #495057;
      cursor: pointer;

      svg{
        font-size: 1.4rem;
      }
    }
  }
`;

// export const Pagination = styled.div`
//   position: absolute;
//   bottom: -65px;
//   right: 0;

//   button {
//     border: none;
//     font-size: 0.875rem;
//     padding: 4px;
//     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
//     cursor: pointer;
//     margin: 7px;
//     padding: 15px 15px;
//     color: #fff;

//     ${props => props.selected 
//       ? css`background-color: rgba(28, 31, 115, 0.9);` 
//       : css`background: #50BCBA;`
//     }

//     ${props => props.disabled 
//       ? css`background-color: #acacac;`
//       : css`
//         &:hover {
//           background-color: rgba(28, 31, 115, 0.8);
//         }`
//     }
//   }
// `;