import styled, { css } from 'styled-components';

import BgVectors from '~/shared/assets/bg_vectors.png';

export const Body = styled.div`
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
`

export const Container = styled.section`
    margin: 10px 10px 0 10px;
    display: grid;
    
    grid-template-columns: 12fr 6fr;
    grid-template-rows: 131px;
    grid-gap: 8px;
`

export const Section = styled.div`
    background: #F6F6F6;
    padding: 5px;
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start: 1;
    grid-row-end: 3;
    
    display:flex;
    flex-direction: column;
    justify-content: top;

    overflow-y: auto;
    max-height: calc(100vh - 105px);

    ::-webkit-scrollbar-track {
    background-color: #dad7d7;
    border-radius: 30px;
    }
    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 30px;
      background: #dad7d7;
    }
    ::-webkit-scrollbar-thumb {
      background: #808080;
      border-radius: 30px;
    }
`

export const DataName = styled.div`
    display: flex;
    justify-content: center;

    padding: 10px;
    margin: 10px;
    width: 85%;
    border-bottom: 1px solid #D0CECE;
    margin: 0 auto;
`

export const IconG = styled.div`
    font-size: 3rem;
    color: #50BCBA;
    margin-right: 65px;
    margin-left: -113px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
`

export const ClientTop = styled.div`
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Name = styled.h1`
    color: #1C1F73;
    font-size: 1.5rem;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
`

export const Data = styled.div`
    display: flex;

    span {
        font-size: 0.8rem;
        margin: 5px 20px;
    }
`

export const Title = styled.h3`
    color: #495057;
    font-size: 1.3rem;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
`

export const FontColor = styled.strong`
    ${(props) => props.active
        ? css`color: #45B529;`
        : css`color: red;`
    }
    
`

export const Time = styled.div`
    /* Cor que se adapta ao parametro state passado */
    background: ${props =>
        props.state === "warning" ? "#EABA0F" :
            props.state === "danger" ? "#D10101" : "#45B529"
    };

    padding: 7px;
    min-width: 300px;
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    text-transform: uppercase;
    color: white;
    font-size: 1.1rem;
    
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    grid-template-rows: 2fr 1fr 1fr;

    align-items: center;
    justify-content: center;
`

export const Protocol = styled.div`
    grid-row-start: 1;
    grid-row-end: 4;
    
    display:flex;
    flex-direction: column;
    

    div {
        font-size: 3rem;
        text-align: center;
        flex: 1;
    }

    span {
        font-size: 1.2rem;
        text-align: center;
    }
`

export const TimeInside = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;

    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;

    p {
        font-size: 1.6rem;
    }

`
export const Chronometer = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
    
    font-size: 0.8rem;
    text-align: center;
    display: flex;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    p {
        font-size: 0.7rem;
    }

    span {
        font-size: 1.7rem;
        align-items: center;
    }

    i {
        font-size: 2rem;
        margin-left: 1em;
    }
`

export const Content = styled.div`
    background: #296F6E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 10px 1px rgba(0, 0, 0, 0.5); 
    border-radius: 7px;
    height:calc(100vh - 243px);
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(${BgVectors});
    background-attachment: fixed;
`

export const ChatMensegeSys = styled.div`
    display: flex;
    padding: 0px 10px 12px 10px;
    align-items: flex-start;
    font-family: Robotobold, sans-serif;
    
    div.message {
        background: #f3f3ad;
        border-radius: 10px;
        border-top-left-radius: 0;
        padding: 5px 10px;
        max-width: 80%;
        min-width: 10%;
        font-size: .8rem;
    }

    span {
        font-size: 0.6rem;
        color: #ED0B0B;
        margin-bottom: 2px;
        text-align: top;
    }
`

export const ChatMensegevc = styled.div`
    display: flex;
    padding: 0px 10px 12px 10px;
    align-items: flex-start;
    justify-content: flex-end;
    font-family: Robotobold, sans-serif;
    
    div.messagevc-yes {
        background: #63FF81;
        border-radius: 10px;
        border-top-right-radius: 0;
        padding: 5px 10px;
        max-width: 80%;
        min-width: 20%;
        font-size: .8rem;
    }
    div.messagevc-no {
        background: #63FF81;
        border-radius: 10px;
        border-top-right-radius: 0;
        padding: 5px 10px;
        max-width: 80%;
        min-width: 20%;
        font-size: .8rem;
    }

    span {
        font-size: 0.6rem;
        color: #1C1F73;
        margin-bottom: 2px;
        text-align: top;
    }
`
export const ChatMensegeClient = styled.div`
    display: flex;
    padding: 0px 10px 12px 10px;
    align-items: flex-start;
    justify-content: center;
    font-family: Robotobold, sans-serif;
    
    div.messageclient {
        background: #a6dddc;
        border-radius: 10px;
        padding: 5px 10px;
        max-width: 80%;
        min-width: 20%;
        font-size: .8rem;
    }

    span {
        font-size: 0.6rem;
        color: #1C1F73;
        margin-bottom: 2px;
        text-align: top;
    }
`

export const Question = styled.label`
    font-size: 1.1rem;
    margin: 15px 0;
`
export const Answer = styled.div`
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    margin: 0 50px;
    
    input {
        margin-right: 3px;
        margin-bottom: 15px;
    }

    label {
        margin-right: 20px;
        margin-bottom: 15px;
    }
`

export const InputText = styled.input`
    border: none;
    font-size: 1.2rem;
    color: #ccc;
    padding: 5px;
    background: #F8F9FA;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
`
export const NextButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 133px;
    height: 35px;
    background: #50BCBA;
    box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
    border-radius: 1.16667px;
    border: none;
    font-family: Roboto, sans-serif;

    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);

    position: absolute;
    bottom: 10px;
    right: 10px;

    button:hover {
        background-color: #50BCBA;  
    }

    svg {
        font-size: 19px;
        margin-left: 10px;
    }
`
export const ChatMessegeGrup = styled.div`
    flex: 1;
    padding-top: 15px;
    position: relative;

    overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
    border-radius: 30px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 30px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 30px;
}
`

export const ChatTop = styled.div`
    display: flex;
    
    background: #4B4B4B;
    color: white;
    border-radius: 8px 8px 0 0;

    div.avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c9c9c9c9;
        font-size: 1.2rem;
        border: 1px solid white;
        border-radius: 50%;
        margin: 5px 0px 5px 10px;
        padding: 5px;
    }

    svg {
        font-size: 30px;
    }
`

export const ChatHeaderUser = styled.div`
    position: relative;
    margin: 7px 0px 7px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        font-weight: 600;
        padding-bottom: 1px;
    }

    p {
        font-size: 0.65rem;
        color: #c4c4c4;
    }
`;

export const ChatMessegeAtd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    /* width: 100%; */
    margin: 0px 5px 5px 5px;

    .iconback {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        
        background: #4B4B4B;
        padding: 5px 10px;
        color: white;
        height: 40px;
        margin-right: 3px;
        border-radius: 8px;
        cursor: pointer;
    }

    .yes-no {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #4B4B4B;
        width: 95%;
        height: 40px;
        border-radius: 8px;
    }

    .btn-yes {
        border: none;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 5px 30px;
        text-transform: uppercase;
        font-family: Robotobold, sans-serif;
        cursor: pointer;
        margin: 0 12.5px;
    }

    .btn-yes:nth-child(odd) {
        background: #45B529;
        color: white;
        
    }

    .btn-yes:nth-child(even) {
        background: #D10101;
        color: white;
    }
    
    .btn-no {
        border: none;
        background: #D10101;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 5px 30px;
        margin-left: 25px;
        text-transform: uppercase;
        color: white;
        font-family: Robotobold, sans-serif;
        cursor: pointer;
    }
`

export const ChatMessageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    height: calc(100vh - 295px);
`

export const ChatLoadingContainer = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .33);

    div.content {
        margin: 20px 30px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        padding: 10px;
        align-items: center;
    }

    div.content label {
        color: #727272;
        font-family: Roboto;
        font-size: 0.875rem;
        margin-left: 10px;
    }
`

/////////////////////////////// Modal ////////////////////////////////////

export const ModalProtocolOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
    transition: 1s;

    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, .25);
    ${props => !props.visible && css`
        display: none;
    `}
`

export const ModalProtocol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 400px;
    height: 300px;

    padding: 20px;

    background: white;
    border: 1px solid #c6c6c6;
    border-radius: 10px;

    text-transform: uppercase;
    label {
        font-family: robotoMedium;
        font-size: 0.7rem;
        margin-right: 5px;
    }

    input {
        width: 100%;
        border: none;
        font-size: 1.4rem;
        border-radius: 4px;
        padding: 5px;
        background: #F8F9FA;
        box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.55);
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 10px 20px;
        background: #50BCBA;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        border: none;
        font-family: Roboto, sans-serif;

        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);
    }
    ${props => !props.visible && css`
        display: none;
    `}
`

export const ModalObsOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;

    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, .25);
    ${props => !props.visible && css`
        display: none;
    `}
`

export const ModalObs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 600px;
    height: 400px;

    padding: 20px;

    background: white;
    border: 1px solid #c6c6c6;
    border-radius: 10px;

    /* text-transform: uppercase; */
    h4 {
        text-align: 
    }
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    label {
        margin: 30px 0 5px 50px;
    }
    textarea {
        min-height: 200px;
        margin: 0 50px;
        padding: 10px;
        border: 1px solid #c6c6c6;
        resize: none;
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 25px 0 0 0;
        width: 22rem;
        padding: 10px 20px;
        background: #50BCBA;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        border: none;
        font-family: Roboto, sans-serif;
        
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);
    }
    /* ${props => !props.visible && css`
        display: none;
    `} */
`

export const ModalOverlay = styled.div`
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100vh;

    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, .25);
    ${props => !props.visible && css`
        display: none;
    `}
`

export const ModalReport = styled.div`
    display: flex;
    flex-direction: column;

    background: white;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    width: 800px;

    margin: 5% 0;
    padding: 20px;

    overflow-y: auto;
    
    span {
        font-family: robotoMedium;
        font-size: 1.1rem;
        text-transform: uppercase;
        text-align: center;

        margin-bottom: 30px;
    }

    div {
        display: flex;
        flex: 1;
        position: relative;
        margin: 0 50px;
        border: 1px solid #c6c6c6;

        overflow-y: auto;
        height: 800px;
        max-height: 800px;

        textarea {
            border: none;
            flex: 1;
            width: 100%;
            resize: none;
            padding: 10px 25px;
        }

        textarea::-webkit-scrollbar-track {
            background-color: #F4F4F4;
            border-radius: 30px;
        }
        textarea::-webkit-scrollbar {
            width: 6px;
            border-radius: 30px;
            background: #F4F4F4;
        }
        textarea::-webkit-scrollbar-thumb {
            background: #dad7d7;
            border-radius: 30px;
        }
    }
    div > span {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        color: #4b4b4b;
        font-size: 1.5rem;
    }

    p {
        margin-bottom: 10px;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 25px auto 0 auto;
        width: 22rem;
        padding: 10px 20px;
        background: #50BCBA;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        border: none;
        font-family: Roboto, sans-serif;
        
        font-weight: 500;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);
    }
    /* ${props => !props.visible && css`
        display: none;
    `} */
`