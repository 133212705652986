import React from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from 'styled-components';

import AppProvider from './shared/hooks';

import Routes from './config/routes';

import GlobalStyles from './shared/styles/global';
import light from './shared/styles/themes/light';

function App() {
    return (
        <Router>
            <Helmet>
                <link rel="icon" type="image/png" sizes="32x32" href="favicon.png" />
            </Helmet>

            <ThemeProvider theme={light}>

                <GlobalStyles />

                <AppProvider>
                    <Routes />
                </AppProvider>

            </ThemeProvider>

        </Router>
    );
}

export default App;