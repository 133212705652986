import styled from 'styled-components';

export const DivSearch = styled.div`
 
 button {
    border: none;
    min-width: 116px;
    height: 30px;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    padding: 0 20px 0 14px;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
  
    &:hover{
      background-color: rgba(80, 188, 186, 0.8);
    }

    span { 
      font-weight: 500;
    }

    svg {
      margin-right: 5px;
    }
 }
`