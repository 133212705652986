import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FaHubspot } from 'react-icons/fa';

import api from '~/shared/services/api';

import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderSMO from '../../components/HeaderSMO';

import { 
  Container,
  Content,
  CardTable,
  HeaderTable,
  Form
} from './styles';

export default function AllocateOnu() {
  
  const [pppoeUser, setPppoeUser] = useState("");
  const [serial, setSerial] = useState("");
  const [foundUsers, setFoundUsers] = useState([]);
  const [isSearchingUser, setIsSearchingUser] = useState(false);
  const [upload, setUpload] = useState(false);
  const [onusInBatch, setOnusInBatch] = useState("");
  const [savedOnusInBatchErr, setSavedOnusInBatchErr] = useState([]);

  const handleChangeUser = useCallback(
    async (event) => {
      setPppoeUser(event.target.value)

      if (isSearchingUser) {
        return;
      }
      try {
        setIsSearchingUser(true)

        const users = await api.get('/my_synsuite/api/v2/connections/', { 
          params: {
            user: event.target.value,
            limit: 8
          }
        })

        setFoundUsers(users.data)
        
      } catch(error) {
          alert("Não foi possivel recuperar os Usuários")
      } finally {
        setIsSearchingUser(false)
      }
    },[isSearchingUser]
  )

  const handleAllocOnu = useCallback(
    async () => {
      try {
        const foundUser = await api.get("/my_synsuite/api/v2/connections/", {
          params: {
            user: pppoeUser,
          }
        })

        let exactUser = null;

        for (const user of foundUser.data) {
          if (user.user === pppoeUser) {
            exactUser = user;
          }
        }
        
        
        if (!!exactUser) {
          await api.post("smo/api/onus/alloc/", {
            serial: serial,
            connection_id: exactUser.id,
            pppoe_user: exactUser.user,
            pppoe_password: exactUser.password
          })
          alert("Onu Alocada com Sucesso")
          setSerial("")
          setPppoeUser("")
        } else {
          alert("Usuário não encontrado")
        }
                
      } catch(error) { 
          alert(`Não foi Possivel alocar ONU.  ${error.response.data.msg || ""}`)
        } 
    },[serial, pppoeUser]
  )

  const handleAllocOnuInBatch = useCallback(
    async () => {      
      try {
        setIsSearchingUser(true);
        setSavedOnusInBatchErr([]);
 
        const onus = onusInBatch
          .split("\n")
          .filter(onu => onu.trim().length)
          .map(onu => {
            const [sn, user] = onu.split("\t");

            return { sn, user };
          })

        let promises = [];
        let errorsWhenSaveOnus = [];

        // for (const onu of onus) {
        promises = onus.map(onu => 
          api
            .get("/my_synsuite/api/v2/connections/", {
              params: {
                user: onu.user
              }
            })
            .catch(() => {
              errorsWhenSaveOnus.push({
                user: onu.user,
                sn: onu.sn,
                err: {
                  response: {
                    data: {
                      msg: "Não foi possível recuperar usuário no Synsuite"
                    }
                  }
                }
              });
            })
        )
        // }
        
        const responses = await Promise.all(promises);

        if (errorsWhenSaveOnus.length) {
          setSavedOnusInBatchErr(errorsWhenSaveOnus);
          return;
        }

        errorsWhenSaveOnus = [];
        
        // let users = [];
        // let exactUser;

        for (const i in responses) {
          if (responses[i].data.length > 1) {
            for (const tempUser of responses[i].data) {
              if (tempUser.user === onus[i].user) {
                onus[i].user_data = tempUser;
              }
            }
          } else if (responses[i].data.length === 1) {
            onus[i].user_data = responses[i].data[0];
          }

          if (!onus[i].user_data) {
            errorsWhenSaveOnus.push({
              user: onus[i].user,
              sn: onus[i].sn,
              err: {
                response: {
                  data: {
                    msg: "Usuário não existe no Synsuite"
                  }
                }
              }
            });
          }

          // users.push(exactUser);
        }

        // errorsWhenSaveOnus = [];
        
        promises = onus.filter(onu => !!onu.user_data).map(onu => {
          // const [sn, _] = onus[i].split("\t");

          return (
            api
              .post("smo/api/onus/alloc/", {
                serial: onu.sn,
                connection_id: onu.user_data.id,
                pppoe_user: onu.user_data.user,
                pppoe_password: onu.user_data.password
              })
              .catch((err) => {
                errorsWhenSaveOnus.push({
                  sn: onu.sn, 
                  user: onu.user_data.user,
                  err
                });
              })
          )
        })

        await Promise.all(promises);

        if (errorsWhenSaveOnus.length) {
          alert('Não foi possível alocar ONU(s)');
          setSavedOnusInBatchErr(errorsWhenSaveOnus);
        } else {
          alert("Onus alocadas com sucesso")
        }

      } catch (err) {
        alert("Não foi possível alocar ONU(s).");
      } finally {
        setIsSearchingUser(false);
      }
    },
    [onusInBatch],
  )
  return (
    <>
      < HeaderSMO />
      
      <Container>
        <Breadcrumb title="Adicionar ONU" paths={[
          { pageName: "SMO", link: "/smo/olt" },
          { pageName: "Alocar ONU" }
        ]} />

        <Content>
          <CardTable>

            <HeaderTable>
              <i><FaHubspot /></i>
              <span>{upload ? "Alocar em Lote" : "Alocar ONU"}</span>
            </HeaderTable>
            {upload ? 
              (<Form>
                <div>
                  <textarea
                    defaultValue={onusInBatch}
                    id=""
                    rows="10" 
                    cols="40"
                    onChange={event => setOnusInBatch(event.target.value)} />
                </div>
                <button 
                  type="button" 
                  onClick={handleAllocOnuInBatch}
                  disabled={isSearchingUser}>
                    Processar
                </button>
                <p onClick={() => setUpload(false)}>Alocar em Unidade</p>

                {savedOnusInBatchErr.length === 0 ? "" : (<ul>
                  { 
                    savedOnusInBatchErr.map(onuError => (
                      <li>
                        {onuError.sn} - &nbsp;&nbsp;{onuError.user}:&nbsp;&nbsp;{onuError.err.response?.data?.msg || "Error interno não conhecido"}
                      </li>
                    ))
                  }
                </ul>)}
              
              </Form>) :
              (<Form>
                <div>
                  <label>Serial da ONU</label>
                  <input value={serial} type="text" onChange={(event) => {
                    setSerial(event.target.value)
                  }} required />
                </div>
                <div>
                  <label>Conexão</label>
                  <datalist id="listFoudUsers">
                    {foundUsers.map(user => <option value={user.user} />)}
                  </datalist>
                  <input value={pppoeUser} list="listFoudUsers" type="text" onChange={handleChangeUser} required />
                </div>
                <button 
                  type="button" 
                  onClick={handleAllocOnu}>
                  Alocar
                </button>
                <p onClick={() => setUpload(true)}>Alocar em Lote</p>
              </Form>)
              }
          </CardTable>
          <div>
            <Link to="/smo/onuallocated">Voltar</Link>
          </div>
        </Content>
      </Container>
    </>

  )
}