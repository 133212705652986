import styled from 'styled-components';

import Background from '../../assets/earth-76235.jpg'

export const FdEarth = styled.section`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Overlay = styled.div`
  background: linear-gradient(251.78deg, #0873DE -60.39%, #4262A2 -6.87%, #1C1F73 53.79%);
  
  height: 100vh;
  width: 100%;
  opacity: .8;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BorderLogin = styled.div`
  border: 1.5px solid #F8F9FA;
  border-radius: 21px;
  width: 800px;
  height: 400px;
  display: flex;
  position: relative;
  min-width: 575px;
`

export const Left = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  border-right: 1px solid #fff;
  margin: 10px 0;

  justify-content: center;
  align-items: center;


  .logosmo {
    opacity: 100%;
  }
  .logomg {
    position:absolute;
    left: 15px;
    bottom: 5px;

    width: 60px;
  }
`

export const Right = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 100%;
    .logomgnet {
      width: 170px;
      margin-bottom: 20px;
      justify-content: center;
    }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: red;
    margin-bottom: 10px;
  }
`
export const Description = styled.div`
  margin: 0 30px 18px 30px;
  p {
    color: #fff;
    text-align: center;
    font-size: .8rem;
  }
`

export const DivImput = styled.div`
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  color: #F8F9FA;

  &:focus-within {
    border-bottom: 2px solid #50BCBA;
    color: #50BCBA;
  }
`

export const Input = styled.input`
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  color: white;
  font-size: 18px;
  padding: 3px;
  margin-left: 5px;
    
    &:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    }
`

export const ButtonEnv = styled.button`
  border: 0;

  background: #006ADD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 20px;
  color: white;
  width: 100px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
    &:hover {
      transition: 1s;
      background: #50BCBA;
      color: black;
    }
`

export const ForgotItPassword = styled.div`
  position: absolute;
  bottom: 15px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`

export const Copyrigth = styled.h3`
  color: white;
  position: absolute;
  bottom: 30px;
`