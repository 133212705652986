import React from 'react';

import Routes from '~/config/routes/mgmaps.routes';

import NavBar from '../../components/NavBar/sideBar';

import Breadcrumb from '~/shared/components/Breadcrumb';

import {BreadrumDashboard} from './style';
import Maps from '../../components/Maps';

export default function DashboardMgMaps(){
  return (
    <>      
      <NavBar />
   
      <BreadrumDashboard>
        <Breadcrumb
          paths={[
            { pageName: "MG Maps", link: "/mgmaps/district" },
            { pageName: "Dashboard" }, 
          ]}
          style={{
            padding: 0
          }}  
        />
      </BreadrumDashboard>
     

      <Routes />

    </>
  );
}