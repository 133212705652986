import styled, {css} from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1300px;
  min-width: 550px;
`

export const Content = styled.div`
  min-height: 109px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #F8F9FA;
  min-width: 550px;
  a {
    text-decoration: none;
  }
`

export const SearchCustomer = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: top;

  h2 {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 21px;
    color: #1C1F73;
    margin-left: 1px;
  }

  form {
    display:flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 60px;

    background: #50BCBA;
    box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
    border-radius: 1.16667px;
    color: black;
    
    padding: 20px 15px;
    text-align: center;
    text-decoration: none;
    transition: .5s;

    border-radius: 5px;

    &:hover {
      transition: .5s;
      background: #28908e;
      font-size: 1.1rem;
    }
  }

  div {
    display: flex;
  }
  
  label {
    font-size: 0.75rem;
    line-break: 22px;
    font-weight: 600;
    margin: 3px 0;
    font-style: normal;
  }

  input, select {
    border: none;
    background: #F8F9FA;
    width: 181px;
    height: 30px;
    border-radius: 1px;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    padding-left: 8px;
  }
  select:invalid { 
    color: gray;
    }

  .selectOlt{
    width: 208px;
  }
  .selectSlot{
    width:94px;
  }
 
  input::placeholder{
    color: #ACACAC;
    font-size: 0.875rem;
    font-weight: 300;
  }
`

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px;
`

export const SecondContent = styled.div`
   display: flex;
   flex-direction: column;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0px;
  background-color: #F8F9FA;
  overflow:hidden;
  border-radius: 5px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  th, td {
   text-align: left;
   padding-left: 10px;
  }

  td {
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: RobotoMedium;
    font-size: 0.85rem;
  }
  
  td:last-child {
      text-align: center;
  }

  tr {
    border-bottom:  1px solid rgba(0, 0, 0, 0.3);
  }

  tbody tr:hover {
    background: white;
  }

  th {
    padding-top: 10px;
    font-weight: 100;
    margin-right: 3px;
    font-family: robotoMedium;
    font-size: 0.85rem;
    color: #495057;
  }

  tr:last-child {
    border-bottom: none;
  }
`

export const Thead = styled.thead`
  background-color: #50BCBA;
  
  th {
    text-align: left;
    padding-left: 10px;
    color: rgba(248, 249, 250, 1);
    font-size: 1.125rem;
    line-height: 2.5rem;
  }
`
export const Icon = styled.button`
  border: 0;
  background: none;
  text-align: center;
  
  svg {
    font-size: 22px;
    color: #495057;
    cursor: pointer;
  }
`

export const Pagination = styled.div`
  `;

export const PaginationLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 20px;
  
  li {
    padding: 0 5px 0 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
`

export const CountOnu = styled.div`
  text-align: right;
  font-size: .8rem;
  font-family: RobotoBold, sans-serif;
  margin: 0;
  padding: 0;
`

export const PaginationLink = styled.button`
  border: none;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-left: 10px;

  ${props => props.selected 
    ? css`background-color: rgba(28, 31, 115, 0.9);` 
    : css`background: #50BCBA;`
  }

  ${props => props.disabled 
    ? css`background-color: #acacac;`
    : css`
      &:hover {
        background-color: rgba(80, 188, 186, 0.8);
      }`
  }

  span{  
    font-size: 0.875rem;
    color: #FFFFFF;
    font-weight: 500;
    padding: 0.45rem 0.7rem;
  }
`;
