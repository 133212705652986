import styled from 'styled-components';

{/* NAV HORIZONTAL */}
export const Nav = styled.div`

  background: #F8F9FA;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  padding: 0 30px;
    img{
      
      width: 79px;
      height: 30px;
    }
  position: fixed;
  top: 0;
 
  width: 100%;
`;
export const Ul = styled.ul`
margin-left: 80px;


`;

export const Li = styled.li`
  list-style: none;
  display: inline-block;
  color: #495057;
  font-size: 0.875rem;
  cursor: pointer;
  align-items: center;
  padding-left:  41px;
  text-decoration: none;
  
     a{
       text-decoration: none;
       font-size: 1rem;
       color: #949494;
       

     }
     .active{
      color: #495057;

     }
     


`;


{/* MENU LATERAL */}
export const SideBarNav = styled.div`
  background: #556374;
  width: 215px;
  display: flex;
  justify-content: center;
  position: fixed;
  margin-top: -4px;
  height: 100%;
  `;

{/* MENU LATERAL*/}
export const SideBarWrap = styled.div`
  background: #556374;
  width:100%;
  overflow-y: auto;
  position: relative;

::-webkit-scrollbar {
  width: 2px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #556374;
}
::-webkit-scrollbar-thumb {
  background: #556374; 
}
::-webkit-scrollbar-thumb:hover {
  background: #556374;
}
button:hover {
  cursor: pointer;
  opacity: .6;
}
`;

export const TitleSideBar = styled.div`
  position: fixed;
  color: white;
  padding: 10px 20px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:215px;
  background-color: #556374;
  z-index: 1000;

  span {
    display: flex;
  }

  span p {
    margin-left: 10px;
  }

  svg{
    font-size: 1em;
  }

  img{
    width: 20px;
  }

  button{
    border: 0;
    background: none;
    color: #FFFFFF;
    cursor: pointer;
    &:hover {
      opacity: .6;
    }
  }
`;

/* SideBarLink menu lateral OPÇOES */
export const SideBarLink = styled.a`

  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  list-style: none;
  width: 100%;
  text-decoration: none;
  font-size: 1.2rem;
`;

{/* SIDEBAR CONTENT */}
export const SideBarLabel = styled.div`
 margin-left: 16px;
 font-size: .8rem;
 font-weight: 400;
 form{
   padding: 5px;
   display: flex;

   label{
     margin-left: 5px;
     font-size: .8rem;
   }
 }
`;

export const SideBar  = styled.div`
  &:first-child {
    margin-top: 40px;
  }
  &:last-child {
    margin-bottom: 55px;
  }
  &:hover{
    background: rgba(237, 237, 237, 0.1);
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
  .active {
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

{/* SUBMENU LATERAL*/}
export const  DropdownLink = styled.a`
  background: #414757;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 1rem;
  &:hover{
    background: rgba(237, 237, 237, 0,1);
    cursor: pointer;
  }
`;