import React from 'react';

import { DivSearch } from "./styles";

import { BiSearchAlt } from 'react-icons/bi';

export default function SearchButton({ onClick=() => {} }) {
  return (
    <DivSearch>
      <button onClick={onClick}>
        <BiSearchAlt size={14} />
        <span>Pesquisar</span>
      </button>
    </DivSearch>
  )
}