import React, { useEffect } from 'react';
import {FiAlertCircle,FiCheckCircle, FiInfo,FiXCircle} from 'react-icons/fi';

import {useToast} from '~/shared/hooks/toast';

import {Container} from './style';
const icons = {
  info: <FiInfo size={18}/>,
  error: <FiAlertCircle size={20}/>,
  sucess: <FiCheckCircle size={18}/>

}
export default function Toast({ message }){
  const {removeToast} = useToast();

  useEffect(()=>{
    console.log(message)
    const timer = setTimeout(()=>{
      removeToast(message.id);
    },3000);

    return() =>{
      clearTimeout(timer);
    }

  },[removeToast, message.id]);
  return(
    <Container type={message.type} hasDescription={!!message.description}>
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>


  );
}