const theme = {
    title: 'light',

    colors: {
        primary: '#1C1F73',
        secondary: '#50BCBA',

        background: '#ECEDEF',
        text: '#333'
    }
};

export default theme;



