import { Switch, useRouteMatch } from "react-router-dom";

import Route from './Route';
import District from '~/modules/mgmaps/pages/District';

export default function Routes() {
    const {path} = useRouteMatch();

    return (
      <Switch>    
         <Route path={`${path}/district`} exact={true} component={District} isPrivate={true} />
      </Switch>
    );
}