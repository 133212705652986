import styled from 'styled-components';

export const BreadrumDashboard = styled.div`
  margin-top: 4rem;
  margin-left: 230px;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 220px;

/* @media screen and (min-width:1256px){
  
  
} */
`;