import React, { createContext, useCallback, useState, useContext } from 'react';
import jwt_decode from 'jwt-decode';

import api from '~/shared/services/api';

const AuthContext = createContext({});

function AuthProvider({ children }) {

  const refreshUser = useCallback(() => {
    const token = localStorage.getItem('@Seedot:token');
    const user = localStorage.getItem('@Seedot:user');

    if (token && user) {
      try {
        const { exp } = jwt_decode(token);

        if ((new Date().getTime() / 1000) <= exp) {
          api.defaults.headers.authorization = `Bearer ${token}`;
          return { token, user: JSON.parse(user) };
        }
      } catch (error) { }

      localStorage.removeItem('@Seedot:token');
      localStorage.removeItem('@Seedot:user');
    }

    return {};
  }, []);

  const [data, setData] = useState(refreshUser);

  const signIn = useCallback(async (username, password) => {
    const headers = {
      'Content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }
    const { data } = await api.post('sessions', {
      username,
      password
    },{headers:headers}, { timeout: 10000 });

    const { token, user } = data;

    localStorage.setItem('@Seedot:token', token);
    localStorage.setItem('@Seedot:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Seedot:token');
    localStorage.removeItem('@Seedot:user');

    setData({});
  }, []);

  const hasPermission = useCallback((permission) => {

    if (!data.user) { // Caso o usuário não esteja logado
      return false;
    }

    if (data.user?.is_admin) {
      return true;
    }

    let permissions = [];

    if (typeof permission === "string") {
      permissions.push(permission);
    } else {
      permissions = permission; // Caso seja um array de permissions
    }

    // Juntando as permissões do usuário com as permissões 
    // dos grupos que o usuário pertence
    let allUserPermissions = [...data.user.permissions];

    data.user.groups.forEach(group => {
      allUserPermissions = [...allUserPermissions, ...group.permissions]
    });

    // Verficando se o usuário possui todas as permissões informadas
    let foundPermission = false;

    for (const perm of permissions) {
      foundPermission = allUserPermissions.find(userPerm => 
        userPerm.codename === perm
      );
      
      if (!foundPermission) {
        return false;
      }
    }

    return true;
  }, [data]);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, refreshUser, hasPermission }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };