import styled from 'styled-components';

export const Container = styled.section`
    margin: 10px;
    display: grid;
    
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 131px 100%;
    grid-gap: 8px;
`
export const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

    div {
      margin: 5px 10px;
    }
    ul {
      display: flex;
      list-style: none;
      padding: 5px 10px;
      font-size: .8rem;
    }
    li {
      padding: 0 2px;
      color: #48494E;
    }

    a {
      color: #4b4b4b;
      text-decoration: none;
    }

    p {
      display: flex;
      justify-content: left;
      align-items: center;

      font-family: 'Roboto',sans-serif;
      font-weight: 400;
      font-size: 1.5rem;
      
      padding-left: 10px;
      color: #4B4B4B;
    }
`

export const Section = styled.div`
    background: #F8F9FA;
    padding: 5px;
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    height: 100%;
    
    display:flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    form {
        display:flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }

`
export const Title = styled.h3`
    color: #1C1F73;
    margin: 0 5px;
    font-size: 1.1rem;
    text-transform: uppercase;
    width: 100%;
`

export const Campsearch = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    label {
        font-size: .75rem;
        font-weight:700;
        margin: 0 5px;
    }
`

export const Inputsearch = styled.input`
    margin: 0 5px;
    border: none;
    background: #F8F9FA;
    min-width: 220px;
    height: 30px;
    border-radius: 4px;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    padding-left: 4px;

    input::placeholder {
        color: #ACACAC;
        font-size: 0.875rem;
        font-weight: 300;
        padding-left: 8px;
    }
    &:hover {
        background: #fff;
        transition: .8s;
    }
`

export const SearchButton = styled.input`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 1px;
    border: none;
    padding: 5px;
`

export const Time = styled.div`
    background: #45B529;
    min-width: 100px;
    padding: 5px;
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`

export const Content = styled.div`
    background: #F8F9FA;
    /* margin: 15px; */
    box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    
    grid-column-start: 1;
    grid-column-end: 3;

    ul {
        /* padding: 15px; */
    }
`

export const ClientItem = styled.li`
    display: grid;
    grid-template-columns: 5fr 3fr 3fr 2fr;
    grid-template-rows: 100%;
    grid-gap: 8px;

    /* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
    padding: 15px 5px 20px 10px;
    word-wrap: break-word;

    &:hover {
        transition: .8s;
        background-color: #fff;
    }

    h4 {
        color: rgba(248,249,250,1);
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 2.5rem;
    }
`
export const TableHeader = styled(ClientItem)`
    background: #1C1F73;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0 5px 0px 10px;
    word-wrap: break-word;

    &:hover {
        background: #1C1F73;
    }
`  

export const ClientName = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    h2 {
        font-family: Roboto;
        font-size: 1.125rem;
        line-height: 29px;

        color: #1C1F73;  
        margin: auto 0;
    }
`;

export const DataItem = styled.div`
    display: flex;
    flex-direction: column;

    div.init-attendance-button-container {
        display: flex;
        justify-content: center;
        align-items: center; 
        height: 100%;
        word-wrap: break-word;
    }

    button {
        border: none;
        background: #1F2397;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
        border-radius: 1px;
        color: white;
        padding: 10px 25px;
        font-weight: bold;
        font-size: .7rem;
        cursor: pointer;        
    }


    svg {
        margin-right: 10px;
    }
`

export const DataRow = styled.div`
    display: flex;
    word-wrap: break-word;

    strong {
        font-weight: 100;
        margin-right: 3px;
        font-size: 0.85rem;
        font-family: RobotoMedium;
        color: #495057;
    }

    span {
        color: #495057;
        font-size: 0.85rem;
        font-family: Roboto;
        min-width: 200px;
        word-wrap: break-word;
    }
`;
