import moment from 'moment-timezone';
import api from '~/shared/services/api';

import { Node, Tree, AttendanceError } from './decisionTreeAttendance';

const decisionTreeAttendance = new Tree();

/*
[X] Função para informar qual tecnologia usa o cliente
[X] Ver tipo da ONU (bridge ou router)
[X] Ver sinal da ONU
[X] Ver se o cliente esta online
[X] Fazer um GET para os principais serviços
[X] Pingar no ponto de acesso do cliente está em pé
[ ] Desautorizar ONU
[ ] Verificar se tem algum equipmamento ligado na LAN da ONU
*/

async function verifyRouterRemoteAccess(node) {
  const connectionData = decisionTreeAttendance.getAttendanceConnectionData();

  try {
    const { data: pppoeStatus } = await api
      .get(`smo/api/pppoe/status/`, {
        params: { pppoe_user: connectionData.user },
        timeout: 10000
      })

    var { ip, status } = pppoeStatus;

    if (status !== "online") {
      throw AttendanceError("Não foi possível testar acesso remoto ao roteador, pois o cliente está offline. Por favor, tente novamente.")
    }
  } catch (error) {
    node.setCallbackResult({
      error: true,
      msg: "Não foi possível testar acesso remoto ao roteador. Por favor, volte e tente novamente ou acesse manualmente."
    })
    // throw AttendanceError("Não foi possível testar acesso remoto ao roteador. Por favor, tente novamente.");
  }

  try {
    const { data: accessTestResult } = await api
      .get('smo/api/tests/remote_access_ports/', {
        params: { ip },
        timeout: 15000
      })

    const { up, port } = accessTestResult;

    if (up) {
      node.setCallbackResult({ up, host: `http://${ip}:${port}` })
    } else {
      node.setCallbackResult({ up, msg: "Foi detectado que nenhuma porta do roteador está habilitada para acesso remoto." });
    }
  } catch (error) {
    node.setCallbackResult({
      error: true,
      msg: "Não foi possível testar acesso remoto ao roteador. Por favor, volte e tente novamente ou acesse manualmente."
    })
    // throw new AttendanceError("Não foi possível testar acesso remoto ao roteador. Por favor, tente novamente.");
  }
}

async function finishAttendance() {
  // TODO: Criar OS no Synsuite
  setTimeout(decisionTreeAttendance.finishAttendanceCallback(), 2000);
}

async function createCorrectiveSolicitation() {
  // TODO: Abrir OS corretiva no Synsuite com robo e finalizar atendimento
}


async function deauthorizeOnu() {

}

async function verifyOnuSignal() {
  // TODO: Verificar se ta chegando sinal da ONT
  const equipment = decisionTreeAttendance.getAttendanceEquipment();

  const { data } = await api.get(`smo/api/onus/${equipment.id}/optical_info/`, { timeout: 10000 });

  if (data.status === "online" && data.rx_power <= 30) {
    return "Sim";
  } else {
    return "Não";
  }
}


async function verifyMainServices() {
  const { data } = await api.get(`smo/api/tests/internet_services/`, {
    timeout: 10000
  });

  console.log(data.up)

  if (data.up) {
    return "stable";
  } else {
    return "problem";
  }
}

async function verifyAccessPoint() {
  // TODO: Verificar se tem problema no link ou no ponto de acesso.
  const equipment = decisionTreeAttendance.getAttendanceEquipment();

  const { data } = await api.get(`smo/api/onus/${equipment.id}/test_access_point/`, {
    timeout: 10000
  });

  console.log(data.up);

  if (data.up) {
    return "Não";
  } else {
    return "Sim";
  }
}

async function verifyClientConnection() {
  // TODO: Verificar se o cliente esta logado
  const connectionData = decisionTreeAttendance.getAttendanceConnectionData();

  const { data } = await api.get(`smo/api/pppoe/status/`, {
    params: { pppoe_user: connectionData.user },
    timeout: 10000
  });

  console.log(data.status);

  if (data.status === "online") {
    return "Online";
  } else {
    return "Offline";
  }
}

async function nodeRootVerifyClientConnection() {
  // TODO: Verificar se o cliente esta logado
  const connectionData = decisionTreeAttendance.getAttendanceConnectionData();

  const { data } = await api.get(`smo/api/pppoe/status/`, {
    params: { pppoe_user: connectionData.user },
    timeout: 10000
  });

  console.log(data.status);

  if (data.status === "online") {
    return "Online";
  } else {
    return "Offline";
  }
}

async function getClientConnectionTechnology() {
  // TODO: Verifica qual a tecnologia que o cliente está usando (Fibra, Rádio, Cabo)
  const equipment = decisionTreeAttendance.getAttendanceEquipment();

  console.log(equipment.tecnology);

  if (equipment.tecnology === 'FIBRA') {
    return "Fibra";
  } else if (equipment.tecnology === 'CABO') {
    return "Cabo";
  } else {
    return "Radio";
  }
}

async function getOnuMode() {
  // TODO: Verificar se a ONT está em bridge ou router
  const equipment = decisionTreeAttendance.getAttendanceEquipment();

  if (equipment.connection_type === '1') {
    return 'Bridge'
  } else {
    return 'Router'
  }
}

async function getEquipmentConnectedOnuLan() {
  // TODO: Verificar se existe algum equipamento ligado na LAN da ONU.
  return "Sim";
}



// Último nó da árvore do atendimento quando o problema foi corrigido 
const nodeFinishAttendanceCongratulations = () => new Node({
  msg: [
    { text: "Sr(a) posso ajudar em algo mais?", to: "client" }
  ],
  responseOptions: [
    { text: "Sim", nextNodeKey: "restartAttendance" },
    { text: "Não", nextNodeKey: "finishAttendance" }
  ],
  waitUserResponse: true,
  children: {
    "restartAttendance": () => decisionTreeAttendance.getRootNode(),
    "finishAttendance": () => new Node({
      msg: function (node) {
        const hour = moment(new Date()).tz('America/Bahia').hour();
        let hourMsg = "";

        if (5 <= hour && hour <= 11) {
          hourMsg = "um bom dia";
        } else if (12 <= hour && hour <= 17) {
          hourMsg = "uma boa tarde";
        } else {
          hourMsg = "uma boa noite";
        }

        return [
          { text: `A MGNET agradece a sua compreensão. Tenha ${hourMsg}.`, to: "client" }
        ];
      },
      responseOptions: [
        { text: "Finalizar Atendimento", nextNodeKey: "createSolicitationAttendance" }
      ],
      children: {
        "createSolicitationAttendance": () => new Node({
          feedBack: "Finalizando atendimento...",
          callback: finishAttendance
        })
      }
    })
  }
})


/* Último nó da árvore do atendimento quando o problema não foi corrigido 
 e é necessário abrir uma ordem de serviço.
*/
const nodeFinishAttendanceOpenCorrectiveSolicitation = () => new Node({
  msg: [
    {
      text: "Sr(a) aguarde um momento enquanto abro um atendimento corretivo em seu cadastro",
      to: "client"
    },
    {
      text: "Abra uma OS corretiva para este cliente, e informe o protocolo ao mesmo",
      to: "attendant"
    }
  ],
  responseOptions: [
    { text: "continuar", nextNodeKey: "finishAttendance" }
  ],
  children: {
    // "createCorrectiveSolicitation": () => new Node({
    //   feedBack: "Abrindo atendimento corretivo",
    //   callback: createCorrectiveSolicitation,
    // })
    "finishAttendance": nodeFinishAttendanceCongratulations
  }
});


const nodeClientIsNotAtHouse = () => new Node({
  msg: [
    { text: "Prefere ligar para nós quando chegar no local, ou que liguemos para o Sr(a)?", to: "client" }
  ],
  responseOptions: [
    { text: "Cliente irá ligar", nextNodeKey: "finishAttendance" },
    { text: "Nós ligaremos para ele", nextNodeKey: "openPendingCall" }
  ],
  children: {
    "finishAttendance": nodeFinishAttendanceCongratulations,
    "openPendingCall": () => new Node({
      msg: [
        { text: "Sr(a) aguarde um momento enquanto abro uma pendência de ligação em seu cadastro", to: "client" },
        { text: "Pendência de ligação criada?", to: "attendant" }
      ],
      responseOptions: [
        { text: "Sim", nextNodeKey: "finishAttendance" }
      ],
      children: {
        "finishAttendance": nodeFinishAttendanceCongratulations
      }
    })
  }
});


const nodeCallIfAccessDoesNotNormalize = () => new Node({
  msg: [
    { text: "Peço que ligue daqui a 1 hora novamente caso o acesso não normalize ou Prefere que nós liguemos para o Sr(a)?", to: "client" }
  ],
  responseOptions: [
    { text: "Cliente irá ligar", nextNodeKey: "finishAttendance" },
    { text: "Nós ligaremos para ele", nextNodeKey: "openPendingCall" }
  ],
  children: {
    "finishAttendance": nodeFinishAttendanceCongratulations,
    "openPendingCall": () => new Node({
      msg: [
        { text: "Sr(a) aguarde um momento enquanto abro uma pendência de ligação em seu cadastro", to: "client" },
        { text: "Pendência de ligação criada?", to: "attendant" }
      ],
      responseOptions: [
        { text: "Sim", nextNodeKey: "finishAttendance" }
      ],
      children: {
        "finishAttendance": nodeFinishAttendanceCongratulations
      }
    })
  }
});


/**************************************************************
 * Nós Fibra Offline 
 **************************************************************/
const nodeOfflineFiberVerifyOnuMode = () => new Node({
  feedBack: "Verificando modo da ONU (router ou bridge)",
  callback: getOnuMode,
  children: {
    "Router": () => new Node({
      feedBack: "Verificando conexão do cliente",
      callback: verifyClientConnection,
      children: {
        "Online": nodeFinishAttendanceCongratulations,
        "Offline": nodeFinishAttendanceOpenCorrectiveSolicitation
      }
    }),
    "Bridge": () => new Node({
      feedBack: "Verificando se existe algum equipamento ligado na LAN da ONU",
      callback: getEquipmentConnectedOnuLan,
      children: {
        "Sim": () => new Node({
          msg: [
            {
              text: "Solicitar ao cliente que reinicie o roteador e verifique a posição dos cabos que sai da ONU para o Roteador",
              to: "attendant"
            },
            { text: "O cliente finalizou o procedimento?", to: "attendant" }
          ],
          responseOptions: [
            { text: "Sim", nextNodeKey: "verifyConnection" }
          ],
          waitUserResponse: true,
          children: {
            "verifyConnection": () => new Node({
              feedBack: "Verificando conexão do cliente",
              callback: verifyClientConnection,
              children: {
                "Online": nodeFinishAttendanceCongratulations,
                "Offline": nodeFinishAttendanceOpenCorrectiveSolicitation
              }
            })
          }
        }),
        "Não": () => new Node({
          msg: [
            { text: "Solicitar ao cliente que certifique se o roteador está ligado e com os cabos nas posições corretas.", to: "attendant" },
            { text: "O acesso normalizou?", to: "attendant" }
          ],
          responseOptions: [
            { text: "Sim", nextNodeKey: "normalizedAccess" },
            { text: "Não", nextNodeKey: "dontNormalizedAccess" }
          ],
          children: {
            "normalizedAccess": nodeFinishAttendanceCongratulations,
            "dontNormalizedAccess": nodeFinishAttendanceOpenCorrectiveSolicitation
          }
        })
      }
    })
  }
})


const nodeOfflineFiberVerifySignalOnu = () => new Node({
  feedBack: "Verificando sinal da ONU",
  callback: verifyOnuSignal,
  children: {
    "Sim": nodeOfflineFiberVerifyOnuMode,
    "Não": nodeFinishAttendanceOpenCorrectiveSolicitation
    // "Não": () => new Node({
    //   feedBack: "Desautorizando ONU",
    //   callback: deauthorizeOnu,
    //   msg: [
    //     { text: "Sr.(a) aguarde um momento enquanto realizamos algumas alterações", to: "client" },
    //     { text: "Após o tempo necessário (de até 1min30s), voltou o sinal da ONU?", to: "attendant" }
    //   ],
    //   responseOptions: [
    //     { text: "Sim", nextNodeKey: "" }
    //   ],
    //   waitUserResponse: true,
    //   children: {
    //     "Sim": nodeOfflineFiberVerifyOnuMode,
    //     "Não": nodeFinishAttendanceOpenCorrectiveSolicitation
    //   }
    // })
  }
})


const nodeQuestionAccessNormalized = () => new Node({
  msg: [
    { text: "O acesso normalizou?", to: "attendant" }
  ],
  responseOptions: [
    { text: "Sim", nextNodeKey: "normalizedAccess" },
    { text: "Não", nextNodeKey: "dontNormalizedAccess" }
  ],
  children: {
    "normalizedAccess": nodeFinishAttendanceCongratulations,
    "dontNormalizedAccess": nodeFinishAttendanceOpenCorrectiveSolicitation
  }
})


const nodeOnlineVerifyDNS = () => new Node({
  callback: verifyRouterRemoteAccess,
  msg: function (node) {
    const { up = false, host = null, error = null, msg = "" } = node.getCallbackResult();

    if (error) {
      return [
        { text: msg, to: "attendant" },
        { text: "Conseguiu acessar?", to: "attendant" }
      ];
    }
    else if (up) {
      return [
        {
          toString: () => `Faça o acesso remoto do Moldem/Router do cliente no link\n${host}`,
          text: <>
            <p>Faça o acesso remoto do Moldem/Router do cliente no link</p>
            <a href={host} target="_blank" rel="noreferrer">{host}</a>
          </>,
          to: "attendant"
        },
        { text: "Conseguiu acessar?", to: "attendant" }
      ]
    } else {
      node.setResponseOptions([
        { text: "Sim", nextNodeKey: "openCorrectiveSolicitation" },
        { text: "Não", nextNodeKey: "verifyDNS" }
      ]);

      return [
        { text: msg, to: "attendant" },
        { text: "Confirma?", to: "attendant" }
      ]
    }
  },
  // msg: [
  //   { text: "Faça o acesso remoto do Moldem/Router do cliente", to: "attendant" },
  //   { text: "Conseguiu?", to: "attendant" }
  // ],
  responseOptions: [
    { text: "Sim", nextNodeKey: "verifyDNS" },
    { text: "Não", nextNodeKey: "openCorrectiveSolicitation" }
  ],
  children: {
    "verifyDNS": () => new Node({
      msg: [
        { text: "Acesse o roteador do cliente ", to: "attendant" },
        { text: "Os DNS estão corretamente configurados com os DNS da empresa?", to: "attendant" }
      ],
      responseOptions: [
        { text: "Sim", nextNodeKey: "restartRouter" },
        { text: "Não", nextNodeKey: "fixedDNS" }
      ],
      children: {
        "restartRouter": () => new Node({
          msg: [
            { text: "Peça para que o cliente reinicie os equipamentos, aguarde logar, e realize os testes novamente", to: "attendant" }
          ],
          children: nodeQuestionAccessNormalized
        }),
        "fixedDNS": () => new Node({
          msg: [
            { text: "Fixar os DNS da empresa e pedir pra o cliente reiniciar o equipamento e realizar o teste novamente", to: "attendant" }
          ],
          children: nodeQuestionAccessNormalized
        })
      }
    }),
    "openCorrectiveSolicitation": nodeFinishAttendanceOpenCorrectiveSolicitation,
  }
})


const nodeOnlineFiber = () => new Node({
  feedBack: "Verificando possível problema nos principais serviços da internet",
  callback: verifyMainServices,
  children: {
    "stable": () => new Node({
      msg: [
        { text: "O Sr(a) está com problema em um site ou serviço específico?", to: "client" }
      ],
      responseOptions: [
        { text: "Sim", nextNodeKey: "accessService" },
        { text: "Não", nextNodeKey: "accessRouterRemotely" }
      ],
      children: {
        "accessService": () => new Node({
          msg: [
            { text: "Acesse site ou serviço na sua Maquina e verifique se está funcionando corretamente", to: "attendant" },
            { text: "Está conseguindo acesso ao site ou serviço especifico?", to: "attendant" }
          ],
          responseOptions: [
            { text: "Sim", nextNodeKey: "accessRouterRemotely" },
            { text: "Não", nextNodeKey: "problemInNetwork" }
          ],
          children: {
            "accessRouterRemotely": nodeOnlineVerifyDNS,
            "problemInNetwork": () => new Node({
              msg: [
                { text: "Sr.(a) vi que tem algum problema com esse site/serviço vamos verificar com nosso setor de Tecnologia.", to: "client" }
              ],
              children: nodeCallIfAccessDoesNotNormalize
            })
          }
        }),
        "accessRouterRemotely": nodeOnlineVerifyDNS
      }
    }),
    "problem": () => new Node({
      msg: [
        { text: "Sr(a) estamos ciente de uma instabilidade nos serviços do facebbok, instagram e google. Por favor aguarde 1 hora até que os serviços normalizarem", to: "client" }
      ]
    })
  }
});


const nodeClientOnline = () => new Node({
  msg: [
    { text: "Sr(a) precisamos fazer alguns testes para tentar resolver o problema", to: "client" },
    { text: "O Sr(a) está no local de acesso agora?", to: "client" }
  ],
  responseOptions: [
    { text: "Sim", nextNodeKey: "verifyOnuMode" },
    { text: "Não", nextNodeKey: "clientIsNotAtHouse" },
  ],
  children: {
    "verifyOnuMode": () => new Node({
      callback: getClientConnectionTechnology,
      children: {
        "Cabo": () => new Node({}),
        "Radio": () => new Node({}),
        "Fibra": nodeOnlineFiber
      }
    }),
    "clientIsNotAtHouse": nodeClientIsNotAtHouse
  }
})


const attendanceRootNode = () => new Node({
  feedBack: "Verificando conexão do cliente",
  callback: nodeRootVerifyClientConnection,
  children: {
    "Online": nodeClientOnline,
    "Offline": () => new Node({
      feedBack: "Verificando possível problema no link ou no ponto de acesso",
      callback: verifyAccessPoint,
      waitUserResponse: false,
      children: {
        "Sim": () => new Node({
          callback: null,
          msg: [
            {
              text: "Está havendo uma eventualidade na sua área, já tem uma equipe técnica no local (ou se dirigindo para o local) e o problema será resolvido o mais breve possível (Caso já tenha prazo de restabelecimento informar ao cliente)",
              to: "client"
            }
          ]
        }),
        "Não": () => new Node({
          callback: getClientConnectionTechnology,
          children: {
            "Cabo": () => new Node({}),
            "Radio": () => new Node({}),
            "Fibra": () => new Node({
              msg: [
                { text: "Sr(a) precisamos fazer alguns testes para tentar resolver o problema.", to: "client" },
                { text: "O Sr(a) está no local de acesso agora?", to: "client" }
              ],
              responseOptions: [
                { text: "Sim", nextNodeKey: "Sim" },
                { text: "Não", nextNodeKey: "Não" }
              ],
              waitUserResponse: true,
              children: {
                "Sim": () => new Node({
                  feedBack: "Verificando sinal da ONU",
                  callback: verifyOnuSignal,
                  children: {
                    "Sim": nodeOfflineFiberVerifyOnuMode,
                    "Não": () => new Node({
                      msg: [
                        { text: "Sr(a) verifique se a ONU está ligada (power e tomada), caso não esteja, por favor ligue", to: "client" },
                        { text: "Onu ligou?", to: "attendant" }
                      ],
                      responseOptions: [
                        { text: "Sim", nextNodeKey: "verifyLedLoss" },
                        { text: "Não", nextNodeKey: "openCorrectiveSolicitation" }
                      ],
                      children: {
                        "verifyLedLoss": () => new Node({
                          msg: [
                            { text: "Sr(a) verifique se o led Los está aceso", to: "client" }
                          ],
                          responseOptions: [
                            { text: "Los aceso", nextNodeKey: "verifyConnector" },
                            { text: "led apagado", nextNodeKey: "verifyOnuSignal" }
                          ],
                          children: {
                            "verifyConnector": () => new Node({
                              msg: [
                                { text: "Sr(a) verifique se conector está bem encaixado na ONU, e em seguida reinicie-a", to: "client" },
                                { text: "Sr(a) Após reiniciar a ONU o led los continua aceso?", to: "client" }
                              ],
                              responseOptions: [
                                { text: "Sim", nextNodeKey: "openCorrectiveSolicitation" },
                                { text: "Não", nextNodeKey: "verifyOnuSignal" }
                              ],
                              children: {
                                "openCorrectiveSolicitation": nodeFinishAttendanceOpenCorrectiveSolicitation,
                                "verifyOnuSignal": nodeOfflineFiberVerifySignalOnu
                              }
                            }),
                            "verifyOnuSignal": nodeOfflineFiberVerifySignalOnu
                          }
                        }),
                        "openCorrectiveSolicitation": nodeFinishAttendanceOpenCorrectiveSolicitation
                      }
                    })
                  }
                }),
                "Não": nodeClientIsNotAtHouse
              }
            })
          }
        })
      }
    })
  }
})


decisionTreeAttendance.setRootNode(attendanceRootNode);

export default decisionTreeAttendance;