
import { Link } from "react-router-dom";

 import { FaUserAlt} from 'react-icons/fa';
 import { HeaderApi, LogoMgnet, Menu } from './styles';
 import LogoColorful from '~/shared/assets/logo-mgnet.png';

export default function HeaderDark() {
  return (
    <HeaderApi>
        <LogoMgnet>
          <Link to='/home' >
            <img src={LogoColorful} alt="Logo branca MGNET"/>
          </Link>
        </LogoMgnet>
      <Menu>
        <Link to='/admin'> <FaUserAlt /> Usuários</Link>
       {/*  <Link to='/admin/grups'> <FaUsers className="icongrup" /> Grupos </Link> */}
        {/* <a href=" "> <FaWrench/> Configurações </a> */}
      </Menu>

  </HeaderApi>
    
  )
  }