import styled, {css} from 'styled-components';

const cardColorVariations = {
  
    red: css`
    color: #ED0707;
  `,
    cyan: css`
    color: #50BCBA;
  `,
    yellow: css`
    color: #EABA0F;
  `,
    green: css`
    color: #45B529;
  `
}

export const Container = styled.div`
  background: linear-gradient(251.78deg, #0E131C -60.39%, #4262A2 -6.87%, #222228 53.79%);
  background-size: cover;
  background-attachment: fixed;
  width:100vw;
  height:100vh;
`
export const SmallCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  margin-left: 20px;

    a {
      text-decoration: none;
      }
`
export const Card = styled.div`

  width:300px;
  height:200px;
  background-color: rgba(229, 229, 229, 0.24);
  display:flex;
  align-items: center;
  cursor: pointer;
  text-decoration:none;
  

  justify-content:center;
  flex-direction: column;
  border-radius:10px;

    &:hover {
      transition: .5s;
      border: solid 2px #FFF;
    }

    p{
      font-size: 30px;
      color: #D0CECE;
      font-style: medium;
      font-weight: 500;
      font-size: 40px;
      line-height: 47px;
      padding-top:30px;
      text-decoration: none;
    }

    svg{
      ${props => cardColorVariations[props.cor] || 'red'}
      width: 80px;
      height: 80px;
    }
`
export const MainCard = styled.div`
  display:flex;
  flex:1;
  justify-content:center;
  margin-top: 100px;
  padding-bottom:60px;

  a {
    text-decoration: none;
  }
`

export const BigCard = styled.div`

  width:300px;
  height:420px;
  background-color: rgba(229, 229, 229, 0.24);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  border-radius:10px;
  cursor: pointer;
    
    &:hover {
      transition: .5s;
      border: solid 1px #FFF;
    }

    p{
      color:#D0CECE;
      font-size: 50px;
      line-height:60px;
      font-family: RobotoBold;
      padding-top:30px;
    }

    svg{
      color: #D69129;
      width: 110px;
      height: 110px;
    }
`
export const Footer = styled.div`

    p{
      color:#F8F9FA;
      text-align:center;
      font-family: Rounded Mplus 1c;
  }

`