import React, { useCallback, useState } from 'react';

import { BsArrowsFullscreen, BsFillImageFill,BsArrowCounterclockwise } from "react-icons/bs";
import { MdClose} from "react-icons/md";







import Breadcrumb from '~/shared/components/Breadcrumb';

import HeaderMgcliente from '../../components/HeaderMgcliente'

import {
    Content,
    Container,
    InformationClient,
    TitleInformation,
    Table,
    Cards,
    Card,
    CardTitle,
    CardImage,
   
    ModalOverlay,
    RotateImage,
    Modal,
    ModalClose,
    ModalContent,


    
} from './styles';


export default function ServiceOrders() {

  const [modalVisible, setModalVisible] = useState(0);
  const [rotatePic, setRotatePic] = useState(0);
  const [valueRotate, setValueRotate] = useState(0);

  const handleShowModal = useCallback (() =>{
    setModalVisible(!modalVisible);

  },[modalVisible]);

  
  const rotateImage = useCallback(()=>{
      setValueRotate(valueRotate+90);
      setRotatePic(true);
  },[valueRotate]);
 


 

    return (
        <>
          <HeaderMgcliente />
            
            <Breadcrumb title="Solicitação" paths={[
        { pageName: "MG CLIENTE", link: "/mgcliente" },
        { pageName: "Solicitações" },
        { pageName: "Solicitação" }
      ]} />
        <Content> 
          


            <Container> 

              <InformationClient> 
              <TitleInformation> 
                <h3> Informações Pessoais</h3>

              </TitleInformation>

              <Table>
                <thead>
                  <tr>
                    <th colSpan="2"> </th>

                  </tr>
                  </thead>
                  <tbody>
                    <tr> 

                      <td> <strong> Nome:</strong> Fernando Luiz Correia de MELO</td>
                      <td> <strong> CPF: </strong> 123.456.789-15</td>

                    </tr>

                    <tr> 

                      <td> <strong>Nome da Mãe: </strong> Isabel Pereira da Silva</td>
                      <td> <strong> RG: </strong> 003.025-745</td>

                    </tr>

                    <tr> 

                      <td> <strong> Data de Nascimento: </strong> 24 de Abril de 1999 </td>
                      <td> <strong> Estado Cívil:  </strong> CASADO(A)</td>

                    </tr>

                    <tr> 

                      <td> <strong> Email: </strong> fernando@gmail.com </td>
                      <td> <strong> Telefone 1: </strong> (84) 98826-0319</td>
                      

                    </tr>
                    <tr> 
                      <td> <strong> Telefone 2: </strong> (84) 98826-0318</td>
                    </tr>
                    
                  </tbody>
                

              </Table>


              <TitleInformation> 
                <h3> Endereço do Cliente</h3>

              </TitleInformation>

              <Table>
                <thead>
                  <tr>
                    <th colSpan=""> </th>

                  </tr>
                  </thead>
                  <tbody>
                    <tr> 

                      <td> <strong> Cidade:</strong> São Miguel do Gostoso </td>
                      <td> <strong> Bairro: </strong> Centro</td>

                    </tr>

                    <tr> 

                    <td> <strong> Logradouro: </strong> Avenida dos Arrecifes</td>
                    <td> <strong> CEP: </strong> 59114500 </td>
                      

                    </tr>

                    <tr> 

                      <td> <strong> Complemento: </strong> Em Frente a Assembleia de Deus </td>
                      <td> <strong> Número:  </strong> 305</td>

                    </tr>

                   
                  </tbody>
                

              </Table>

              <TitleInformation> 
                <h3> Detalhes da Solicitação</h3>

              </TitleInformation>

              <Table>
                <thead>
                  <tr>
                    <th colSpan="2"> </th>

                  </tr>
                  </thead>
                  <tbody>
                    <tr> 

                      <td> <strong> Plano:</strong> 100M </td>
                      <td> <strong> Valor: </strong> R$ 79,90</td>

                    </tr>

                    <tr> 

                    <td> <strong> Vencimento: </strong>  15 </td>
                    <td> <strong> Status: </strong> Finalizada </td>
                      

                    </tr>

                    <tr> 

                      <td> <strong> Realizada em: </strong> 4 de SETEMBRO de 2020 ás 16:39 </td>
                      <td> <strong> Verificado em: </strong> 24 de SETEMBRO de 2020 ás 16:39 </td>


                    </tr>
                    <tr> 

                      <td> <strong> Verificado por: </strong> LUCIEL BRITO DE ARAUJO </td>
                      <td> <strong> Vendedor: </strong> Luciel Brito </td>


                    </tr>

                    <tr> 

                      <td> <strong> Relato: </strong> Solicitado no Sistema </td>
                      


                    </tr>



                   
                  </tbody>
                

              </Table>

              <Cards> 
              <CardTitle> 
                <span> <BsFillImageFill /></span>
                    <h3>  Comprovante</h3>
                </CardTitle>

                <Card> 

                  <CardImage> 
                    <div className="botoes"> 
                      <button onClick={handleShowModal}> <BsArrowsFullscreen /> </button>

                      </div>
                    <img src="https://gizmodo.uol.com.br/wp-content/blogs.dir/8/files/2011/01/Divulgacao-INI-20110114123502.jpg" alt=""/>

                  </CardImage>

                  <CardImage> 

                  <div className="botoes"> 
                  <button onClick={handleShowModal}> <BsArrowsFullscreen /> </button>
                      

                      </div>
                     
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7OOou8vOf_DedNMTOmz6JcLUYpCubkekk8g&usqp=CAU" alt=""/>

                  </CardImage>
                  
                </Card>
                
               
              </Cards>
             
              </InformationClient>

              


            </Container>

            
           
          </Content>

          <ModalOverlay visible={modalVisible}>
              <Modal visible={modalVisible}> 
                <ModalClose visible={modalVisible}>
               
                

                <button onClick={handleShowModal}> <MdClose /> </button>
                

                </ModalClose>
                
                <p>
                   <span> <BsFillImageFill /> </span>
                    <span> Comprovante da Solicitação</span>
                </p>
                <RotateImage>
                     <button onClick={() => rotateImage()} >  <BsArrowCounterclockwise /> </button>
                </RotateImage>
                
                <ModalContent rotate={String(rotateImage)} valueRotate={`${String(valueRotate)}deg`}> 
                <img src="http://170.82.73.98:8003/documents/22/address_proof.jpg" alt=""/>

                  

                </ModalContent>
              </Modal>
            </ModalOverlay>

          
        </>
    )
}

