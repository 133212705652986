import styled,{css} from 'styled-components';


export const Content = styled.div`
margin: 1.125rem auto;

`;


export const Filters = styled.div`
   
 
  
  

  padding: 20px;




  form{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 2fr)); 
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.125rem;
    
  }

`;

export const FilterInputs = styled.div`
  padding: 0.313rem;
 

`;

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  height: 54px;
  margin: 4px 8px;
  select, input{
    flex: 1; 
    margin-top: 1%;
    border: 1px solid #B7BEDB;
    background-color: #FFFFFF;
    padding-left: 0.25rem;
    border-radius: 0.25rem;

  }

    label{
      color: #495057;
      font-size: 0.7rem;
      font-weight: 100;
    }
    
    input[type="date"]{
      flex:1;
      height: 35px;
      width: 15rem;
      min-width: 240px;
      
    }

  input[class="final"], label[class="final"]{
    margin-left: 8%;
      

  }
  
  

  div{
    
    height: 38.15px;
    
    
  }
    
  .all{
    
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
  }

  select option:nth-child(1){
    display: none;
  }

 
  


  @media(max-width:562px){
    width: 500px;
    .all{
    flex: 1;
    display: grid;
    gap: 10px;
    
    
  }
  select, input{
    width: 60%;
  }

  
  input[class="final"], label[class="final"]{
    margin-left: 0;
    
      
  }

  }
  `;

export const ButtonSearch = styled.div`

  display: flex;
  justify-content: flex-start;

  margin-left: 2%;
  
  button {
  
    border: none;
    width: 160px;
    height: 30px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 1px;
    cursor: pointer;
    outline: none;

    transition: filter 0.2s;
  
  &:hover{
    filter: brightness(0.9);
  }

  span{  
    font-size: 0.875rem;
    color: #FFFFFF;
    font-weight: 500;
  }

 }



 button:nth-child(2){
  background: #D7DAE7;
  margin-left: 1.125rem;
  

 }
  button:nth-child(2) span{
    color: #495057;

  }

@media(max-width:600px){
  margin-top: 40px;
  display: flex;
  flex-direction: row;

}
 
`;

export const ListThe = styled.div`
  margin: 0 auto;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 0.313rem;
 


`;
export const Table = styled.table`
  flex: 1;
  margin: 2.5rem auto;
  border-collapse: collapse;
  width: 98%;
  background-color: #FFFFFF;
  overflow:hidden;
  -webkit-border-radius: 0.313rem;
  -moz-border-radius: .313rem;
  border-radius: .313rem;
  

  svg{
    color: #495057;
    font-size: 1.3rem;
    cursor: pointer;
  }
 

thead th{
  padding: 0.938rem;

  background-color: #50BCBA;
}
thead th{

  color:#FFFFFF;
}


th, td{
  text-align: left;
  padding-left: 3%;
  padding: 1.125rem;
  font-size: 1rem;
  color: #495057;
  font-weight: 400;
}

  tr{
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  tr:last-child {
      border-bottom: none;
  }

 

 

   tr td:nth-child(1){
    text-transform: uppercase;
  }
  tr td:nth-child(5) span{
    background: #4DBD74;
    border-radius: 0.313rem;
    padding: 0.2rem;
  }

  tr td:nth-child(5) .pendente{
    background: #E83F5B;
    border-radius: 0.313rem;
    padding: 0.2rem;
  }

 
   tr:nth-child(even){
    background-color: #F8F9F9;

  }


  @media(max-width:716px){
    th, td{
      padding:0.2rem;
    }

  
 }

`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.125rem; 


  button {
    border: none;
    width: 70px;
    /*width: 5%; */
    height: 30px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background: #50BCBA;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin-left: 0.625rem;
     
  &:hover{
    background-color: rgba(80, 188, 186, 0.8);
  }

  span{  
    font-size: 0.875rem;
    color: #FFFFFF;
    font-weight: 500;
  }

}

 .next{
  width: 70px;
  

 }

 .page, .nextPage{
   width: 40px;

   

 }

  
`;


export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  ${props => !props.visible && css `
  display: none;



`}


`;








      
          

        