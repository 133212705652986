import React, { useCallback, useState } from 'react';

import { Link } from "react-router-dom";
import { BiRightArrowCircle } from "react-icons/bi";


import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderSupport from '../../shared/HeaderSupport'

import {
    Filters,
    FilterInputs,
    Search,
    ButtonSearch,
    Content,
    ListThe,
    Table,
    Pagination,
   
} from './styles';


export default function ServiceOrders() {

 

    return (
        <>
          <HeaderSupport />
            
            <Breadcrumb title="Relatórios de Atendimento" paths={[
        { pageName: "Dashboard", link: "/support/dashboard" },
        { pageName: "Relatórios" }
      ]} />
        <Content> 
          <Filters> 
           
            <FilterInputs> 

              <form action="">

              <Search>
                <label htmlFor=""> Atendente </label>
                <select className="">
                  <option   defaultValue="" className="selectDisable">Selecione um atendente</option>
                  <option > Dalania Silva </option>
                  <option > Levir Chianca </option>
                  <option > Jefferson Monteiro  </option>
                </select>
              </Search>

              

              <Search>
                <label htmlFor="" > Protocolo do Atendimento   </label>
               
                 <input type="text"  placeholder="Protocolo do Atendimento"/>

              </Search>


              <Search>
                <label htmlFor="" > CPF/CPJ   </label>
               
                 <input type="text"  placeholder="Cpf do Cliente"/>

              </Search>

             

              <Search>
                <div className="all"> 
                <div>
                <label htmlFor=""> Data Inicial </label>

               
               <input type="date"/>

             
               </div>

               <div>
               <label htmlFor="" className="final"> Data Inicial </label>
               <input type="date" className="final"/>
               </div>


                </div>

               


                
                
                 

              </Search>

              

              </form>
             
             
            </FilterInputs>

            <ButtonSearch>

            <button>
               <span>Buscar</span>
          </button>
        </ButtonSearch>
           
          </Filters>


            <ListThe> 
            <Table> 
              <thead>
               <tr> 
                 <th> Atendente </th>
                 <th> Data </th>
                 <th> Protocolo </th>
                 <th> Duração</th>
                 <th> Relato</th>
                 
               </tr>
              </thead>

              <tbody>
                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 10/02/2021 </td>
                  <td> 102040</td>
                  <td> 10:20</td>
                  <td>  <Link to="/" /> <BiRightArrowCircle />  </td>
                </tr>

                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 10/02/2021 </td>
                  <td> 102040</td>
                  <td> 10:20</td>
                  
                  <td>  <Link to="/" /> <BiRightArrowCircle /> </td>
                </tr>
                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 10/02/2021 </td>
                  <td> 102040</td>
                  <td> 10:20</td>
                  <td> <Link to="/" /> <BiRightArrowCircle />  </td>
                </tr>
                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 10/02/2021 </td>
                  <td> 102040</td>
                  <td> 10:20</td>
                  <td> <Link to="/" /> <BiRightArrowCircle />  </td>
                </tr>
                <tr> 
                  <td> ANA PATRICIA DE LIRA SILVA DUARTE</td>
                  <td> 10/02/2021 </td>
                  <td> 102040</td>
                  <td> 10:20</td>
                  <td> <Link to="/" /> <BiRightArrowCircle />  </td>
                </tr>

                

               
              </tbody>
              
              
             
              
              
            </Table>
           
            <Pagination>

            <button>
                <span>Primeira</span>
              </button>

            <button className="page"> 
              <span>1</span>
            </button>

            
            <button className="nextPage"> 
              <span>&raquo;</span>
            </button>
                

            
            <button className="next"> 
              <span>Última</span>
            </button>
                
                
                
            </Pagination>
            
            
           
           
            </ListThe>
           
          </Content>

          
        </>
    )
}

