import React,{useCallback, useState} from 'react';


import { Link } from "react-router-dom";



import LogoColorful from '~/shared/assets/logo-mgnet.png';
import IconDocumentMenu from '~/shared/assets/documentPagemgcliente.svg';
import { Header,LogoMgnet,Menu} from './styles';


 

export default function HeaderMgcliente() {
  return (
<>
  <Header>
    <LogoMgnet>
      <Link to='/smo' >
        <img src={LogoColorful} alt="Logo branca MGNET"/>
      </Link>
    </LogoMgnet>

    <Menu>
      <li><Link to='/mgcliente' > <img src={IconDocumentMenu} alt=""/> <span> Solicitações</span> </Link></li>
      
    </Menu>
  </Header>
</>
    
  )
  }