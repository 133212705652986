
/**
 * Utiliza o nome do ponto de acesso para descrobrir a tecnologia(fibra, radio ou cabo) que o 
 * cliente usa.
 * 
 * @param {String} accessPoint Nome do ponto de acesso do cliente
 * 
 * @returns A tecnologia que o cliente utiliza (FIBRA, CABO ou RADIO).
 */
export const getTecnology = (accessPoint) => {
    accessPoint = accessPoint.toUpperCase();

    if (accessPoint.includes('OLT')) {
        return 'FIBRA';
    } else if (accessPoint.includes('CABO')) {
        return 'CABO';
    } else {
        return 'RADIO';
    }
}

/**
 * Utiliza a descrição do plano para descobrir seu o cliente é pessoa física, 
 * jurídica ou link dedicado.
 * 
 * @param {String} plan Descrição do plano do cliente
 * 
 * @returns Se o cliente é pessoa física, pessoa jurídica ou link dedicado.
 */
export const getClientType = (plan) => {
    plan = plan.toUpperCase();
    
    if(plan.includes('PJ')) {
        return 'Pessoa Jurídica';
    } else if (plan.includes('DEDICADO')) {
        return 'Link Dedicado';
    } else {
        return 'Pessoa Física';
    }
}