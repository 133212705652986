
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
`
export const Content = styled.div`
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 4px;
`

export const ContainerNameInfo = styled.div`
  flex: 1;
  margin: 0 5px;
  padding-top: 10px;

  display: flex;
  border-bottom: 1px solid #cccccc;
  div {
    padding: 3px 10px;
    font-family: robotoBold;
    color: rgba(51, 51, 51, 0.6);
    cursor: pointer;
  }
  .active {
    border-bottom: 2px solid #1C1F73;
    color: rgba(51, 51, 51, .9);
  }
`
export const ContainerCards = styled.div`
  display: flex;
  width: 100%;
`
export const ContainerConnections = styled.div`
    display: flex;
    flex: 1.5;
    .w-100 {
        flex: 1;
    }
`
export const ContainerConnections2 = styled.div`
    flex: 1;
    height: auto;
    .w-100 {
        flex: 1;
    }
`
export const ContainerData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 5fr;
  color: 333;
  font-family: roboto;

  .green {
    font-family: robotoMedium;
    color: #45B529;
  }
  .blue {
    font-family: robotoMedium;
    color: #1C1F73;
  }
  ul {
    margin-left: 100px;
    list-style: none;
  }
  li {
    margin: 20px 0;
  }
  strong {
    margin-right: 5px;
    font-family: robotobold;
  }
`
export const DataClientOne = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    margin: 30px 0 50px 0;
    color: #495057;
`
export const CustomerInformation = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 37px;
  border-bottom: 1px solid #D0CECE;
  padding: 20px;
`
export const CustomerIcon = styled.div`
  svg{
    color: #1C1F73;
    font-size: 70px;
}
 
`
export const ClientPrimary = styled.div`
  margin-left: 5%;

`
export const NameClient = styled.div`
  text-align: center;
  span{
    color: #1C1F73;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

`
export const DateClient = styled.div`
  font-family: 'Arapey', serif;
  display: flex;
  justify-content: space-between;
  span{
   font-size: 14px;
 }
`

export const DateClientMore = styled.div`
  transition: height 2s;
  ${props => !props.visible && css`
    height: 0;
    overflow: hidden;
` }
`

export const DateClientOthers = styled.div`
  font-family: 'Arapey', serif;
  display: flex;
  display: inline-block;
  p{
    padding-top: 10px;
    padding-right: 78px;
    font-size: 14px;
  }
`
export const ButtonMoreInformation = styled.div`
  display: flex;
  justify-content: center;
  img{
    cursor: pointer;
    margin-right: 18%;
    width: 30px;
    height: 15px;
  }
`

export const ConnectionEquipament = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Cards = styled.div`
  display: flex;

`
export const Card =  styled.div`
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: 5px;
  flex: 1;

`
export const CardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #A6DDDC;
    border-radius: 3px 3px 0px 0px;
    font-size: 1.3rem;
    color: #1C1F73;
    width: 100%;
    padding: 5px 10px;
    .desc {
      margin-left: 4px;
    }
    .sync {
      cursor: pointer;
      &:hover {
        color: green;
      }
    }
`

export const CardContent = styled.div`
  margin: 10px;
  .ppoeStatusOnline, .ppoeStatusPa{
    color: #45B529;
  }
  strong{
    color: initial;
  }
  p{
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400;
    color: #000000;
  }
  p span {
    font-size: 1rem;
    color: #1C1F73;
    font-family: robotobold;
  }
  span.pppoe-status {
      font-family: robotobold;
      font-style: initial;
  }

  span.pppoe-status.online {
      color: #45B529;
  }

  span.pppoe-status.offline {
      color: #D10101;
  }
  article{
    margin-top: 30px;
  }

`
export const  CardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`

export const Button = styled.button`
  background-color: rgba(255, 99, 99, 1);
  border: none;
  box-shadow: 0px 1.16667px 3.5px rgba(0,0,0,0.5);
  border-radius: 4px;
  width: 183px;
  height: 35px;
  font-family: robotobold;
  color: white;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

`

export const CardOlt = styled.div`
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: 5px;
  flex: 1;
  margin-top: 15px;
  height: 97%;
  position: relative;

`
export const CardInformation = styled.div`
  
  border-bottom: 1px solid rgba(196, 196, 196, 0.76);
  h3{
    padding-top: 20px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 1.125rem;

  }
  
`

export const CardImage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  img{
  width:90%;
  height: 70px;
}

`
export const ButtonsAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
 

  .remove{
    background-color: #68C9C7;
  }
  .acessar{
    background-color: #1C1F73;
  }

  .bridge{
    background-color: #45B529;
  }

  .restart{
    background-color: #45B529;
  }
  
  `
  export const ButtonGroup = styled.div`
    display: flex;
    padding: 10px;
  
  `
  export const ButtonOnu = styled.button`
    background-color: rgba(255, 99, 99, 1);
    border: none;
    box-shadow: 0px 1.16667px 3.5px rgba(0,0,0,0.5);
    border-radius: 1.16667px;
    width: 140px;
    height: 28px;
    font-family: robotobold;
    color: white;
    margin-left: 8px;
    outline: none;
    cursor: pointer;
  
  `

export const Grafic = styled.div`
    display: flex;
    padding: 20px;
`

export const DetailsTrafic = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    p {
        color: #1C1F73;
        font-family: robotobold;
        font-size: .8rem;
        margin-bottom: 5px;
    }
    div {
        display: flex;
        flex-direction: column;
        margin-left: .9rem;
        margin-bottom: .6rem;
    }
    span {
        font-size: .9rem;
    }
    strong{
      margin-left: .9rem;

    }
`
export const TitleDetailsTrafic = styled.span`
    color: #1C1F73;
    padding-bottom: 2px;
    
    border-bottom: 5px solid #50bcba;
    border-radius: 5px;
    font-family: robotobold;
    font-size: .8rem;

    
`

export const CardImageGrafic = styled.div`
`