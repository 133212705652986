
/**
 * Captura a hora atual
 * 
 * @returns String no formato H:M, onde H é a hora atual e o M o minuto atual
 */
export function getCurrentFormatedTime() {
    let now = new Date();
    let hours = now.getHours() > 9 ? now.getHours() : '0' + now.getHours();
    let min = now.getMinutes() > 9 ? now.getMinutes() : '0' + now.getMinutes();
    
    return `${hours}:${min}`;
}

export function getHumanFormated(strDate) {
    let date = new Date(strDate)
    let year = date.getFullYear();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let hours = date.getHours()> 9 ? date.getHours() : '0' + date.getHours();
    let min = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();

    return `${day}/${month}/${year} - ${hours}:${min}`;

}

export function getHumanFormatedDate(strDate) {
    let date = new Date(strDate)
    let year = date.getFullYear();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

    return `${day}/${month}/${year}`;

}

/**
 * Cria um intervalo que a cada segundo o mesmo chama a função passada, para atualizar
 * o valor do estado que armazena a hora, matendo assim o estado sempre com a hora
 * atual.
 * 
 * @param {useState} setTime Função que atualiza o valor de um useSate do React.
 * 
 * @returns Um intervalo que é executado a cada 1s que seta a hora atual no estado informado.
 */
export function getIntervalUpdateTime(setTime) {
    return setInterval(function() {
        setTime(getCurrentFormatedTime());
    }, 1000);
}

/**
 * Calcula a diferença em milisegundos entre as datas informadas.
 * 
 * @param {Date} initDate Data inicial
 * @param {Date} finalDate Data final
 */
export function getMillisecondsBetweenDates(initDate, finalDate=new Date()) {
    return finalDate.getTime() - initDate.getTime();
}

/**
 * Calcula as horas e minutos a partir dos milisegundos informado.
 * 
 * @param {Int16Array} miliseconds 
 */
export function formatMillisenconds(miliseconds) {
    let seconds = Math.floor(miliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(seconds / 3600);
    
    // Retirando os segundos que já foram contados nos minutos
    seconds = seconds % 60; 

    // Retirando os mitutos que já foram contados nas horas
    minutes = minutes % 60;

    return ( 
        (hours > 9 ? hours : '0' + hours) + ':' + 
        (minutes > 9? minutes : '0' + minutes) + ':' +
        (seconds > 9 ? seconds : '0' + seconds)
    );
}

/**
 * Calcula quantos minutos compoem os milisegundos informado.
 * 
 * @param {Int} miliseconds Quantidade de milisegundos que serão utilizado no cálculo
 */
export function getMinutes(miliseconds) {
    return Math.floor(miliseconds / 1000 / 60);
}