import React, {useRef, useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-modal';
import { MdClose} from "react-icons/md";

import Input from '~/shared/components/FormValidation/input';
import { Form } from './style';
import {Container, FormRegistration, ModalHeader} from './style';
Modal.setAppElement('#root')

export default function ModalRegistrationSplitter({isOpen, onRequestClose, typeAction}){
  const formRef = useRef(null);

  const handleSubmit = useCallback (async(data, {reset}) =>{

    try{
      const schema = Yup.object().shape({
        splitter: Yup.string().min(3, 'No mínino 3 caracteres')
          .required('O Splitter é Obrigatório'),
      });
      await schema.validate(data, {
        abortEarly: false,
      })

      formRef.current.setErrors({})
        reset();

    }catch(err){
      if(err instanceof Yup.ValidationError ){
        const errorMessages = {

        };

        err.inner.forEach(error =>{
          errorMessages[error.path] = error.message;
        })

        formRef.current.setErrors(errorMessages);

      }
    }

  }, [])
  return(
        <Container>
          <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-city"

          >
            {typeAction==='register' &&
              <>
                <ModalHeader>
                  <div>
                    <button onClick={onRequestClose}> <MdClose /> </button>

                  </div>
            
                  <p> Cadastrar Splitter</p>

                </ModalHeader>
                <Form ref={formRef} onSubmit={handleSubmit}> 
                  <Input type="text" name="splitter" placeholder="Tipo do Splitter" />
            
            
                    <button>Cadastrar</button>

            
              </Form>
              </>
            }

            {typeAction==='update'&&
                <>
                  <ModalHeader>
                    <div>
                      <button onClick={onRequestClose}> <MdClose /> </button>

                    </div>
              
                    <p> Editar Splitter</p>

                  </ModalHeader>
                  <Form ref={formRef} onSubmit={handleSubmit}> 
                    <Input type="text" name="splitter" placeholder="Tipo do Splitter" />
                      <button>Atualizar</button>

              
                </Form>
                </>
                }

        </Modal>

         </Container>


  );
}