import styled,{css} from 'styled-components';

export const Container = styled.div`
 margin-left: 230px;
 color: #495057;
`;
export const SmallCards = styled.div`
  display: flex;
  justify-content: center;
  max-width: 980px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

export const BreadrumDashboard = styled.div`
  margin-top: 4rem;
  margin-left: 230px;
`;

export const CardLocality = styled.div`
  background: #ffffff;
  border-radius: 6px;
  margin: 40px auto;
  width: 100%;
  max-width: 940px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   /* padding-bottom: 15px;  */
  box-sizing: border-box;
  ${props => props.widthCard && css `
    max-width: 450px;
  `}
  max-height: 350px;
  
  
  .tbl-content{
    height:250px;
    overflow-y:auto;
    margin-top: 0px;
    border-top: 1px solid rgba(255,255,255,0.3);


    ::-webkit-scrollbar-track {
    background-color: #dad7d7;
    border-radius: 30px;
    }
    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 30px;
      background: #dad7d7;
    }
    ::-webkit-scrollbar-thumb {
      background: #808080;
      border-radius: 30px;
    }
  }
  table{
    width:100%;
    table-layout: fixed;
    color: #495057;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 15px;
    

  th{
    padding: 10px 15px;
    text-align: left;
    font-family: 'RobotoMedium';
    font-size: .9rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    color: #495057;
    
  }

  td{
    margin: 15px;
    padding-left: 15px;
    line-height: 2rem;
    text-align: left;
    vertical-align:middle;
    font-weight: 400;
    font-size: .875rem;
    font-weight: 400;

    button{
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #33CC95;
    cursor: pointer;
  }
  button:nth-child(2){
    color: #E62E4D;
    margin-left: 5px;
  }
  .buttondelete{
    color: #E62E4D;

  }
    
    
  }
  tbody tr:nth-child(odd){
    background-color: #F1F3FA;
  }

}


  
`;

export const CardTitle = styled.div`
display: flex;
font-size: 1rem;
justify-content: space-between;

h3{
  font-weight: 400;
  margin: 10px;
  margin-top: 8px;
}
svg{
  
  margin-top: 2px;
}

 button{
  background: none;
  border: none;
  outline: none;
  color: #117ADE;
  cursor: pointer;
  font-size: 2rem;
}
`;


export const ModalContent =  styled.div`
  .buttonClose{
      display: flex;
      justify-content: flex-end;
      padding: 0.25rem;
      button{
        cursor: pointer;
        border: 0;
        background: none;
        font-size: 1.2em;
        color: #405057;
        z-index: 1;
  }

  }

  .informationDelete{
   
    p{
      text-align: center;
      margin-top: 20px;
      color: #495057;
      font-size: 1rem;
      font-family: 'RobotoMedium';
    }
   
    
  }

  .buttons{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button{
      cursor: pointer;
    border: none;
    background: #C4C4C4;
    margin: 20px;
    border-radius: 5px;
    color: #FFFFFF;
    width: 90px;
    height: 30px;
    transition: filter 0.2s;

    &:hover{
      filter: brightness(0.9);
    }
  }
  button+button{
    background: #E62E4D;
    
  }
  }
   

`;