import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import Helmet from 'react-helmet';

import api from '~/shared/services/api';

import Spinner from '../../components/Spinner';

import { Container, Content, Description, Form, ButtonEnv, Copyrigth, BackMenu } from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword({ edit=false }) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    console.log(query.get("token"));

    if (edit === false && (query.get("token") === null || query.get("token") === "")) {
      alert("Url inválida");
      history.push('/');
    }
  }, [history, query, edit]);

  const alterPasswordHandle = useCallback(async (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "" || 
      (actualPassword === "" && edit === true)) {
      alert("Por favor, preencha todos os campos abaixo");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      alert("As senhas não conlidem");
      return;
    }
    
    try {

      setIsLoggingIn(true);

      if (edit) {
        await api.put("/profile", { 
          password: newPassword,
          old_password: actualPassword
        });
        alert("Senha alterada com sucesso");

      } else {
        await api.post("/password/reset", {
          token: query.get("token"),
          password: newPassword
        });
        
        alert("Senha resetada com sucesso!");
      }

      history.push("/");

    } catch (error) {
      const errorMessage = error.response?.data?.message;

      alert(errorMessage || "Não foi possível alterar senha. Por favor, tente novamente.")

      setIsLoggingIn(false);
    }
    
  }, [newPassword, confirmNewPassword, edit, history, query, actualPassword]);
  
  return (
    <>
      <Helmet title="Seedot - ResetPassword" />

      <Container>
        <Content>
          <Description>
            <p>Digite nos campos a baixo para criar uma nova senha.</p>
          </Description>
          <Form>
            {edit ? 
              (<div className='form-group'>
                <label>Senha atual:</label>
                <input 
                  required
                  type="password"
                  value={actualPassword}
                  onChange={(e) => setActualPassword(e.target.value)} />
              </div>)
              : ("")
            }
            <div className='form-group'>
              <label>Nova Senha:</label>
              <input 
                required
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <div className='form-group'>
              <label>Confirmar nova Senha:</label>
              <input 
                required
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)} />
            </div>
            <ButtonEnv type="button" onClick={alterPasswordHandle} disabled={isLoggingIn}>
              {isLoggingIn ? (<Spinner size={"1rem"} borderWidth={"0.2rem"} />) : "Redefinir"}
            </ButtonEnv>
            
          </Form>
        </Content>
        <BackMenu>Voltar ao Menu</BackMenu>
        <Copyrigth>© NOC Solutions - 2020-2021</Copyrigth>

      </Container>
    </>
  )
}