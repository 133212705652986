import React, {useCallback, useRef, useState, useEffect} from 'react';
import Collapsible from 'react-collapsible';
import { useHistory, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { IoIosClose } from 'react-icons/io';
import AlertLoading from '~/shared/components/AlertLoading'


import HeaderApi from '../../shared/components/HeaderAPI';
import Input from '~/shared/components/FormValidation/input';
import Select from '~/shared/components/FormValidation/select';
import Checkbox from '~/shared/components/FormValidation/checkbox';
import Breadcrumb from '~/shared/components/Breadcrumb';
import { formatCpfCnpj } from '~/shared/utils/StringUtils';

import api from '~/shared/services/api';

import { 
    SectionTwo,
    Container, 
    Content, 
    SectionOne, 
    Form, 
    Inputs,
    SectionUser, 
    InputLegend, 
    Labels, 
    DivDataUser, 
    TitleSection, 
    ContentSection,
    ContentSectionTwo,
    ContentSectionThree,
    ContCard,
    Card,
    CardTop,
    CardBody,
    CollapsibleDiv,
    ContColapsible,
    CardContent
} from './styles';

const AddUser = ({ edit }) =>  {
    const [permissions, setPermissions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carregando informações");

    const { id } = useParams();
    
    const formRef = useRef(null);

    const history = useHistory();
    
    useEffect(() => {
        setLoadingMessage("Carregando informações");
        setIsLoading(true);

        Promise.all([
            api.get('groups'), 
            api.get('permissions')
            
        ]).then(async response => {
            let allGroups = response[0].data;
            let allPermissions = response[1].data;

            if (edit) {
                try {
                    const user = await api.get(`users/${id}`);
                    
                    formRef.current.setData({
                        name: user.data.first_name,
                        sobrenome: user.data.last_name,
                        cpf: user.data.cpf,
                        user: user.data.username,
                        password: user.data.password,
                        birthday: user.data.birthday,
                        sexo: user.data.gender,
                        isAdmin: user.data.is_admin,
                        isActive: user.data.is_active,
                        email: user.data.email,
                    });

                    const userGroups = user.data.groups.map(group => group.id);

                    allGroups = allGroups.map(group => {
                        if (userGroups.includes(group.id)) {
                            group.chosen = true;
                        }

                        return group;
                    });

                    const userPermissions = user.data.permissions.map(
                        permission => permission.id
                    );

                    allPermissions = allPermissions.map(permission => {
                        if (userPermissions.includes(permission.id)) {
                            permission.chosen = true;
                        }

                        return permission;
                    });
                } catch (error) {
                    alert('Não foi possível recuperar os dados do usuário');
                    history.push('/admin');
                }
            }

            setGroups(allGroups);
            setPermissions(allPermissions);
             
        }).catch(error => {
            alert("Não foi possível recuperar os dados necessários para esta página");
            history.push('/admin');
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [edit, id, history]);
    
    const handleSetCpf = useCallback(event => {
        const input = event.target;

        input.value = formatCpfCnpj(input.value);
    }, []);
    
    const handleSubmit = useCallback(async (data, {reset}) => {
        setLoadingMessage("Aplicando alterações");
        setIsLoading(true);

        try {
            const schema = Yup.object().shape({
                user: Yup.string().min(3, 'No mínino 3 caracteres')
                .required('Este campo é obrigatório'),
                name: Yup.string().min(3, 'No mínino 3 caracteres')
                .required('Este campo é obrigatório'),
                sobrenome: Yup.string().min(3, 'No mínino 3 caracteres')
                .required('Este campo é obrigatório'),             
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const permissionsId = permissions
                .filter(id => id.chosen === true)
                .map(obj => obj.id);

            const groupsId = groups
                .filter(id => id.chosen === true)
                .map(obj => obj.id);
          
            if(edit === false){
                const user = await api.post('users',{
                    first_name:data.name,
                    last_name:data.sobrenome,
                    cpf:data.cpf,
                    username:data.user,
                    password:data.password,
                    permissions: permissionsId,
                    groups: groupsId,
                    birthday: data.birthday,
                    gender: data.sexo,
                    is_admin: data.isAdmin,
                    email: data.email,
                    is_active: data.isActive
                });

                alert("Usuário cadastrado com sucesso");

                history.push(`/admin/users/${user.data.id}/edit`);

                return;
            } else {
                const dataUserEditing = {
                    first_name:data.name,
                    last_name:data.sobrenome,
                    cpf:data.cpf,
                    username:data.user,
                    password:data.password,
                    birthday:data.birthday,
                    gender: data.sexo,
                    email: data.email,
                    permissions: permissionsId,
                    groups: groupsId,
                    is_admin: data.isAdmin,
                    is_active: data.isActive
                }
                
                await api.put(`users/${id}`, dataUserEditing); 
                
                alert("Usuário atualizado com sucesso");
            }
            
            formRef.current.setErrors({})
          
        } catch(err) {
            if (err instanceof Yup.ValidationError) {
                const errorMessages = {

                };    

                err.inner.forEach(error =>{
                    errorMessages[error.path] = error.message;
                })

                formRef.current.setErrors(errorMessages);

                return;
            }

            alert("Error não foi possível aplicar alterações no usuário");
        } finally {
            setIsLoading(false);
        }

      }, [groups, permissions, edit, id, history]);

    
    const handleToggleChosenGroup = useCallback(groupId => {
        const tempgroups = groups.map(group => {
            if (group.id === groupId) {
                group.chosen = group.hasOwnProperty('chosen') ? !group.chosen : true;
            }

            return group;
        });

        setGroups(tempgroups);
    }, [groups]);

    const handleToggleChosenPermission = useCallback(permissionId => {
        const tempPermissions = permissions.map(permission => {
            if (permission.id === permissionId) {
                permission.chosen = permission.hasOwnProperty('chosen') ? !permission.chosen : true;
            }

            return permission;
        });

        setPermissions(tempPermissions);
    }, [permissions]);

    const groupPermissionsByServices = useCallback(() => {
        const lookup = new Map();

        permissions.forEach(permission => {
            const permissionsService = lookup.get(permission.service.name);

            if (permissionsService) {
                permissionsService.push(permission);
            } else {
                lookup.set(permission.service.name, [permission]);
            }
        });

        return Array.from(lookup);
    }, [permissions]);

    return (
        
        <>
        
            <HeaderApi/>

            <SectionOne>
                <div>
                    <Breadcrumb 
                        title={edit? 'Editar Usuário' : 'Adicionar Usuários'}
                       
                        paths={[
                            { pageName: "Painel", link: "/admin" },
                            { pageName: "Usuários" }
                    ]} /> 
                </div> 
            </SectionOne>
            <SectionTwo>
                <Container>                    
                    <Content>
                        
                        <SectionUser>  
                           
                            <Form ref={formRef} onSubmit={handleSubmit} >

                                <ContentSectionTwo>

                                    <div className="todos">
                                        <div className="dataUser">
                                            
                                        </div>

                                        <div className="dataUser">
                                            
                                        </div>
                                    </div>
                                    <div className="todos">
                                        <div className="dataUser">
                                            
                                        </div>
                                        
                                        <div className="dataUser">
                                            
                                        </div>

                                        <div className="dataUser">
                                            
                                        </div>
                                    </div>
                                
                                </ContentSectionTwo>
                            
                        
                                <TitleSection> Dados do Usuário</TitleSection>
                                <ContentSection>
    
                                    <Labels>
                                        <label className="user">Usuário:</label>
                                        <label className="password">Senha:</label>
                                        <label className="email">Email:</label>
                                    </Labels>
                                    <DivDataUser>
                                        <Inputs>
    
                                            <Input 
                                                type="text"
                                                name="user"            
                                            />
    
                                        </Inputs>
                                        <Inputs>
                                            <InputLegend>
                                                <Input 
                                                    type="password"
                                                    name="password"
                                                />
                                                <span>Caso este campo estiver vazio sé enviado uma senha por e-mail.</span>
    
                                            </InputLegend>
                                        
                                            
                                        </Inputs>   
                                        <Inputs>
                                            <Input 
                                                type="email"
                                                name="email"
                                                placeholder="meuemail@provedor.com"
                                            />
                                                            
                                        </Inputs>
                                    </DivDataUser>
                                </ContentSection>

                                <TitleSection> Dados Pessoais</TitleSection>
                                <ContentSectionTwo>

                                    <div className="todos">
                                        <div className="dataUser">
                                            <label>Nome:</label>
                                            <Input 
                                                type="text"
                                                name="name"
                                            />
                                        
                                        </div>
                                        <div className="dataUser">
                                            <label>Sobrenome:</label>
                                            <Input 
                                                type="text"
                                                name="sobrenome"
                                            />
                                        </div>
                                    </div>
                                    <div className="todos">
                                        <div className="dataUser">
                                            <label>CPF:</label>
                                            <Input 
                                                type="text"
                                                name="cpf"
                                                className="small2"
                                                onChange={handleSetCpf}
                                                maxLength={13}
                                            />
                                            
                                        </div>
                                        <div className="dataUser">
                                            <label>Nascimento:</label>
                                            <Input 
                                                type="date"
                                                name="birthday"
                                                className="small2"
                                            />
                                        </div>
                                        <div className="dataUser">
                                            <label>Sexo:</label>
                                            <Select name="sexo" className="small2">
                                                <option className="small2">Selecione o Sexo</option>
                                                <option className="small2" value="F">Feminino</option>
                                                <option className="small2" value="M">Masculino</option>
                                            </Select>
                                        </div>
                                    </div>
                                
                                </ContentSectionTwo>

                                <TitleSection>Permissões</TitleSection>

                                <ContentSectionThree>
                                
                                    <Checkbox 
                                        name="isActive"
                                        className="checkbox"
                                    />
                                    <label>Ativo</label>
                                    
                                </ContentSectionThree>
                                <ContentSectionThree>
                                
                                    <Checkbox 
                                        name="isAdmin"
                                        className="checkbox"
                                    />
                                    <label>Status de Superusuário</label>
                            
                                </ContentSectionThree>

                                <ContentSectionThree>
                                    <p>Setores:</p>
                                </ContentSectionThree>

                                <ContentSectionThree>
                                    <div className="cards">
                                        <div className="card">
                                            <ContCard>
                                                <Card>
                                                    <CardTop>
                                                        <span>Setores</span>
                                                    </CardTop>
                                                
                                                    <CardContent>   
                                                        {groups.map((group)=>(
                                                            <div key={group.id}>
                                                                <Checkbox
                                                                    key={group.id}
                                                                    name={group.name}
                                                                    className="checkbox"
                                                                    label={group.name}
                                                                    checked={group.hasOwnProperty('chosen') ? group.chosen : false}
                                                                    onChange={() => handleToggleChosenGroup(group.id)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </CardContent>
                                                </Card>
                                            </ContCard>
                                        </div>

                                        <div className="card">
                                            <ContCard>
                                                <Card>
                                                    <CardTop>
                                                        <span>Permissões ativas setores</span>
                                                    </CardTop>
                                                
                                                    <CardContent>         
                                                        <ul>
                                                            {groups
                                                            .filter(group => group.chosen)
                                                            .map(group =>
                                                                <li key={group.id}>
                                                                    <div>
                                                                        {group.name}
                                                                    </div>
                                                                    <button onClick={() => handleToggleChosenGroup(group.id)}>
                                                                        <IoIosClose /> 
                                                                    </button>
                                                                </li>
                                                                )}
                                                        </ul>          
                                                    </CardContent>
                                                </Card>
                                            </ContCard>
                                        </div>
                                    </div>
                                </ContentSectionThree>

                                <ContentSectionThree>
                                    <p>Permissões Individuáis:</p>
                                </ContentSectionThree>
                                
                                <ContentSectionThree>
                                
                                    <div className="cards">
                                    
                                        <div className="card">
                                            <ContCard>
                        
                                                <Card>
                                                
                                                    <CardTop>
                                                        <span>Cliente</span>
                                                    </CardTop>
                                                
                                                    <CardBody>
                                                        
                                                        <CollapsibleDiv>
                                                            {groupPermissionsByServices().map(service => (
                                                                <Collapsible trigger={service[0]} triggerTagName='div' key={service}  >
                                                        
                                                                    <ContColapsible>
                                                                        <ul>
                                                                            {service[1].map(permission =>                                                 
                                                                                <li key={permission.id}>
                                                                                    <input  
                                                                                        id={permission.id} 
                                                                                        className='checkbox' 
                                                                                        type='checkbox'
                                                                                        checked={permission.hasOwnProperty('chosen') ? permission.chosen : false}
                                                                                        onChange={() => handleToggleChosenPermission(permission.id)}
                                                                                    />
                                                                                    <label htmlFor={permission.id}>{permission.name}</label>
                                                                                </li>
                                                                            )}
                                                                            
                                                                        </ul>
                                                                    </ContColapsible>
                                                                </Collapsible>

                                                            ))}
                                                        </CollapsibleDiv>
                                                    </CardBody>
                                                </Card>
                                            </ContCard>
                                        </div>

                                        <div className="card">
                                            <ContCard>
                
                                                <Card>
                                                
                                                    <CardTop>
                                                        <span>Permissões ativas do usuário</span>
                                                    </CardTop>
                                                
                                                    <CardContent>
                                                        <div>
                                                            <ul>    
                                                                {permissions
                                                                    .filter(permission => permission.chosen)
                                                                    .map(permission => <p key={permission.id}>{permission.service.name} | {permission.name}</p>)
                                                                }   
                                                            </ul>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </ContCard>
                                        </div>
                                    </div>

                                </ContentSectionThree>
                                
                                <div className="button">
                                    <button type="submit">
                                        Salvar
                                    </button>
                                </div>
                            </Form>
                        </SectionUser>
                    </Content>
                </Container>
            </SectionTwo>

            <AlertLoading 
                msg={loadingMessage} 
                isVisible={isLoading} />
        </>
    )
}

export default AddUser;
