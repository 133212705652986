import { Switch } from "react-router-dom";

import Route from './Route';

import Login from '~/shared/pages/Login';
import ResetPassword from '~/shared/pages/redefinePassword';
import Home from '~/shared/pages/SysSelect';

import Users from '~/modules/api_gateway/pages/init_users';
import AddUser from '~/modules/api_gateway/pages/addUser';

import Connections from '~/modules/support/pages/Connections';
import ConfirmData from '~/modules/support/pages/ConnectionDetails';
import Dashboard from '~/modules/support/pages/Dashboard';
import Reports from '~/modules/support/pages/Reports';
import SelectedReports from '~/modules/support/pages/SelectedReport';

import SearchClient from '~/modules/smo/pages/SearchClient';
import SearchOlt from '~/modules/smo/pages/SearchOlt';
import SearchOthers from '~/modules/smo/pages/SearchOthers';
import ConnectionDetails from '~/modules/smo/pages/ConnectionDetails';
import AllocateOnu from '~/modules/smo/pages/AllocateOnu';
import SearchAlloc from '~/modules/smo/pages/SearchAlloc';

import Requests from '~/modules/mgcliente/pages/Requests';
import DetailsRequests from '~/modules/mgcliente/pages/DetailsRequests';
import DashBoardMgMaps from '~/modules/mgmaps/pages/DashboardMgMaps';
import settingsMgMaps from '~/modules/mgmaps/pages/Settings';

export default function Routes() {
  return (
    <Switch>
      {/* Support */}
      <Route path="/support/connections" exact={true} component={Connections} isPrivate={true} />
      <Route path="/support/connections/details" component={ConfirmData} isPrivate={true} />
      <Route path="/support/dashboard" component={Dashboard} isPrivate={true} />
      <Route path="/support/reports" component={Reports} isPrivate={true} />
      <Route path="/support/selected" component={SelectedReports} isPrivate={true} />

      {/* Api gateway */}
      <Route path="/admin" exact={true} component={Users} isPrivate={true}/>
      <Route path="/admin/users/add" exact={true} component={() => <AddUser edit={false} />} isPrivate={true} />
      <Route path="/admin/users/:id/edit" exact={true} component={() => <AddUser edit={true} />} isPrivate={true} />

      {/* SMO */}
      <Route path="/smo" exact={true} component={SearchClient} isPrivate={true} />
      <Route path="/smo/olt" exact={true} component={SearchOlt} isPrivate={true} />
      <Route path="/smo/others" exact={true} component={SearchOthers} isPrivate={true} />
      <Route path="/smo/connections/details" exact={true} component={ConnectionDetails} isPrivate={true} />
      <Route path="/smo/onuallocated/add" exact={true} component={AllocateOnu} isPrivate={true} />
      <Route path="/smo/onuallocated" exact={true} component={SearchAlloc} isPrivate={true} />

      {/* MG Cliente */}
      <Route path="/mgcliente" exact={true} component={Requests} isPrivate={false} />
      <Route path="/mgcliente/details/requests" exact={true} component={DetailsRequests} isPrivate={false} />

      {/* MG MAPS */}
      <Route path="/mgmaps" component={DashBoardMgMaps} isPrivate={true} />
      <Route path="/settings" component={settingsMgMaps} isPrivate={true} />
      
      {/* Global Routes */}
      <Route path="/" exact={true} component={Login} />
      <Route path="/reset-password" exact={true} component={ResetPassword} />
      <Route path="/alter-password" exact={true} component={() => <ResetPassword edit={true} />} isPrivate={true} />
      <Route path="/home" exact={true} component={Home} isPrivate={true} />
      <Route component={() => <h1>Error: 404</h1>} />
    </Switch>
  );
}