import styled,{css} from 'styled-components';





export const Content = styled.div`
margin: 1.125rem auto;



 
`;


export const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 0.313rem;
 


`;

export const InformationClient = styled.div`
width: 100%;
color: #495057;


`;


export const TitleInformation = styled.div`
  padding: 1.125rem;
  width: 100%;
  flex: 1;
 

  
  
  h3{
    font-size: 1rem;
    padding: 0.5rem;
    background-color: rgba(80, 188, 186, 0.51);
    
    border-radius: 4px 4px 0px 0px;
  
  }

`;

export const Table = styled.table`
flex: 1;
width: 100%;
padding: 1.25rem;
font-size: 1rem;

tr{
  flex: 1;
  display: grid;
  gap: 12.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 2fr)); 


}

@media(max-width:620px){
  tr{
    gap: 1.125rem;

  }

}

td{
  
  
  padding: 0.625rem;
  font-size: 1rem;
  font-weight: 400;
}
 

    
`;






export const Cards= styled.div`
  padding: 1.125rem;
  





`;
export const Card = styled.div`
  background: #F8F9FA;
  display: flex; 
  gap: 2.5rem;
  padding: 1.125rem;
  flex-wrap: wrap;
  justify-content: space-evenly;


`;


export const CardTitle= styled.div`
  background:rgba(80, 188, 186, 0.51);
  border-radius: 4px 4px 0px 0px;
  padding: 0.5rem;
  
  display: flex;
  flex: 1;

  font-size: 1rem;

h3{
  font-size: 1rem;
  padding-left: 0.5rem;
  


}

`;

export const CardImage = styled.div`
background: #F8F9FA;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 80%;
max-width: 31.25rem;
margin: 0 auto;

img{
  padding-top: 1.25rem;
  border-radius: 0.25rem;
  width: 90%; 
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  cursor: pointer;

  &:hover{
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(0.9);
}
  


}

&:hover .botoes{
  display: flex;
  justify-content: flex-end;
  padding: 0.8rem;

  svg{
    font-size: 1rem;
  }
 
}


button{
  background:rgba(80, 188, 186, 0.51);
  border: none;
  padding: 0.625rem;
  cursor: pointer;
  border-radius: 0.25rem;
  outline: none;

}

.botoes{
  
  
  display: none;
  
  
}


`;






/* MODAL */

export const ModalOverlay = styled.div`

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  
  ${props => !props.visible && css`
  display: none;
  
  
  `}




`;

export const Modal = styled.div`
  border-radius: 0.313rem;
  background: #FFFFFF;
  width: 40%;
  color:#495057;

  margin: 2.5rem auto;
  padding-bottom: 2.5rem;

  ${props => !props.visible && css `
  display: none;
  
  `}


  @media(max-width:1800px){
    width: 60%;
    

  }

  @media(max-width:980px){
    width: 100%;
    

  }

  @media(max-width:1400px){
    width: 100%;
    

  }

  p{
    display: flex;
    flex: 1;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    
  }
  p span:last-child{
    margin-left: 0.625rem;
  }

`;
export const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.375rem;
  
  cursor: pointer;
  color: #1C1F73;

  button{

    outline: none;
    margin-right: 0.2rem;

    border: none;
    background-color: white;
    cursor: pointer;
  }
  svg{
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 20%;
    color: #495057;
  }

  ${props => !props.visible && css `
    display: none;
  
  `}

`;

export const RotateImage = styled.div`

  display: flex;
  justify-content: flex-end;
  
  
  cursor: pointer;
  color: #1C1F73;

  button{
    margin-top: 4rem;

    outline: none;
    margin-right: 1rem;

    border: none;
    background-color: white;
    cursor: pointer;
  }
  svg{
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 20%;
    color: #495057;
  }

`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.125rem;

  img{
    width: 90%;
    max-width:370px;
    height: 70%;

    ${props => props.rotate && css `
    transform: rotate(${props.valueRotate});
  
  `}
  }

  @media(max-width:1800px){
    img{
    width: 50%;
    max-width:300px;
    }


  }
  
  
`;



    


    




      
          

        