import { useState } from 'react';

import Maps from '../../components/Maps';
import CardsMgMaps from '../../components/CardsMgMaps';
import Graphics from '../../components/Graphics';
import {Container, BreadrumDashboard} from '~/modules/mgmaps/pages/DashboardMgMaps/style';


export default function District() {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [grapichIsVisible, setGrapichIsVisible] = useState(false);

    function handleShowGrapich(){
        setGrapichIsVisible(!grapichIsVisible);
    }

    function handleOpenModal(){
        setModalIsOpen(true);
    }

    return (
    
    <Container>
        <CardsMgMaps />
        {grapichIsVisible &&
         <Graphics />
        }
        <Maps />
    </Container>

    )
}