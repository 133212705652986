import { useState, useEffect } from 'react';

import {SideBarLink, SideBarLabel, DropdownLink, SideBar} from './style';

export default function Submenu({item, selectedDistricts, onChangeCheckBox}){

const [subnav, setSubnav] = useState(false);
const [onCheckBox, setOnCheckBox] = useState(false);


useEffect(() => {
  
  item.districts.map((district) => {
    if (selectedDistricts[district.id] || false) {
      setOnCheckBox(true);
    }
  }
  )
}, [setOnCheckBox, selectedDistricts, item.districts])


const showSubnav = () => setSubnav(!subnav);

  return(
    <SideBar>

      <SideBarLink className={onCheckBox ? "active" : ""} onClick={item.id && showSubnav}>
        <div>
          <SideBarLabel> <p key={item.id}> {item.name} </p> </SideBarLabel> {/* aqui vai colocar o endpoint das cidades */}
        </div>
        <div>
          {item && subnav ? item.iconOpened : item.iconClosed } {/* Icones de abrir e fechar o menu seta */}
        </div>
      </SideBarLink>
        
      {subnav && 
          <DropdownLink>
            <SideBarLabel> {/* aqui vai ficar as localidades das cidades*/}
              
            {item.districts.map(districtCity =>(
              <form method="post" key={districtCity.id}>
                
                  <input 
                    type="checkbox"
                    name={districtCity.id}
                    onChange={onChangeCheckBox}
                    checked={selectedDistricts[districtCity.id] || false}
                  />
                  <label  htmlFor={districtCity.id}>{districtCity.name}</label>
                
              </form>

            ))}
            </SideBarLabel> 
          </DropdownLink>
      }
    </SideBar>

  );

}