import styled from 'styled-components';

export const Container= styled.main`
  margin: 20px 0;
  width: 95%;
  max-width: 1151px;
  position: relative;

  .leaflet-container {
  
    height: 65vh;
    z-index: 0;
  }
  .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: none;
  }
  .map-popup .leaflet-popup-content h3 {
  color: #0089a5;
  font-size: 1rem;
  font-weight: bold;
  margin: 2px 0px;
  }
  .map-popup .leaflet-popup-content p {
  color: #606060;
  font-size: 11px;
  font-weight: 500;
  margin: 5px 0;
  }
  .map-popup .leaflet-popup-content span {
  color: #042f38;
  font-size: 11px;
  font-weight: 700;
  }
  .map-popup .leaflet-popup-tip-container {
  display: none;
}
`;

export const LegendMap = styled.div`
  margin-left: .4rem;
  position: absolute;
  z-index: 1000;
  bottom: 5px;
  left: 10px;
`;

export const Ul = styled.ul`
display: flex;
`;

export const Li = styled.li`
  display: flex;
  list-style-type: none;
  font-size: 0.8rem;
  color: #495057;
  padding: .8rem;
  z-index: 1;
  
  div {
    border-radius: 50%;
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
  .red {
    background-color: red;
  }
  .yellow {
    background-color: yellow;
  }
  .green {
    background-color: green;
  }
  .orange {
    background-color: orange;
  }
  .gray {
    background-color: gray;
  }
`;

export const MapsSearch = styled.div`
  top: 10;
  left: 50;
  position: absolute;


  form.landing-page-form {
    padding: 20px 40px;
    top: 10px;
    left: 800px;
    position: absolute;

    z-index: 1;

    input{
      padding-left: 0.25rem;
      border: none;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      height: 35px;
      width: 15rem;
    }
  }
`;