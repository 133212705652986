import styled from 'styled-components';



export const Header = styled.div`
  display: flex;
  height: 60px;
  background-color: #F8F9FA;
  align-items: center;
  padding: 0 20px;

`
export const LogoMgnet = styled.div`

  img{
    width: 79px;
    height: 30px;
  }

`
export const Menu = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  a{
    display: flex;
    align-items: center;
    padding-left:  41px;
    text-decoration: none;
    font-size: 1rem;
    color: #495057;
  }
  a:hover {
    color: #1C1F73;
  }

  span{
    margin-left: 8px;
  }

`
