import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1300px;
  min-width: 835px;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 10px 20px;
`

export const AddOnu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #A6DDDd;
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: .5s;
  &:hover {
    background: #50BCBA;
    transition: .5s;
  }

  svg {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: #000;
  }

`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: #33CC95;
    }}
`;

export const CardTable = styled.div`
  background-color: #f6f6f6;
  width: 800px;
  min-height: 400px;

  margin: 30px;
  padding: 20px;
  position: relative;

  box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
`;

export const HeaderTable = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: left;

  background-color: #A6DDDC;
  width: 100%;
  height: 39px;
  padding: 10px;

  i {
    font-size: 1.4rem;
    margin-right: 10px;
  }
`;

export const AllocList = styled.div`
  margin-top: 22px;
  display: flex;
    thead {
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }
    table {
      border-collapse: collapse;
      flex:1;
      text-align: left;
    }
    td:last-child {
      text-align: right;
    }
    th:last-child {
      text-align: right;
    }
    svg {
      margin-right: 10px;
      color: red;
      filter: opacity(.7);
      font-size: 1.1rem;
      &:hover {
        filter: opacity(1);
      }
    }
    th {
      font-family: Robotobold;
      padding: 10px 25px 0 25px;
    }

    td {
      color: #495057;
      padding: 10px 25px;
      
      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }

    tbody tr:nth-child(odd){
        background-color: #F1F3FA;
        }
`;