import React from 'react';
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from '../../shared/hooks/auth';

function Router({ 
  isPrivate = false, 
  permissions=[], 
  component: Component, 
  ...rest 
}) {
  const { refreshUser, hasPermission } = useAuth();

  const { user } = refreshUser();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {

        if (!!user) {
          if (!permissions.length || hasPermission(permissions)) {
            return <Component />;
          } else {
            alert("Seu usuário não possui permissão para acessar essa página");
            
            return <Redirect to={{ pathname: '/home' }} />
          }
        } else if (isPrivate) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }} />
          );
        } else {
          return <Component />;
        }
      }}
    />
  )
}

export default Router;