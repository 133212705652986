import styled, { css } from 'styled-components';

export const NavDark = styled.nav`
  display: flex;
  box-sizing: border-box;
  background: rgba(7, 92, 133, 0.76);
  padding: 5px;
`

export const Logo = styled.div `
img{
    margin: 5px 20px ;
    height:40px;
  }
`

export const DivIcons = styled.div`
  display: flex;
  justify-content: right;
  margin-left: auto;
` 

export const IconTheme = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 40px;
  }
`

export const IconDiv = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;


  div{
    ${(props) => props.visible ? 
      css`display: flex;`:
      css`display: none;`
    }
    position:absolute;
    top: 50px;
    right:20px;
    width: 160px;
    flex:1;
    border-radius: 4px;
    flex-direction: column;
    background:#FFF;

  }

  button{
    padding: 10px;
    margin: 0;
    
    border: none;
    width: auto;
    overflow: visible;
    cursor: pointer;


    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;
    text-align: inherit;
    outline: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;
    &::-moz-focus-inner {
    border: 0;
  }
}
  button:hover {
    background-color: rgba(7, 92, 133, 0.76);
  }
  svg{
    width:42px;
    height:42px;
    color: #FFFAF0;
    padding-right: 20px;
  }
`
export const Menu = styled.div`
  position: absolute;
`

