import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
`;

export const Content = styled.div`
  width: 700px;
  height: 410px;
  background-color: #fff;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  color: #495057, 100%;

  margin: 50px 0 20px 0;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0 20px;
  width: calc(85% + 40px);
  
  div{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }
  div.form-group {
    width: 100%;
  }
  
  label{
    font-size: .8rem;
    color: #495057, 100%;
  }
  
  input {
    height: 40px;
    background: #F8F9FA;
    border: none;
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 0 5px;
    font-size: 1rem;
  }
  
  .b{
    display: flex;
    justify-content: center;
  }
`;

export const ButtonEnv = styled.button`
  border: 0;

  background: #006ADD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 20px;
  color: white;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
    &:hover {
      transition: 1s;
      background: #50BCBA;
      color: black;
    }
`

export const BackMenu = styled.div`
  display: flex;
  justify-content: center;
`

export const Copyrigth = styled.h3`
  position: absolute;
  bottom: 30px;
`