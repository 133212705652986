import React from 'react';
import { Container, Onu } from './styles';
import OnuImg from '~/shared/assets/onu.png';

function EquipmentImg({ signal, equipmentType, connected, portStatus }) {
    return (
        <Container>
            <Onu version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 553 126.9">
                <image id="image0" width="553" height="126.6" x="0" y="0" href={OnuImg}></image>

                <rect x="454" y="35" width="65" height="46.5" fill="white" fillOpacity="0.9"></rect>

                <rect x="100.7" y="31" width="40.5" height="49" fill={connected ? "green" : "red"} fillOpacity="0.5"></rect>

                <g>
                    <svg x="158" y="35.5">
                        <path d="M20.9083 5.55593V1H37.4771V5.55593H44.0275V12.5164H55.3303V20.7424H57V34.4102H55.3303V42.7627H3.05505V34.4102H1V20.7424H3.05505V12.5164H14.6147V5.55593H20.9083Z" fill="green" fillOpacity="0.7"></path>
                    </svg>
                    <svg x="223" y="35.5">
                        <path d="M20.9083 5.55593V1H37.4771V5.55593H44.0275V12.5164H55.3303V20.7424H57V34.4102H55.3303V42.7627H3.05505V34.4102H1V20.7424H3.05505V12.5164H14.6147V5.55593H20.9083Z" fill="green" fillOpacity="0.7"></path>
                    </svg>
                    <svg x="292.5" y="35.5">
                        <path d="M20.9083 5.55593V1H37.4771V5.55593H44.0275V12.5164H55.3303V20.7424H57V34.4102H55.3303V42.7627H3.05505V34.4102H1V20.7424H3.05505V12.5164H14.6147V5.55593H20.9083Z" fill="green" fillOpacity="0.7"></path>
                    </svg>
                    <svg x="357.5" y="35.5">
                        <path d="M20.9083 5.55593V1H37.4771V5.55593H44.0275V12.5164H55.3303V20.7424H57V34.4102H55.3303V42.7627H3.05505V34.4102H1V20.7424H3.05505V12.5164H14.6147V5.55593H20.9083Z" fill="green" fillOpacity="0.7"></path>
                    </svg>
                </g>

                <g fontSize="24" fontFamily="sans-serif" fill="#dc3545" textAnchor="middle">
                    <text x="487" y="67">{Number((signal || 0)).toFixed(1)}</text>
                </g>
            </Onu>
        </Container>
    );
}

export default EquipmentImg;