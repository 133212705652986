import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { BiSearchAlt } from 'react-icons/bi';

import Breadcrumb from '~/shared/components/Breadcrumb';
import { formatCpfCnpj } from '~/shared/utils/StringUtils';
import HeaderSMO from '../../components/HeaderSMO';
import SearchButton from '../../components/SearchButton';
import api from '~/shared/services/api';

import { Container, Content, SearchCustomer, Search, SecondContent, Table } from './styles';

export default function SearchClient() {
    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [user, setUser] = useState("");
    const [contractNumber, setContractNumber] = useState("");
    const [connectionsSynsuite, setConnectionsSynsuite] = useState([]);
    const [recoveringConnections, setRecoveringConnections] = useState(false);

    const history = useHistory();

    /**
     * Recupera da API do synsuite as conexões que passaram no filtro dos 
     * dados informados pelo usuário.
     */

    /**
     * Leva o usuário para tela de Detalhes do equipamento.
     */
    function goEquipment(connectionId) {
        sessionStorage.clear()

        history.push({
            pathname: 'smo/connections/details',
            state: { connectionId, previousPage: "SearchClient" }
        });
    }

    const handleSetCpf = (value) => {
        setCpf(() => {
            value = value.replace(/\D/g, "")

            if (value.length <= 11) {
                if (value.length <= 6) {
                    return value.replace(/(\d{3})(\d)/, "$1.$2");
                } else if (value.length <= 9) {
                    return value.replace(/(\d{3})(\d{3})(\d)/, "$1.$2.$3");
                } else {
                    return value.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4");
                }
            } else {
                if (value.length <= 12) {
                    return value.replace(/(\d{2})(\d{3})(\d{3})(\d)/, "$1.$2.$3/$4")
                }
                return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, "$1.$2.$3/$4-$5")
            }
        })
    };

    const getConnections = useCallback(() => {
        if (recoveringConnections) {
            return;
        }

        setRecoveringConnections(true);

        let paramsSearch = {
            'page': 1,
            'limit': 25
        };

        if (user.length > 2) {
            paramsSearch['user'] = user;
        }
        if (name.length > 2) {
            paramsSearch['client_name'] = name;
        }
        if (cpf.length > 2) {
            paramsSearch['cpf'] = cpf.replace(/\D/g, "");
        }
        if (contractNumber.length > 2) {
            paramsSearch['contract_number'] = contractNumber;
        }

        api
            .get('/my_synsuite/api/v2/connections/', {
                params: paramsSearch,
                timeout: 4000
            })
            .then(function (response) {
                setConnectionsSynsuite(response.data);
            })
            .catch(function (error) {
                console.log(error.response);
                alert("Não foi possível recuperar as conexões");
            })
            .finally(function () {
                setRecoveringConnections(false);
            });
    }, [contractNumber, cpf, name, user, recoveringConnections]);

    const handleGetConnections = e => {
      e.preventDefault();
      getConnections();
    };

    useEffect(() => {
      getConnections();
    }, [contractNumber, cpf, name, user]);

  return (
    <>
      < HeaderSMO />

      <Container>
        <Breadcrumb title="Clientes" paths={[
          { pageName: "SMO", link: "/smo" },
          { pageName: "Clientes" }
        ]} />


        <Content>
          <SearchCustomer>
            <h2> PESQUISAR CLIENTE </h2>
            <form>
              <Search>
                <label htmlFor=""> Nome </label>
                <input className="name" type="text" placeholder="Nome do Cliente" value={name} onChange={(event) => { setName(event.currentTarget.value); }}/>
              </Search>

              <Search>
                <label htmlFor=""> CPF/CNPJ</label>
                <input type="text" placeholder="CPF/CNPJ" value={cpf} onChange={(event) => { handleSetCpf(event.currentTarget.value); }} />
              </Search>

              <Search>
                <label htmlFor=""> Conexão</label>
                <input type="text" placeholder="Usuário pppoe" value={user} onChange={(event) => { setUser(event.target.value); }} />
              </Search>

              <Search>
                <label htmlFor=""> n° Contrato</label>
                <input type="text" placeholder="Número do Contrato" value={contractNumber} onChange={(event) => { setContractNumber(event.target.value); }} />
              </Search>

              <Search>
                <label>&nbsp;</label>
                <SearchButton onClick={handleGetConnections} />
              </Search>

            </form>

          </SearchCustomer>
        </Content>

        <SecondContent>
          <Table>
            <thead>
              <tr>
                <th className="name"> Nome Completo </th>
                <th className="date"> Dados </th>
                <th colSpan="2"> Endereço </th>
              </tr>
            </thead>

            <tbody>
            {connectionsSynsuite.map((connection) => {
                return (
                  <tr key={connection.id}>
                      <td className="nameClient">
                        {connection.client_name}
                      </td>
                      <td>
                        <ul className="dateClient">
                          <li> <span> <strong>CPF/CNPJ:</strong>  {formatCpfCnpj(connection.client_cpf)}</span> </li>
                          <li> <span> <strong>E-mail:</strong>  {connection.client_email}</span> </li>
                          <li> <span> <strong>Conexão: </strong>  {connection.user}</span> </li>
                        </ul>
                      </td>

                      <td>
                        <ul className="dateClient">
                          <li> <span> <strong>Rua:</strong>   {connection.street}, {connection.number} </span></li>
                          <li> <span> <strong>Bairro:</strong>   {connection.neighborhood} </span></li>
                          <li> <span> <strong>Cidade:</strong>   {connection.city} </span></li>
                        </ul>
                      </td>

                      <td>
                        <ul className="dateClient">
                          <li className="liIcon"> 
                            <button onClick={() => goEquipment(connection.id)} > 
                              <BiSearchAlt />
                            </button>
                          </li>
                        </ul>
                      </td>
                  </tr> 
                );
             })}
             </tbody>
          </Table>
        </SecondContent>
      </Container>



    </>

  )
}