import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 2px;
  background-color: #ECEDEF;

  p{
    color: #495057;
    font-weight: 400;
    font-size: 1.75rem;
  }

  ul {
    display: flex;
    margin-top: 10px;
  }

  li {
    list-style-type: none;
    font-size: 0.875rem;
    font-weight: 400;
    color: #48494E;
    padding-left: 4px;
  }

  li:first-child {
    padding-left: 0px;
  }

  a {
    text-decoration: none;
    color: #48494E;
  }

  a:hover {
    text-decoration: underline;
  }
`