import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px 20px;

`;


export const Cards = styled.div`
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  overflow-y: auto;
  background-color: #FFFFFF;
  border-radius: 3px;
  box-sizing: border-box;
  height: 25rem;
  width: 500px;
  max-width: 600px;

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #50BCBA;
  }
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #D0CECE;
  }
`;

export const CardTitle = styled.div`
  background-color: rgba(80, 188, 186, 0.51);
  border-radius: 3px 3px 0px 0px;
  display: flex;
  justify-content: left;
  padding: 15px 10px;
  flex-wrap: wrap;
  box-sizing: border-box;
  

  h2{
    margin-left: 8px;
    color: #48494E;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    font-family: 'RobotoMedium';
}

svg{
  color: #495057;
  font-size: 1.125rem;
}
`;

export const  CardContent = styled.div`

`;
    
export const Table = styled.table`
  font-size: 1rem;
  flex: 1;
  border-collapse: collapse;
  box-sizing: border-box;
  font-weight: 700;
  overflow:hidden;
  width: 100%;



  thead{
    color: #1C1F73;
    
  }
  th,td{
    text-align: left;
    padding: 10px;
  }
  th{
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  }
  td{
    font-weight: 400;
    color: #495057;
    font-size: .9rem;
    font-family: 'RobotoMedium';


  }
  
  tr:nth-child(odd) {
    background-color: #F8F9FA;
  }
  tr td:nth-child(1){
    text-transform: uppercase;
  }
`;

    
   