import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FaWifi, FaSyncAlt, FaNetworkWired, FaServer, FaChartBar } from "react-icons/fa";

import api from '~/shared/services/api';
import smo from '~/shared/services/smo';

import HeaderSMO from '../../components/HeaderSMO';
import Breadcrumb from '~/shared/components/Breadcrumb';
import AlertLoading from '~/shared/components/AlertLoading';
import TrafficChart from '~/shared/components/TrafficChart';
import EquipmentImg from '~/modules/support/pages/ConnectionDetails/components/EquipmentImg';

// import ButtonMoreDown from '~/shared/assets/arrow.svg';

import { formatCpfCnpj } from '~/shared/utils/StringUtils';
import { getClientType, getTecnology } from '../../../support/shared/utils/ConnectionUtils';

import {
    NameClient,
    Container,
    ConnectionEquipament,
    Cards,
    Card,
    CardTitle,
    CardContent,
    CardButton,
    Button,
    CardOlt,
    ContainerConnections,
    ContainerConnections2,
    CardInformation,
    CardImage,
    ButtonsAction,
    ButtonOnu,
    ButtonGroup,
    Grafic,
    DetailsTrafic,
    TitleDetailsTrafic,
    CardImageGrafic,
    ContainerCards,
    ContainerNameInfo,
    Content,
    ContainerData,
    DataClientOne
} from './styles';
import SearchClient from '../SearchClient';

function ConnectionDetails({ connectionId = null, onuSerial = null, previousPage = null }) {
    const sessionStoragePrefixKey = `seedot-support@${connectionId}/`;

    const [connectionData, setConnectionData] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}connection`) || '{}')
    );
    const [equipment, setEquipment] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}equipment`) || '{}')
    );
    const [equipmentOpticalInfo, setEquipmentOpticalInfo] = useState({});
    const [pppoe, setPPPoE] = useState(
        JSON.parse(sessionStorage.getItem(`${sessionStoragePrefixKey}pppoeConnection`) || '{}')
    );
    const [recoveringPPPoEData, setRecoveringPPPoEData] = useState(false);
    const [recoveringEquipment, setRecoveringEquipment] = useState(false);
    const [recoveringConnectionData, setRecoveringConnectionData] = useState(false);
    const [disconnectingPPPoE, setDisconnectingPPPoE] = useState(false);
    const [runningEquipmentAction, setRunningEquipmentAction] = useState(false);
    const [runningEquipmentActionMsg, setRunningEquipmentActionMsg] = useState("");


    const [pppoeTrafficHistory, setPPPoETrafficHistory] = useState([]);

    // const [image, setImage] = useState(ButtonMoreDown);
    // const [contentVisible, setContentVisible] = useState(false);


    const getPPPoEData = useCallback(async (connectionDataTemp) => {
        const connection = connectionDataTemp || connectionData;

        if (recoveringPPPoEData) {
            return;
        }

        setRecoveringPPPoEData(true);

        try {
            const { data } = await api.get(`smo/api/pppoe/status/`, {
                params: { pppoe_user: connection.user },
                timeout: 10000
            });

            setPPPoE(data);
            setRecoveringPPPoEData(false);
            console.log(data);
            return data;
        } catch (error) {
            alert("Ops, Não foi possível recuperar dados da conexão PPPoE");
            setRecoveringPPPoEData(false);
        }
    }, [connectionData, recoveringPPPoEData]);


    const getPPPoETraffic = useCallback(async () => {
        if (!connectionData.user) {
            console.log(connectionData)
            console.log('without connection user');
            return;
        }

        try {
            const { data } = await api.get('smo/api/pppoe/traffic/', {
                params: { pppoe_user: connectionData.user },
                timeout: 10000
            });

            setPPPoETrafficHistory(pppoeTrafficHistory => [...pppoeTrafficHistory, data]);
            console.log(data);
            return data;
        } catch (error) {
            console.log('error')
            return null;
        }
    }, [connectionData])


    const getEquipment = useCallback(async ({ connectionData: connectionDataTemp = null, onuSerial = null }) => {
        let connection;
        let params;

        if (onuSerial) {
            connection = { tecnology: 'FIBRA' };
            params = { serial: onuSerial };
        } else {
            connection = connectionDataTemp || connectionData;
            params = { connection_id: connectionId };
        }

        // Recuperando dados do equipamento utilizado na conexão
        if (recoveringEquipment) {
            return;
        }
        setRecoveringEquipment(true);

        if (connection.tecnology === 'FIBRA') {
            try {
                var equipmentResponse = await api.get('smo/api/onus/', { params });
            } catch (error) {
                if (error.response) {
                    alert(error.response.data.msg);
                } else {
                    alert("Não foi possível recuperar equipamento da conexão");
                }
                setRecoveringEquipment(false);
                return;
            }

            if (!equipmentResponse.data.length) {
                alert("Não foi possível recuperar equipamento da conexão");
                setRecoveringEquipment(false);
                return;
            }

            console.log(equipmentResponse.data[0]);

            let equipmentData = {
                tecnology: connectionData.tecnology,
                ...equipmentResponse.data[0]
            };

            setEquipment(equipmentData);

            // Recuperando dados ópticos da ONU
            const equipmentId = equipmentResponse.data[0].id;

            try {
                var equipOpticInfoResp = await api.get(`smo/api/onus/${equipmentId}/optical_info/`, { timeout: 10000 });
            } catch (error) {
                
                const errorOpticalEquipment = "Não foi possível recuperar dados opticos do equipamento da conexão. " + error.response?.data?.msg || "";
            
                alert(errorOpticalEquipment);
                
                setRecoveringEquipment(false);
                return;
            }

            console.log(equipOpticInfoResp.data);

            equipmentData = {
                ...equipmentData,
                ...equipOpticInfoResp.data
            };

            setEquipment(equipmentData);
            setEquipmentOpticalInfo(equipOpticInfoResp.data);
        }

        setRecoveringEquipment(false);
    }, [connectionData, connectionId, recoveringEquipment]);


    const getConnectionData = useCallback(async () => {
        // Recuperando dados da conexão
        if (recoveringConnectionData) {
            return;
        }
        setRecoveringConnectionData(true);

        try {
            var connectionResponse = await api.get(`my_synsuite/api/v2/connections/${connectionId}`)
        } catch (error) {
            if (error.response) {
                alert(error.response.msg);
            } else {
                alert("Não foi possível recuperar dados da conexão");
            }

            setRecoveringConnectionData(false);
            return;
        }

        const tecnology = getTecnology(connectionResponse.data.pe);
        const clientType = getClientType(connectionResponse.data.plan_description);


        setConnectionData({
            tecnology,
            clientType,
            ...connectionResponse.data,
        });

        setRecoveringConnectionData(false);

        console.log({
            tecnology,
            clientType,
            ...connectionResponse.data,
        })

        return {
            tecnology,
            clientType,
            ...connectionResponse.data,
        }
    }, [connectionId, recoveringConnectionData]);

    const handleGetEquipment = () => {
        getEquipment(connectionData.tecnology)
    }

    const handleDisconnectPPPoE = async () => {
        setDisconnectingPPPoE(true);

        try {
            await smo.post(
                'pppoe/disconnect/',
                { pppoe_user: connectionData.user },
                { timeout: 5000 }
            );
        } catch (error) {
            console.log(error);
            alert("Ops, não foi possível disconectar conexão PPPoE");
        }

        getPPPoEData(connectionData.user);

        setDisconnectingPPPoE(false);
    }

    const handleRemoveOnu = useCallback(async () => {
        if (!window.confirm("Deseja realmente Remover está ONU?")) {
            return;
        }

        setRunningEquipmentAction(true);
        setRunningEquipmentActionMsg("Removendo ONU...");

        try {
            await api.delete(`smo/api/onus/${equipment.id}/`, { timeout: 10000 })
            setEquipment({});
        } catch (error) {
            if (error.response && error.response.data?.msg) {
                alert(error.response.data.msg);
            } else {
                alert("Ops, algo não saiu como esperado. Por favor, tente novamente!");
            }
        } finally {
            setRunningEquipmentAction(false);
            setRunningEquipmentActionMsg("");
        }
    }, [equipment]);
    

    const handleDeauthorizeOnu = useCallback(async () => {
        if (!window.confirm("Deseja realmente desautorizar ONU?")) {
            return;
        }

        setRunningEquipmentAction(true);
        setRunningEquipmentActionMsg("Desautorizando ONU...");

        try {
            await api.get(`smo/api/onus/${equipment.id}/deauthorize/`, { timeout: 10000 })
            setEquipment({});
        } catch (error) {
            if (error.response && error.response.data?.msg) {
                alert(error.response.data.msg);
            } else {
                alert("Ops, algo não saiu como esperado. Por favor, tente novamente!");
            }
        } finally {
            setRunningEquipmentAction(false);
            setRunningEquipmentActionMsg("");
        }
    }, [equipment]);

    const handleToBridge = useCallback(async () => {
        if (!window.confirm("Deseja Colocar a ONU em Bridge?")) {
            return;
        }

        setRunningEquipmentAction(true);
        setRunningEquipmentActionMsg("Mudando para Bridge...");

        try {
            await api.get(`smo/api/onus/${equipment.id}/to_bridge/`, { timeout: 10000 });
            alert("ONU colocada em Bridge com Sucesso!");
        } catch (error) {
            if (error.response && error.response.data?.msg) {
                alert(error.response.data.msg);
            } else {
                alert("Ops, algo não saiu como esperado. Por favor, tente novamente!");
            }
        } finally {
            setRunningEquipmentAction(false);
            setRunningEquipmentActionMsg("");
        }
    }, [equipment]);

    const handleRemoteAccessPorts = useCallback(async () => {

        setRunningEquipmentAction(false);
        setRunningEquipmentActionMsg("");

        try {
            const { data } = await api.get(`smo/api/tests/remote_access_ports/?ip=${pppoe.ip}`, { timeout: 10000 });
            if (data.up) {
                alert(`https://${pppoe.ip}:${data.port}`)
            } else {
                alert("acesso remoro não está disponivel")
            }
        } catch (error) {
            if (error.response && error.response.data?.msg) {
                alert(error.response.data.msg);
            } else {
                alert("Ops, algo não saiu como esperado. Por favor, tente novamente!")
            }
        } finally {
            setRunningEquipmentAction(false);
            setRunningEquipmentActionMsg("");
        }
    }, [pppoe])

    useEffect(() => {
        (async () => {
            if (connectionId) {
                const connectionDataTemp = await getConnectionData();
                await getPPPoEData(connectionDataTemp);
                await getEquipment({ connectionData: connectionDataTemp });
            } else {
                await getEquipment({ onuSerial });
            }
        })();
    }, [connectionId, onuSerial]);

    // Criando intervalo para monitor tráfego do cliente
    useEffect(() => {
        const getPPPoETrafficInterval = setInterval(getPPPoETraffic, 20000);

        return () => {
            clearInterval(getPPPoETrafficInterval);
        }
    }, [getPPPoETraffic]);

    // Interação do menu LabelTab
    const [activeLabelTab, setActiveLabelTab] = useState("equipment");

    return (
        <>
            <HeaderSMO />

            <Container >
                <Breadcrumb title={"Detalhes da Conexão"} paths={[
                    { pageName: "SMO", link: previousPage === "SearchClient" ? "/smo" : "/smo/olt" },
                    { pageName: previousPage === "SearchClient" ? "Clientes" : "ONUs" },
                    { pageName: previousPage === "SearchClient" ? `${connectionData.client_name || ""}` : `${equipment.serial || ""}` }
                ]}
                />
                <Content>
                    <NameClient>
                        <span>{(connectionData.client_name || "")}</span>
                    </NameClient>
                    <ContainerNameInfo>
                        <div className={activeLabelTab === "data" ? "active" : ""} onClick={() => setActiveLabelTab("data")}>Cadastro</div>
                        <div className={activeLabelTab === "equipment" ? "active" : ""} onClick={() => setActiveLabelTab("equipment")}>Conexão</div>
                    </ContainerNameInfo>

                    {activeLabelTab === "equipment" && (
                        <ContainerCards>

                            <ContainerConnections>

                                <ConnectionEquipament>

                                    <Cards>  {/* card*/}

                                        <Card>  {/* card section*/}

                                            <CardTitle>
                                                <span> <FaWifi /> <span className="desc">Sessão </span>  </span>
                                                <span className="sync"><i onClick={() => getPPPoEData()}><FaSyncAlt /></i></span>
                                            </CardTitle>
                                            {recoveringPPPoEData ? (
                                                <>
                                                    <CardContent>
                                                        <p><Skeleton height={17} /></p>
                                                        <p><Skeleton height={17} /></p>
                                                        <p><Skeleton height={17} /></p>
                                                        <p><Skeleton height={17} /></p>
                                                        <p><Skeleton height={17} /></p>
                                                    </CardContent>

                                                    <Skeleton style={{ margin: "20px auto", display: "block" }} width={183} height={35} />
                                                </>
                                            ) : (
                                                    <>
                                                        <CardContent>
                                                            <p>
                                                                <strong>Status: </strong>
                                                                <span className={`pppoe-status ${pppoe.status || ''}`}>{(pppoe.status || '').toUpperCase()}</span>
                                                            </p>
                                                            <p> <strong> IP: </strong><span>{pppoe.ip || "-"}</span></p>
                                                            {pppoe.status === 'online' ? (
                                                                <>
                                                                    <p> <strong> Conectado à: </strong> {pppoe.online_time || ""}</p>
                                                                    <p> <strong> Conectou em: </strong>{pppoe.start_time || ""}</p>
                                                                </>
                                                            ) : (
                                                                    <>
                                                                        <p> <strong> Desconectado à: </strong> {pppoe.online_time || ""}</p>
                                                                        <p> <strong> Desconectado em: </strong>{pppoe.start_time || ""}</p>
                                                                    </>
                                                                )}
                                                            <p> <strong> Mac Address:</strong> {pppoe.mac || ""} </p>
                                                        </CardContent>

                                                        <CardButton>
                                                            <Button
                                                                disabled={disconnectingPPPoE || recoveringPPPoEData}
                                                                onClick={handleDisconnectPPPoE}>
                                                                Desconectar</Button>
                                                        </CardButton>
                                                    </>
                                                )}
                                        </Card>

                                        <Card>
                                            <CardTitle>
                                                <span> <FaNetworkWired />   <span className="desc"> Conexão </span> </span>
                                                <span className="sync"></span>
                                            </CardTitle>

                                            <CardContent>
                                                <p className="ppoeStatusOnline"> <strong>Usuário: </strong>{connectionData.user || ""}</p>
                                                <p> <strong> Senha: </strong> {connectionData.password || ""}</p>
                                                <p> <strong> Plano: </strong> {connectionData.plan_description || ""}</p>
                                                <p> <strong> Ponto de Acesso: </strong> {connectionData.pe}</p>
                                                <p className="ppoeStatusPa"> <strong>Status Ponto de Acesso: </strong> OK </p>
                                            </CardContent>
                                        </Card>
                                    </Cards>

                                    <Card>
                                        <CardTitle>
                                            <span> <FaChartBar /> <span className="desc"> Trafego da Conexão </span> </span>
                                            <span className="sync"></span>
                                        </CardTitle>

                                        <CardContent>

                                            <Grafic>
                                                <CardImageGrafic>
                                                    <TrafficChart traffic={pppoeTrafficHistory} />
                                                </CardImageGrafic>

                                                <div className="w-100">
                                                    <DetailsTrafic>

                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÍNIMO</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {
                                                                    (Math
                                                                        .min(
                                                                            ...(pppoeTrafficHistory.length ?
                                                                                pppoeTrafficHistory.map(traffic => Number(traffic.download))
                                                                                : [0])
                                                                        ) / 1024
                                                                    )
                                                                        .toFixed(2)
                                                                } Mb/min
                                                        </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {
                                                                    (Math
                                                                        .min(
                                                                            ...(pppoeTrafficHistory.length ?
                                                                                pppoeTrafficHistory.map(traffic => Number(traffic.upload))
                                                                                : [0])
                                                                        ) / 1024
                                                                    )
                                                                        .toFixed(2)
                                                                } Mb/min
                                                        </span>
                                                        </div>
                                                    </DetailsTrafic>

                                                    <DetailsTrafic>
                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÉDIA</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.download;
                                                                    }

                                                                    return Number(a) + Number(b.download);
                                                                }, 0) / ((pppoeTrafficHistory.length || 1) * 1024)).toFixed(2)} Mb/min
                                                            </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.upload;
                                                                    }

                                                                    return Number(a) + Number(b.upload);
                                                                }, 0) / ((pppoeTrafficHistory.length || 1) * 1024)).toFixed(2)} Mb/min
                                                            </span>
                                                        </div>
                                                    </DetailsTrafic>

                                                    <DetailsTrafic>

                                                        <div>
                                                            <p>
                                                                <TitleDetailsTrafic>MÁXIMO</TitleDetailsTrafic>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <span>
                                                                <strong>Down: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.download;
                                                                    }

                                                                    return Math.max(Number(a), Number(b.download));
                                                                }, 0) / 1024).toFixed(2)} Mb/min
                                                        </span>
                                                            <span>
                                                                <strong>Up: </strong>
                                                                {(pppoeTrafficHistory.reduce(function (a, b) {
                                                                    if (typeof a != 'number') {
                                                                        a = a.upload;
                                                                    }

                                                                    return Math.max(Number(a), Number(b.upload));
                                                                }, 0) / 1024).toFixed(2)} Mb/min
                                                            </span>
                                                        </div>

                                                    </DetailsTrafic>
                                                </div>
                                            </Grafic>

                                        </CardContent>

                                    </Card>

                                </ConnectionEquipament>

                            </ContainerConnections>

                            <ContainerConnections2>
                                <CardOlt>
                                    <CardTitle>
                                        <span> <FaServer />  <span className="desc">Detalhes via OLT </span> </span>
                                        <span className="sync" onClick={handleGetEquipment}>  <FaSyncAlt /> </span>
                                    </CardTitle>

                                    {recoveringEquipment ? (
                                        <>
                                            <CardContent>
                                                <Skeleton height={35} />

                                                <CardImage>
                                                    <div>
                                                        <Skeleton height={80} />
                                                    </div>
                                                </CardImage>

                                                <article>
                                                    <p><Skeleton height={22} /></p>
                                                    <p><Skeleton height={22} /></p>
                                                    <p><Skeleton height={22} /></p>
                                                    <p><Skeleton height={22} /></p>
                                                    <p><Skeleton height={22} /></p>
                                                    <p><Skeleton height={22} /></p>
                                                </article>

                                                <Skeleton height={35} />
                                            </CardContent>
                                        </>
                                    ) : (
                                            <>
                                                <CardContent>
                                                    <CardInformation>
                                                        <h3>ONU</h3>
                                                    </CardInformation>
                                                    <CardImage>
                                                        <div>
                                                            <EquipmentImg
                                                                connected={(equipmentOpticalInfo.status || "") === "online"}
                                                                signal={equipmentOpticalInfo.rx_power || ""}
                                                            />
                                                        </div>

                                                        {false && (
                                                            <div>
                                                                <button>Reiniciar</button>
                                                            </div>
                                                        )}
                                                    </CardImage>

                                                    <article>
                                                        <p> <strong> SLOT: </strong> {equipment.card ? equipment.card.slot : ""} </p>
                                                        <p> <strong> PON: </strong> {equipment.pon ? equipment.pon.number : ""} </p>
                                                        <p> <strong> INDEX :</strong> {equipment.index || ""}</p>
                                                        <p> <strong> MODELO: </strong> {equipment.model || ""} </p>
                                                        <p> <strong> MODO: </strong>{
                                                            (equipment.connection_type || "") === "2" ? "Router" :
                                                                (equipment.connection_type || "") === "1" ? "Bridge" : ""
                                                        }</p>
                                                        <p> <strong> N/S: </strong> {equipment.serial || ""}</p>
                                                    </article>

                                                    <CardInformation>
                                                        <h3> Ações </h3>
                                                    </CardInformation>
                                                </CardContent>

                                                <ButtonsAction>

                                                    <ButtonGroup>
                                                        <ButtonOnu onClick={handleDeauthorizeOnu}> Desautorizar</ButtonOnu>
                                                        <ButtonOnu onClick={handleRemoveOnu} className="remove"> Remover </ButtonOnu>
                                                    </ButtonGroup>

                                                    <ButtonGroup>
                                                        <ButtonOnu onClick={handleRemoteAccessPorts} className="acessar"> Acessar </ButtonOnu>
                                                        <ButtonOnu onClick={handleToBridge} className="bridge"> Colocar em Bridge </ButtonOnu>
                                                    </ButtonGroup>

                                                </ButtonsAction>
                                            </>
                                        )}

                                    <AlertLoading msg={runningEquipmentActionMsg} isVisible={runningEquipmentAction} />
                                </CardOlt>
                            </ContainerConnections2>

                        </ContainerCards>
                    )}
                    {activeLabelTab === "data" && (
                        <ContainerData>
                            <DataClientOne>
                                <h2>DADOS DO CLIENTE</h2>
                            </DataClientOne>
                            <div>
                                <ul>
                                    <li>
                                        <strong>Status:</strong>
                                        <span className={`${connectionData.contract_status || ""}` === "Normal" ? "green" : "blue"}>{connectionData.contract_status}</span>
                                    </li>
                                    <li>
                                        <strong>Nº Contrato:</strong>
                                        <span>{connectionData.contract_number || ""}</span>
                                    </li>
                                    <li>
                                        <strong>CPF/CNPJ:</strong>
                                        <span>{formatCpfCnpj(connectionData.client_cpf || "")}</span>
                                    </li>
                                    <li>
                                        <strong>Telefones:</strong>
                                        <span>{connectionData.client_phone || ""}
                                            {connectionData.client_phone && connectionData.client_phone2 ? " | " : ""}
                                            {connectionData.client_phone2 || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Rua:</strong>
                                        <span>{connectionData.street || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Numero:</strong>
                                        <span>{connectionData.number || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Bairro</strong>
                                        <span>{connectionData.neighborhood || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Cidade:</strong>
                                        <span>{connectionData.city || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Estado:</strong>
                                        <span>{connectionData.state || ""}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <strong>Plano:</strong>
                                        <span>{connectionData.plan_description || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Ponto de Acesso:</strong>
                                        <span>{connectionData.pe}</span>
                                    </li>
                                    <li>
                                        <strong>Data do vencimento:</strong>
                                        <span>{connectionData.contract_collection_day || ""}</span>
                                    </li>
                                    <li>
                                        <strong>Tecnologia:</strong>
                                        <span>{connectionData.tecnology}</span>
                                    </li>
                                    <li>
                                        <strong>Link:</strong>
                                        <span>{connectionData.clientType}</span>
                                    </li>
                                </ul>
                            </div>
                        </ContainerData>
                    )}


                </Content>
            </Container>
        </>
    )
}

function ErrorPage() {
    const history = useHistory();

    useEffect(() => {
        alert("Nenhuma conexão foi selecionada");
        history.push('/smo');
    }, [history])

    return (<></>);
}

function Validator() {
    const location = useLocation();

    if (!(location.state && (location.state.connectionId || location.state.onuSerial))) {
        return ErrorPage();
    }

    return ConnectionDetails(location.state);
}

export default Validator;


