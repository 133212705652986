export const formatCpfCnpj = function (cpfCnpj) {
  cpfCnpj = cpfCnpj.replace(/\D/g, '')

  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4')
  } else if (cpfCnpj.length === 14) {
    return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else {
    return cpfCnpj
  }
}

export const capitalize = function (text) {
  return text[0].toUpperCase() + text.slice(1).toLowerCase()
}