import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';
import { FaHubspot } from 'react-icons/fa';

import api from '~/shared/services/api';

import Breadcrumb from '~/shared/components/Breadcrumb';
import HeaderSMO from '../../components/HeaderSMO';
import SearchButton from '../../components/SearchButton';

import {
  Container,
  Content,
  SearchCustomer,
  Search,
  SecondContent,
  Table,
  Thead,
  Icon,
  Pagination,
  CountOnu,
  PaginationLinks,
  PaginationLink
} from './styles';

export default function SearchOlt() {
  const [olts, setOlts] = useState([]);
  const [slots, setSlots] = useState([]);
  const [pons, setPons] = useState([]);
  const [onus, setOnus] = useState([]);

  const [oltSelected, setOltSelected] = useState(0);
  const [slotSelected, setSlotSelected] = useState(0);
  const [ponSelected, setPonSelected] = useState(0);
  const [serial, setSerial] = useState("");
  const [lastParamsForGetOnu, setLastParamsForGetOnu] = useState("");

  const [pageLinks, setPageLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [showingOnus, setshowingOnu] = useState(0);
  const [totalAmountOnus, setTotalAmountOnus] = useState(0);

  const history = useHistory();

  const handleChangeOlt = useCallback((event) => {
    if (!event.target.value) {
      setOltSelected(0);
      setSlotSelected(0);
      setPonSelected(0);
      setSlots([]);
      setPons([]);
      return;
    }

    setOltSelected(event.target.value);

    api
      .get('smo/api/cards/', { params: { olt_id: event.target.value } })
      .then(response => setSlots(response.data))
      .catch(error => (alert(error)));
  }, []);

  const handleChangeSlot = useCallback((event) => {
    if (!event.target.value) {
      setSlotSelected(0);
      setPonSelected(0);
      setPons([]);
      return;
    }

    setSlotSelected(event.target.value);

    api
      .get('smo/api/pons/', { params: { card_id: event.target.value } })
      .then(response => setPons(response.data))
      .catch(error => (alert(error)))
  }, []);

  const handleChangePon = useCallback((event) => {
    if (!event.target.value) {
      setPonSelected(0);
      return;
    }

    setPonSelected(event.target.value);
  }, []);

  const getOnus = useCallback(() => {
    let params = {
      authorized: true
    };

    if (oltSelected) {
      params['olt_id'] = oltSelected;
    }
    if (slotSelected) {
      params['card_id'] = slotSelected;
    }
    if (ponSelected) {
      params['pon_id'] = ponSelected;
    }
    if (serial) {
      params['serial'] = serial;
    }

    setLastParamsForGetOnu(params);
    setCurrentPage(1);

    api
      .get('smo/api/onus/', { params })
      .then((response) => {
        const numPages = Math.ceil(Number(response.headers['x-total-count']) / 25);

        setTotalAmountOnus(Number(response.headers['x-total-count']));
        setshowingOnu(response.data.length);

        setPageLinks(Array.from({ length: numPages }, (_, i) => i + 1));
        setOnus(response.data);
      })
      .catch();
  }, [oltSelected, slotSelected, ponSelected, serial]);

  const handleNavigateToPage = useCallback((page) => {
    const newParams = { ...lastParamsForGetOnu, 'page': page };

    api
      .get('smo/api/onus/', { params: newParams })
      .then(response => {
        const numPages = Math.ceil(Number(response.headers['x-total-count']) / 25);

        setPageLinks(Array.from({ length: numPages }, (_, i) => i + 1));
        setTotalAmountOnus(Number(response.headers['x-total-count']));
        setshowingOnu(response.data.length);
        setLastParamsForGetOnu(newParams);
        setCurrentPage(page);
        setOnus(response.data);
      })
      .catch(error => console.log(error));
  }, [lastParamsForGetOnu]);

  const handleGetOnus = useCallback((e) => {
    e.preventDefault();

    getOnus();
  }, [getOnus]);

  const handleNavigateToConnectionDetails = useCallback((onu) => {
    const state = onu.connection_id
      ? { connectionId: onu.connection_id }
      : { onuSerial: onu.serial };


    history.push('/smo/connections/details', { ...state, previousPage: "SearchOnu" });
  }, [history]);

  useEffect(() => {
    api
      .get('smo/api/olts/')
      .then(response => setOlts(response.data))
      .catch(error => alert('Não foi possivel recuperar as OLTs'))

    api
      .get('smo/api/onus/', {
        params: {
          authorized: true
        }
      })
      .then(response => {
        const numPages = Math.ceil(Number(response.headers['x-total-count']) / 25);

        setTotalAmountOnus(Number(response.headers['x-total-count']));
        setshowingOnu(response.data.length);

        setPageLinks(Array.from({ length: numPages }, (_, i) => i + 1));
        setLastParamsForGetOnu({});
        setCurrentPage(1);
        setOnus(response.data);
      })
      .catch(error => {
        if (error.response && error.response.data?.message) {
          alert(error.response.data.message);
        } else {
          alert('Não foi possivel recuperar as ONUs');
        }
      })
  }, []);

  return (
    <>
      < HeaderSMO />

      <Container>
        <Breadcrumb title="ONUs" paths={[
          { pageName: "SMO", link: "/smo/olt" },
          { pageName: "ONUs" }
        ]} />

        <Content>
          <SearchCustomer>
            <h2> PESQUISAR EQUIPAMENTO </h2>

            <form>
              <div>
                <Search>
                  <label htmlFor=""> OLT </label>
                  <select className="selectOlt" onChange={handleChangeOlt} defaultValue={""}>
                    <option value="">Selecione uma OLT</option>
                    {olts.map(olt => (
                      <option key={olt.id} value={olt.id}>{olt.title}</option>
                    ))}
                  </select>
                </Search>

                <Search>
                  <label htmlFor=""> SLOT </label>
                  <select className="selectSlot" onChange={handleChangeSlot} defaultValue={""}>
                    <option value="">Slot</option>
                    {slots.map(slot => (
                      <option key={slot.id} value={slot.id}>{slot.slot}</option>
                    ))}
                  </select>

                </Search>

                <Search>
                  <label htmlFor=""> PON </label>
                  <select className="selectSlot" onChange={handleChangePon} defaultValue={""}>
                    <option value="">Pon</option>
                    {pons.map(pon => (
                      <option key={pon.id} value={pon.id}>{pon.number}</option>
                    ))}
                  </select>
                </Search>

                <Search>
                  <label htmlFor=""> SN ONU </label>
                  <input type="text" placeholder="FTTH4567D8E4" value={serial} onChange={event => setSerial(event.target.value)} />
                </Search>

                <Search>
                  <label>&nbsp;</label>
                  <SearchButton onClick={handleGetOnus} />
                </Search>
              </div>
            </form>

          </SearchCustomer>
        </Content>

        <SecondContent>
          <Table>
            <Thead>
              <tr>
                <th> OLT </th>
                <th> ONU </th>
                <th> SLOT </th>
                <th> PON </th>
                <th colSpan="2"> Index </th>
              </tr>
            </Thead>

            <tbody>
              {onus.map(onu => (
                <tr key={onu.id}>
                  <td> {onu.olt.title} </td>
                  <td> {onu.serial} </td>
                  <td> {onu.card.slot} </td>
                  <td> {onu.pon.number} </td>
                  <td> {onu.index} </td>
                  <td>
                    <Icon onClick={() => handleNavigateToConnectionDetails(onu)}><BiSearchAlt /></Icon>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <CountOnu>
            <p> {`Mostrando ${showingOnus} de ${totalAmountOnus}`} </p>
          </CountOnu>
          {pageLinks.length && (
            <Pagination>

              <PaginationLinks>
                <PaginationLink onClick={() => handleNavigateToPage(1)} disabled={currentPage <= 1}>
                  <span>Primeira</span>
                </PaginationLink>
                <PaginationLink onClick={() => handleNavigateToPage(currentPage - 1)} disabled={currentPage <= 1}>
                  <span>&laquo;</span>
                </PaginationLink>

                {Array
                  .from(
                    { length: currentPage > 2 ? 2 : currentPage - 1, },
                    (_, i) => i + (currentPage > 2 ? currentPage - 2 : 1))
                  .map(page => (
                    <PaginationLink className="page" onClick={() => handleNavigateToPage(page)}>
                      <span>{page}</span>
                    </PaginationLink>
                  ))
                }

                <PaginationLink className="page" onClick={() => handleNavigateToPage(currentPage)} selected>
                  <span>{currentPage}</span>
                </PaginationLink>

                {Array
                  .from(
                    { length: (currentPage + 3) > pageLinks.length ? pageLinks.length - currentPage : 2 },
                    (_, i) => i + (currentPage + 1))
                  .map(page => (
                    <PaginationLink className="page" onClick={() => handleNavigateToPage(page)}>
                      <span>{page}</span>
                    </PaginationLink>
                  ))
                }

                <PaginationLink
                  onClick={() => handleNavigateToPage(currentPage + 1)}
                  disabled={currentPage >= pageLinks.length}>
                  <span>&raquo;</span>
                </PaginationLink>

                <PaginationLink
                  onClick={() => handleNavigateToPage(pageLinks.length)}
                  disabled={currentPage >= pageLinks.length}>
                  <span>Última</span>
                </PaginationLink>
              </PaginationLinks>
            </Pagination>
          )}
        </SecondContent>
      </Container>
    </>

  )
}