import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AiFillHome } from "react-icons/ai";
import * as RiIcons from 'react-icons/ri';
import { NavLink } from "react-router-dom";


import api from '~/shared/services/api';

import SubMenu from './subMenu';
import logoMgnet from '~/shared/assets/logo-mgnet.png';
import buttonSearch from '~/shared/assets/buttonSearchMgMaps.svg';

import {Nav, SideBarNav, SideBarWrap, TitleSideBar, Ul, Li} from './style'

export default function Sidebar(){
  const [city, setCity] = useState([{}]);
  const [districtIdsToSearch, setDistrictIdsToSearch] = useState({});

  const location = useLocation();
  const history = useHistory();

  const handleCheckBoxChange = useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.checked;

      setDistrictIdsToSearch(districtIdsToSearch => {
        return { ...districtIdsToSearch, [name]: value }
      })
    },
    []
  );

  const handleSearchCtos = useCallback(
    () => {
      const districtIds = Object.keys(districtIdsToSearch).filter(
        districtId => districtIdsToSearch[districtId]
      );

      history.push({
        pathname: '/mgmaps/district',
        state: { districtIds }
      });
    },
    [districtIdsToSearch, history],
  );
 
  useEffect(()=>{
    api
      .get('smo/api/cities/districts/?state=20')
      .then(response => {
        const formatedArrayCity = response.data.map(
          (city)=> Object.assign(city,
            {
              iconClosed: <RiIcons.RiArrowDownSFill />,
              iconOpened: <RiIcons.RiArrowUpSFill />,
            }
          ))
          setCity(formatedArrayCity);
      })
      .catch(error => console.log(error));

    // Verificando se já não possui distritos carregados
    const districtIds = location.state?.districtIds || null;
    
    if (districtIds) {
      let dictDistrictIds = {};
      districtIds.forEach(id => dictDistrictIds[id] = true);
      setDistrictIdsToSearch(dictDistrictIds);
    }
  }, []);

  const [activeLabelTab, setActiveLabelTab] = useState("home");


  return(
    <div>
     <Nav> {/* NAV HORIZONTAL */}
       <figure>
         <img src={logoMgnet} alt="logo Mgnet"/>
       </figure>
       <Ul>
         <Li> 
            <NavLink activeClass="active" to="/mgmaps"> 
              Home
            </NavLink>
          </Li>
          <Li> 
            <NavLink activeClass="active" to="/teste"> 
              Mapa
            </NavLink>
          </Li>
          <Li> 
            <NavLink activeClass="active" to="/settings"> 
              Configuração
            </NavLink>
          </Li>
         
       </Ul>
     </Nav>

    <SideBarNav > {/* NAVBAR VERTICAL*/}
        <TitleSideBar>     
          <span> 
            <AiFillHome />
            <p>Localidades</p>
          </span>

          <button onClick={handleSearchCtos}>
            <img src={buttonSearch} alt="Botão Pesquisar CTO"/>
          </button>
      </TitleSideBar>
       <SideBarWrap>
      
        {city.length> 1 && city.map((item) => 
          <SubMenu 
            item={item}
            key={item.id}
            onChangeCheckBox={handleCheckBoxChange}
            selectedDistricts={districtIdsToSearch}
          />
        )}
       </SideBarWrap>

    </SideBarNav>

    </div>
  );
}