import React from 'react'
import {HorizontalBar } from "react-chartjs-2";
import {Container, ContainerGrapichs} from './style'

const data = {
  labels: ['Caiçara do Norte', 'Ceara Mirim', 'João Camâra', 'Maxaranguape', 'Natal', 'Parazinho', 'Pedra Grande', 'Poço Branco', 'Pureza', 'Riachuelo', 'Rio do Fogo', 'Santa Luzia', 'São Bento do Norte', 'São Miguel de Touros', 'Taipu', 'Touros'],
  datasets: [
   
    {
      label: 'Cidades',
      data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 10],
      
      backgroundColor: [
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",
        "#50BCBA",

        
      ],
      
      fill: false,


      borderWidth: 1,
    },
  ],
}

const options = {
  scales: {

    xAxes: [{
      gridLines: {
          display:false
      }
  }],
    
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
          color: '#FF6384',
        sidePadding: 200,
    
   
          
        },
        gridLines: {
          display:false
      }
      },
    ],
  },
  animation: false,
  elements:{
    color: '#FF6384',
    sidePadding: 200,
    
  }
}


const HorizontalBarChart = () => (

  <ContainerGrapichs>

<Container>
  

  <div className='header'>
    
    <div className='links'>
      <a
        className='btn btn-gh'
        href='https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/HorizontalBar.js'
      >
        
      </a>
    </div>
  </div>
  <HorizontalBar data={data} options={options} />
 
</Container>

  </ContainerGrapichs>
  

)

export default HorizontalBarChart