import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { FontColor, Title } from '../../styles';
import { Container, DataItem } from './styles';

export default function DataClient({ connectionData, recoveringConnectionData, setConfirmedClient, isClientFinanceBlocked }) {
    return (
        <Container>

            {!recoveringConnectionData ? (
                <>
                    <Title>DADOS DO CLIENTE</Title>

                    <DataItem>
                        <li><strong>CPF/CNPJ: </strong> {connectionData.client_cpf || ""}</li>
                        <li>
                            <strong>Telefone: </strong>
                            {connectionData.client_phone || ""}
                            {connectionData.client_phone && connectionData.client_phone2 ? " | " : ""}
                            {connectionData.client_phone2 || ""}
                        </li>
                        <li>
                            <strong>Endereço: </strong>
                            {`${connectionData.street || ""}, ${connectionData.number || ""} - ${connectionData.neighborhood || ""} - ${connectionData.city || ""} - ${connectionData.state || ""}`}
                        </li>
                        <li><strong>Plano: </strong> {connectionData.plan_description || ""} </li>
                        <li><strong>Ponto de Acesso: </strong> {connectionData.pe}</li>
                        <li><strong>Tecnologia: </strong> {connectionData.tecnology}</li>
                        <li><strong>Link: </strong> {connectionData.clientType}</li>
                        <li>
                            <strong>Status: </strong>
                            <FontColor
                                active={!isClientFinanceBlocked}
                            >{connectionData.contract_status}
                            </FontColor>
                        </li>
                        <li><strong>Nº Contrato: </strong> {connectionData.contract_number || ""}</li>
                        <li><strong>Dia de Vencimento: </strong> {connectionData.contract_collection_day || ""}</li>
                    </DataItem>

                    <button onClick={setConfirmedClient}>
                        {!isClientFinanceBlocked
                            ? "Cliente Confirmado"
                            : "Voltar"
                        }
                    </button>
                </>
            ) : (
                    <>
                        <Title><Skeleton width={200} height={30} /></Title>

                        <DataItem>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                            <li><Skeleton height={19} /></li>
                        </DataItem>

                        <Skeleton style={{ margin: "20px auto", display: "block" }} width={183} height={35} />
                    </>
                )}
        </Container>
    );
}
