import styled from 'styled-components';
import {Form as Unform } from '@unform/web'

export const Container = styled.div`


`;
export const ModalHeader =  styled.div`
  div{
    display: flex;
    justify-content: flex-end;
    padding: .25rem;

  }
    button{
      cursor: pointer;
      border: 0;
      background: none;
      font-size: 1.2rem;
      color: #405057;
      z-index: 1;
    
  }

  
  p{
    color: #495057;
    font-weight: 600;
    margin-left: 2.813rem;
    padding-top: 1.125rem;
    font-size: 1rem;

  }

`;
export const Form = styled(Unform)`

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.15rem;
  margin-top: 1.125rem;
  input, button{
    width: 100%;
    max-width: 400px;
    height: 40px;
    outline: none;
    background: #F8F9FA;
    border: 1px solid #D7D7D7;
    border-radius: 5px;

  }
  input{
    padding-left: .5rem;

  }
  button{
    cursor: pointer;
    color: #FFFFFF; 
    background: #33CC95;
    border-radius: 5px;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    transition: filter 0.2s;

    &:hover{
      filter:brightness(0.9);
    }


  }
`;