import React from 'react';
import {
  Container,
  Card,
  CardTitle,
  CardInformation,
  ProgressBar,
  ProgressGoal,
  ProgressCurrent
} from './style';

export default function CardsMgMaps(){

  const value=200;
  const valueUsed=100;

  return(

    <>

      <Container>
     
        <Card>
          <CardTitle>
            Capacidade
          </CardTitle>
          <CardInformation>

            <ul>
              <li>
                <div>
                  <div className={'card-blue'}>&nbsp;</div><span>Utilizada</span>
                </div>
                <p>250</p>
              </li>
              <li>
                <div>
                  <div className={'card-green'}>&nbsp;</div><span>Atual</span>
                </div>
                <p>800</p>
              </li>
              <li>
                <div>
                  <div className={'card-red'}>&nbsp;</div><span>Máxima</span>
                </div>
                <p>2048</p>
              </li>
            </ul>

            <ProgressBar>
              
              
              <div className="progressMax">
             

              </div>

              <ProgressGoal  progress widthBarCurrent={`${String(value)}px`} ></ProgressGoal>
              <ProgressCurrent  progress widthBarUsed={`${String(valueUsed)}px`} ></ProgressCurrent>

            </ProgressBar>
          </CardInformation>

        </Card>

        <Card>
          <CardTitle>
            Cto(Portas)
          </CardTitle>
          <CardInformation>
            <ul>
              <li>
                <div>
                  <div className={'card-blue'}>&nbsp;</div><span> Utilizada</span>
                </div>
                <p>158</p>
              </li>
              <li>
                <div>
                  <div className={'card-green'}>&nbsp;</div><span>   Atual</span>
                </div>
                <p> 850</p>
              </li>

              <li>
                <div>
                  <div className={'card-red'}>&nbsp;</div><span>  Máxima</span>
                </div>
                <p> 1048</p>
              </li>
            </ul>


            <ProgressBar>
              <div className="progressMax">
             

              </div>

              <ProgressGoal  progress widthBarCurrent={`${String(value)}px`} ></ProgressGoal>

              <ProgressCurrent progress widthBarUsed={`${String(valueUsed)}px`}> </ProgressCurrent>
              
              
              

            </ProgressBar>

          </CardInformation>

        </Card>
        
        <Card>
          <CardTitle>
            Clientes
          </CardTitle>
          <CardInformation>
            <ul>
              <li>
                <div>
                  <div className={'card-blue'}>&nbsp;</div><span> Utilizada</span>
                </div>
                <p>550</p>
              </li>

              <li>
                <div>
                  <div className={'card-green'}>&nbsp;</div><span> Atual</span>
                </div>
                <p>1000</p>
              </li>

              <li>
                <div>
                  <div className={'card-red'}>&nbsp;</div><span>  Máxima</span>
                </div>
                <p>200</p>
              </li>
            </ul>

            <ProgressBar>
              <div className="progressMax">
             

              </div>

              <ProgressGoal  progress widthBarCurrent={`${String(value)}px`} ></ProgressGoal>
              <ProgressCurrent progress widthBarUsed={`${String(valueUsed)}px`}> </ProgressCurrent>

              
              
              
              
              

            </ProgressBar>
          </CardInformation>

        </Card>

      </Container>
      
    </>
  );
}