import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { FaUserAlt, FaLock, FaMailBulk } from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';

import Spinner from '../../components/Spinner';
import api from '~/shared/services/api';

import LogoSMO from '../../assets/smo_logo.png';
import GrupoMG from '../../assets/grupo_mg.png';
import LogoMgnet from '../../assets/logo-mgnet-branco.png';

import { 
  Overlay,
  FdEarth,
  BorderLogin,
  Left,
  Right,
  Input,
  Form,
  ButtonEnv,
  DivImput,
  Copyrigth,
  ForgotItPassword,
  Description 
} from './styles';

export default function Login() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const [emailResetPassword, setEmailResetPassword] = useState(true);

  const { signIn, user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!!user) {
      history.push("/home");
    }
  }, [user, history]);

  const handleSendEmail = useCallback(async () => {
    try {
      await api
        .post('/password/forgot', { email });
      
      alert('E-mail enviado com sucesso');
      setEmail("");
      setEmailResetPassword(true);
    } catch (error) {
      alert(error.response.data.message);
    }
  },[email]);

  const handleSignIn = useCallback(async (event) => {
    event.preventDefault();

    try {
      if (!username.length || !password.length) {
        alert("Por favor preencha todos os campos");
      } else {
        setIsLoggingIn(true);

        await signIn(username, password);

        const next = location.state?.from?.pathname;

        if (next) {
          history.push(next);
        } else {
          history.push('/home');
        }
      }
    } catch (error) {
      setIsLoggingIn(false);

      if (error.response && error.response.data?.message) {
        setError(error.response.data.message);
        return;
      }

      alert("Ops, não foi possível validar credenciais");
    }
  }, [username, password, signIn, history, location]);


  return (
    <>
      <Helmet title="Seedot - Login" />

      <FdEarth>
        <Overlay />
        <BorderLogin>

          <Left>
            <img className="logosmo" src={LogoSMO} alt="Logo SMO" />
            <img className="logomg" src={GrupoMG} alt="Logo MG" />
          </Left>

          <Right>

            <div>
              <img className="logomgnet" src={LogoMgnet} alt="Logo MGNET" />
            </div>
            { emailResetPassword ? (
                <Form>
                  <span>{error}</span>
                  <DivImput>
                    <FaUserAlt />
                    <Input
                      type="text"
                      placeholder="Usuário"
                      required="true"
                      onChange={(event) => setUsername(event.target.value)}
                      value={username}
                      onFocus={() => setError("")}
                      disabled={isLoggingIn} />
                  </DivImput>
                  <DivImput>
                    <FaLock />
                    <Input
                      type="password"
                      placeholder="Senha"
                      required="true"
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                      onFocus={() => setError("")}
                      disabled={isLoggingIn} />
                  </DivImput>
                  <ButtonEnv type='button' onClick={handleSignIn} disabled={isLoggingIn}>
                    {isLoggingIn ? (<Spinner size={"1rem"} borderWidth={"0.2rem"} />) : "Enviar"}
                  </ButtonEnv>
                  <ForgotItPassword>
                    <p onClick={() => setEmailResetPassword(false)}>Esqueci minha senha</p>
                    
                  </ForgotItPassword>
                </Form>)
                :
                (<Form>
                  <span>{error}</span>
                  <Description>
                    <p>Isira seu e-mail e enviaremos um link para redefinir sua senha.</p>
                  </Description>
                  <DivImput>
                    <FaMailBulk />
                    <Input
                      type="email"
                      placeholder="E-mail"
                      required="true"
                      onChange={(event) => setEmail(event.target.value)}
                      value={email}
                      onFocus={() => setError("")}
                      disabled={isLoggingIn} />
                  </DivImput>
                  <ButtonEnv type="button" onClick={handleSendEmail} disabled={isLoggingIn}>
                    {isLoggingIn ? (<Spinner size={"1rem"} borderWidth={"0.2rem"} />) : "Enviar"}
                  </ButtonEnv>
                  <ForgotItPassword>
                    <p onClick={() => setEmailResetPassword(true)}>Voltar</p>
                  </ForgotItPassword>
                </Form>)
            }
          </Right>

        </BorderLogin>
        <Copyrigth>© NOC Solutions - 2020-2021</Copyrigth>
      </FdEarth>
    </>
  )
}