import styled from 'styled-components';

export const HeaderApi = styled.header`
  display: flex;
  box-sizing: border-box;
  background-color: #F8F9FA;
  height: 70px;
  padding: 5px;

`
export const LogoMgnet = styled.div`
   display: flex;
   align-items: center;
   img {
    margin: 5px 20px;
    height: 40px;
    }
`
export const Menu = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;  
    a{
      display: flex;
      align-items: center;
      color: #495057;
      padding: 10px;
      text-decoration: none;
    }
    svg {
      margin-right: 11px;
    }
    .icongrup {
      font-size: 22px;
    }

`