import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { FaServer, FaPaste, FaHeadset, FaGlobeAmericas, FaUserAlt } from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';

import HeaderDark from '~/shared/pages/SysSelect/components/HeaderDark'

import { Container, SmallCards, Card, BigCard, MainCard, Footer } from './styles';

export default function SysSelect() {
  const { user } = useAuth();
  const { is_admin } = user;

  return (
    <>
      <Container>
        <Helmet title="Seedot - Home" />
        
        <HeaderDark />
        
        <MainCard>
          <Link to={is_admin ? '/smo' : '#'}>
            <BigCard>  
                <FaServer />
                <p> SMO </p>
            </BigCard>
          </Link>
          <SmallCards>
            <Link to='#'>
              <Card cor={"yellow"}>
                <FaPaste />
                <p> OS </p>
              </Card>
            </Link>
            <Link to='/support/connections'>
              <Card cor={"red"}>
                  <FaHeadset />
                  <p> Suporte </p>
              </Card>
            </Link>
          </SmallCards>
          <SmallCards>
            <Link to={is_admin ? '/mgmaps/district' : '#'}>
              <Card cor={"green"} >
                <FaGlobeAmericas />
                <p> Maps </p>
              </Card>
            </Link>
            <Card cor={"cyan"}>
              <FaUserAlt />
  
              <p> Cliente </p>
  
            </Card>
          </SmallCards>
        </MainCard>
        <Footer>
          <p>© NOC Solutions - 2020-2021</p>
        </Footer>
      </Container>
    </>

  )
}