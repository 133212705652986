import styled from 'styled-components';

export const EquipmentContainer = styled.section`
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .w-100 {
        flex: 1;
    }
`

export const IP = styled.span`
    font-size: 1rem;
    color: #1C1F73;
    font-family: robotobold;
`

export const Card = styled.div`
    display: flex;
`

export const CardSection = styled.div`
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 5px;
    flex: 1;
`

export const StripeUp = styled.div`
    display: flex;
    align-items: center;
    background: #A6DDDC;
    border-radius: 3px 3px 0px 0px;
    font-size: 1.3rem;
    color: #1C1F73;
    width: 100%;
    padding: 5px 10px;

    span {
        color: initial;
        font-family: RobotoMedium, sans-serif;
        margin-right: auto;
        margin-left: 10px;
    }
    i {
        font-size: 1.3rem;
        color: initial;
        align-items: center;
        cursor: pointer;
    }
`
export const TimeInside = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;

    font-size: 1.7rem;
    font-weight: 400;
    text-align: center;

`
export const CardBody = styled.div`
    margin: 10px;
    
    button {
        display: block;
        margin: 20px auto;
        border: initial;
        cursor: pointer;
        background: #D10101;
        color: #fff;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        width: 183px;
        height: 35px;
        font-weight: bold;
        
        &:hover {
            background: #ca3303;
        }
     }
`

export const SectionOnu = styled.div`
    display: flex;
`

export const EquipmentFiber = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    margin: 10px;

    div {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    button {
        width: 183px;
        height: 35px;
        background: #EABA0F;
        box-shadow: 0px 1.16667px 3.5px rgba(0, 0, 0, 0.5);
        border-radius: 1.16667px;
        border: none;
        margin: 30px;
    }
`

export const Grafic = styled.div`
    display: flex;
    align-items: center;
    
    img {
        width: 100%;
    }
`

export const DetailsTrafic = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    min-width: 177px;
    
    p {
        color: #1C1F73;
        font-weight: bold;
        font-size: .8rem;
        margin-bottom: 5px;
    }
    div {
        display: flex;
        flex-direction: column;
        margin-left: .9rem;
        margin-bottom: .6rem;
    }
    span {
        font-size: .9rem;
    }
`

export const TitleDetailsTrafic = styled.span`
    color: #1C1F73;
    padding-bottom: 2px;
    
    border-bottom: 5px solid #50bcba;
    border-radius: 5px;
    font-weight: bold;
    font-size: .8rem;

    
`

export const DataItem = styled.ul`
    display: flex;
    flex-direction: column;
    font-size: .9rem;

    li {
        margin: 5px;
        list-style-type: none;
        font-size: .9rem;
        font-weight: 400;
    }

    span.pppoe-status {
        font-weight: bold;
        font-style: initial;
    }

    span.pppoe-status.online {
        color: #45B529;
    }

    span.pppoe-status.offline {
        color: #D10101;
    }
`